import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"

import {
  Modal,
} from "reactstrap"

import { connect } from "react-redux"
import { Form, Input, Button, Row, Col } from "reactstrap"

import { Link, useHistory } from "react-router-dom"

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import logoImage from '../../assets/images/logo.png'

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

import logoSm from "../../assets/images/logo-sm.png"
import logoDark from "../../assets/images/logo-dark.png"
import logoLight from "../../assets/images/logo-light.png"

// import images
import github from "../../assets/images/brands/github.png"
import bitbucket from "../../assets/images/brands/bitbucket.png"
import dribbble from "../../assets/images/brands/dribbble.png"
import dropbox from "../../assets/images/brands/dropbox.png"
import mail_chimp from "../../assets/images/brands/mail_chimp.png"
import slack from "../../assets/images/brands/slack.png"
import { closeModal, changeRoutForModal, changeRoutForModalDashboard } from './globalFunction';

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"

import $ from 'jquery';
import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper";

const Header = props => {


  const [modal_standard, setmodal_standard] = useState(false)
  const [modal_large, setmodal_large] = useState(false)
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [modal_small, setmodal_small] = useState(false)
  const [modal_center, setmodal_center] = useState(false)
  const [modal_scroll, setmodal_scroll] = useState(false)
  const [modal_fullscreen, setmodal_fullscreen] = useState(false)
  const [modal_backdrop, setmodal_backdrop] = useState(false)


  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    removeBodyCss()
  }  
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }



  const history = useHistory();
  
  const [search, setsearch] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)
  const [menuType, setMenu] = useState([])

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const segments = document.URL;
  const currentLink = segments.substr(segments.lastIndexOf('/') + 1);


  const idleDurationMins = 60;    // X number of minues
  const redirectUrl = '/logout';  // Redirect idle users to this URL
  let idleTimeout; // variable to hold the timeout, do not modify


  function timeOutFunction(){
    window.location.href = redirectUrl;
  }

  function resetIdleTimeout() {

    // Clears the existing timeout
    if(idleTimeout) clearTimeout(idleTimeout);

    // Set a new idle timeout to load the redirectUrl after idleDurationMins
    idleTimeout = setTimeout(timeOutFunction, idleDurationMins * 60 * 1000);
  };

  useEffect(() => {
    setMenu([])

    resetIdleTimeout();
    ['click', 'touchstart', 'mousemove'].forEach(evt => 
      document.addEventListener(evt, resetIdleTimeout, false)
    );

  }, [])


  $(function() {
    $('.menu-top').removeClass("manual_active");
    $('.menu-top-' + currentLink).addClass("manual_active");

    var firstLoading = true;
    var m_type = window.localStorage.getItem('m_type');

    if (m_type === null) {
      get(url.GET_USER_PROFILE).then(res => {
        window.localStorage.setItem('m_id', res.id);
        if(res.user_type == 1) {
          if(currentLink == 'dashboard') {
            if(firstLoading){
              history.push(url.URL_LINK_ADMIN_USERTOTAL);
              firstLoading = false;
            }
          }
          setMenu('1');
          window.localStorage.setItem('m_type', '1');
        } else {
          setMenu('2');
          window.localStorage.setItem('m_type', '2');
        }
      });
    }else{
      firstLoading = false;
    }
  })

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    props.toggleLeftmenu(!props.leftMenu)
    let changeIcon = document.querySelector('#changeIcon');

    function showSide(){
      props.changeSidebarType("condensed", isMobile)
      changeIcon.classList.remove('fa-bars');
      changeIcon.classList.add('fa-window-close');
      changeIcon.classList.add('close-style'); 
    }
    function hideSide(){
      props.changeSidebarType("default", isMobile)
      changeIcon.classList.remove('fa-window-close');
      changeIcon.classList.remove('close-style');
      changeIcon.classList.add('fa-bars'); 
    }

    if (props.leftSideBarType === "default") {
      showSide();
    } else if (props.leftSideBarType === "condensed") {
      hideSide();
    }
  }
  return (
    <React.Fragment>
      
      <header id="page-topbar" className="cstm-topbar-dashboard">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="20" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="20" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" id="changeIcon" />
            </button>

            {menuType && menuType.length > 0 && menuType == '2' ? (
            <div className="manual_menu">
                {/* <Link to="/keys" style={{marginLeft: "16px",fontSize: "18px",fontWeight: "",cursor: "pointer"}}>Keys</Link> */}
                <Link to="/home" className="cst-menu-anchors">
                    <img src={logoImage} className="logo" alt="" />
                </Link>
                <Link to="/pricing" className="cst-menu-anchors">Pricing</Link>
                {/* <Link to="/rightsholders" style={{marginLeft: "16px",fontSize: "18px",fontWeight: "",cursor: "pointer"}}>Rightsholders</Link> */}
                <Link to="/about-us" className="cst-menu-anchors">About Us</Link>
                <Link to="/api" className="cst-menu-anchors">API</Link>
                <Link to="/dashboard" className="cst-menu-anchors mm-active_ menu-top menu-top-dashboard">Dashboard</Link>
                <Link to="/logout" className="LogOutClassName">Logout</Link>
            </div>
            ) : menuType == '1' ? (
            <div className="manual_menu">
              <Link to="/home" className="cst-menu-anchors">
                  <img src={logoImage} className="logo" alt="" />
                  {/* <Link to="/logout" className="LogOutClassName">Logout</Link> */}
                  <Link className="LogOutClassName"
                  onClick={() => {
                    tog_backdrop()
                  }}
                  data-toggle="modal"
                  >Logout</Link>
              </Link>
            </div>
            ) : (
            <div className="manual_menu">
              <Link to="/home" className="cst-menu-anchors">
                  <img src={logoImage} className="logo" alt="" />
                  {/* <Link to="/logout" className="LogOutClassName">Logout</Link> */}
                  <Link className="LogOutClassName"
                  onClick={() => {
                    tog_backdrop()
                  }}
                  data-toggle="modal"
                  >Logout</Link>
              </Link>
            </div>)}

            {/* <button
              type="button"
              className="btn btn-primary waves-effect waves-light"
              onClick={() => {
                tog_backdrop()
              }}
              data-toggle="modal"
            >
              Static backdrop modal
            </button> */}
            <Modal
              isOpen={modal_backdrop}
              toggle={() => {
                tog_backdrop()
              }}
              scrollable={true}
              id="staticBackdrop"
            >
              <div className="modal-header">
                {/* <h5 className="modal-title" id="staticBackdropLabel">Are you sure you want to logout?</h5>
                <button type="button" className="btn-close"
                  onClick={() => {
                    setmodal_backdrop(false)
                  }} aria-label="Close"></button> */}
              </div>
              <div className="modal-body">
                <p style={{fontSize: 18,fontFamily: "'IBM Plex Sans', sans-serif"}}>Are you sure you want to logout?</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-light" onClick={() => {
                  setmodal_backdrop(false)
                }}>Cancel</button>
                <button type="button" className="btn btn-danger"><Link style={{color:"white"}} to="/logout">Logout</Link></button>
              </div>
            </Modal>

            {/* <Form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder={props.t("Search") + "..."}
                />
                <span className="uil-search"></span>
              </div>
            </Form> */}
          </div>

          <div className="d-flex">
            <ProfileMenu />

            

          </div>
        </div>
      </header>
      
      <div className='modal-popup'>
        <div className='modal-blur'></div>
        <div className='modal-container p-4'>
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Subscription Required!</h5>
            {/* <button type="button" class="close" onClick={(e)=>{closeModal()}}>
              <span aria-hidden="true">&times;</span>
            </button> */}
          </div>
          <div class="modal-body py-4">
            <h6 className='text-left'>You need to subscribe first in order to Add Stores.</h6>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" onClick={(e)=>{changeRoutForModal()}}>Subscribe</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={(e)=>{changeRoutForModalDashboard()}}>Close</button>
          </div>
        </div>
      </div>
    </React.Fragment>
    /*
    <div onClick={() => {
              props.showRightSidebarAction(!props.showRightSidebar)
            }}
              className="dropdown d-inline-block">
              <button type="button" className="btn header-item noti-icon right-bar-toggle waves-effect">
                <i className="uil-cog"></i>
              </button>
            </div>
    */
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))