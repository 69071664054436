import React, { useEffect, useState } from "react";
import { get } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const AmazonPay = (props) => {
  const [amazonAuthorize, setAmazonAuthorize] = useState(false);
  const [billingDetails, setBillingDetails] = useState("");
  const location = useLocation();
  useEffect(() => {
    get(url.AMAZON_PAY_DETAILS)
      .then((response) => {
        if (response && response?.billing_name) {
          setAmazonAuthorize(true);
          setBillingDetails(response);
        }
      })
      .catch((err) =>
        console.log("Error while fetching Amazon Billing Details")
      );
  }, [location]);

  function changeBilling() {
    setAmazonAuthorize(false);
  }

  function refreshPage() {
    window.location.reload();
  }

  return (
    <div>
      <div
        style={
          amazonAuthorize == false ? { display: "block" } : { display: "none" }
        }
      >
        <h5>Payment Authorization</h5>
        <p>
          To use Amazon Pay as your default payment method, you need to
          authorize Koloden.com to charge you on a monthly basis.
          <br></br> This is a one-step process. Please click the button below to
          complete the payment authorization process (If you don't see the
          button below then{" "}
          <a href="javascript:void(0)" onClick={refreshPage}>
            click here
          </a>{" "}
          to refresh the page). <br />
          <span style={{ color: "red" }}>Important: </span> Please note that the
          authorization charge is <strong>$1.00</strong>
        </p>

        <div style={{ width: "200px" }}>
          <div id="AmazonPayButton"></div>
        </div>
      </div>

      <div
        style={
          amazonAuthorize == true ? { display: "block" } : { display: "none" }
        }
      >
        <h5>Billing Details</h5>
        <div>
          <a
            href="javascript:void(0)"
            onClick={changeBilling}
            style={{ float: "right" }}
          >
            Change Billing Information
          </a>
        </div>
        <table className="table">
          <tr>
            <td>Full Name</td>
            <td>{billingDetails.billing_name}</td>
          </tr>
          <tr>
            <td>Email Address</td>
            <td>{billingDetails.billing_email}</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>
              {billingDetails.billing_address_line1},{" "}
              {billingDetails.billing_city}, {billingDetails.billing_state}{" "}
              {billingDetails.billing_postal_code},{" "}
              {billingDetails.billing_country}
            </td>
          </tr>
          <tr>
            <td>Payment Preference</td>
            <td>{billingDetails.payment_preference}</td>
          </tr>
        </table>
      </div>
      <Helmet>
        <script
          async="async"
          type="text/javascript"
          src=" https://static-na.payments-amazon.com/OffAmazonPayments/us/sandbox/js/Widgets.js"
        ></script>

        <script
          type="text/javascript"
          charset="utf-8"
          src="js/amazonpay-starter.js"
        ></script>
      </Helmet>
    </div>
  );
};

export default AmazonPay;
