import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect, useState } from "react"
import { 
  Container, 
  Row, 
  Col, 
  CardBody, 
  Card, 
  CardTitle,
  Dropdown,
  Alert,
  Button,
  Table,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  Form,
  Label,
  Input,
  FormGroup } from "reactstrap"
import { Link } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import "../../assets/scss/custom-style.css"
import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import config from 'dotenv';

import { openModal, closeModal, changeRoutForModal } from "../../components/VerticalLayout/globalFunction";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Components
import { MDBDataTable } from "mdbreact"

import addAmazonStore from "./img/add-amazon-store.jpg";
import authorizationAmazonSeller from "./img/authorization-amazon-seller.jpg";
 

const AddStoreSp = props => {
  const [successMessage, setSuccessMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [selectOptions, setOptions] = useState([]);
  const [amazonMarketRegion, setAmazonMarketRegion] = useState([]);

   useEffect(() => {
     setSuccessMessage('')
     setErrMessage('')
     getStoreList();
     
     if(props.location.search) {
        console.log(props.location.search);
        const wishcode = props.location.search.replace("?code=","");
        //setWishCode(wish_code);
        handleWishSubmit(wishcode);
     }

     checkSubscriptionAndPopUp();
   }, [])

  const getStoreList = () => {    
    get(url.GET_STORES)
    .then(res => {
      const options = res.map(d => ({
        "value" : d.id,
        "label" : d.name
      }))
      setOptions(options);
    })
    .catch(error => {
      setErrMessage(error)
    });
  }

  const checkSubscriptionAndPopUp = async (e) => {
    try {
      const userObj = JSON.parse(localStorage.getItem("authUser"));
      const response = await axios.get('https://koloden.com/v2api/public/api/stripe/subscription?userid='+userObj.id);
      if (response.status === 200) {
        if(response.data.status != 'paid') {
          openModal();
        }
      }else{
        openModal();
      }
      // Handle success
    } catch (error) {
      openModal();
    }
  }

  const shoot = () => {

    setSuccessMessage('')
    setErrMessage('')

    var value_th = document.getElementById('select_platform').value;
    var none_platform = document.getElementById('none_platform');
    var wish = document.getElementById('wish');
    var ebay = document.getElementById('ebay');
    var amazon = document.getElementById('amazon');
    var walmart = document.getElementById('walmart');
    
    if(value_th == 0){
      none_platform.style.display = 'block';
      wish.style.display = 'none';
      amazon.style.display = 'none';
      ebay.style.display = 'none';
      walmart.style.display = 'none';
    }
    if(value_th == 1){
      none_platform.style.display = 'none';
      wish.style.display = 'block';
      amazon.style.display = 'none';
      ebay.style.display = 'none';
      walmart.style.display = 'none';
    }
    if(value_th == 2){
      none_platform.style.display = 'none';
      wish.style.display = 'none';
      amazon.style.display = 'block';
      ebay.style.display = 'none';
      walmart.style.display = 'none';
    }
    if(value_th == 3){
      none_platform.style.display = 'none';
      wish.style.display = 'none';
      amazon.style.display = 'none';
      ebay.style.display = 'block';
      walmart.style.display = 'none';
    }
    if(value_th == 4){
      none_platform.style.display = 'none';
      wish.style.display = 'none';
      amazon.style.display = 'none';
      ebay.style.display = 'none';
      walmart.style.display = 'block';
    }
  }
  
  const handleWishSubmit = (wish_code) => {
    setSuccessMessage('')
    setErrMessage('')

    const wish_client_id = localStorage.getItem('wish_client_id');
    const wish_store_name = localStorage.getItem('wish_store_name');
    const wish_store_id = localStorage.getItem('wish_store_id');
    const wish_client_secret = localStorage.getItem('wish_client_secret');
    //localStorage.removeItem("wish_client_id")
    //localStorage.removeItem("wish_store_name")
    //localStorage.removeItem("wish_store_id")
    //localStorage.removeItem('wish_client_secret')

    if(wish_client_id == null) {
      setErrMessage('Wish client id not found, please try again.');
      return;
    }

    if(wish_store_name == null) {
      setErrMessage('Wish store name not found, please try again.');
      return;
    }

    if(wish_store_id == null) {
      setErrMessage('Wish store id not found, please try again.');
      return;
    }

    if(wish_client_secret == null) {
      setErrMessage('Wish client secreat not found, please try again.');
      return;
    }

    if(wish_code == null) {
      setErrMessage('Wish code expired, please try again.');
      return;
    }

    const wish_data = { store:'wish', store_id: wish_store_id, store_name: wish_store_name, client_id: wish_client_id, client_secret: wish_client_secret, access_token: wish_code, refresh_token: '' };
    console.log(wish_data);

    post(url.POST_USERSTORES, wish_data)
    .then(response => {
      if(response.message == "success") {
        document.getElementById('select_platform').value = 0;
        document.getElementById('wish').style.display = 'none';
        setSuccessMessage('Wish store successfully created, Click <a href="wish">here</a> to view store');

        get(url.GET_USER_PROFILE) 
        .then(res => {
            if(res) {
              localStorage.setItem("authUser", JSON.stringify(res));
            }
        })
        .catch(error => {
          console.log(error);
        });
      }
      // if(response == 0) {
      //   setErrMessage('Your current password is wrong')
      //   return response
      // }
      // localStorage.removeItem("authUser")
      // localStorage.setItem("authUser", JSON.stringify(values))
      // setSuccessMessage('Profile update successfully')
      // return response.data
    })
    .catch(error => {
      console.log(error);
      if(error && error.response && error.response.data && error.response.data.error) {
        setErrMessage(error.response.data.error);
      } else {
        setErrMessage('Request can not be processed at this time');
      }
      // let errorText = '';
      // for (var x in error.response.data.errors) {
      //   errorText += error.response.data.errors[x] + '</br>'
      // } 
      // setErrMessage(errorText)
    });
  }

  const handleAmazonRequest = (event, values) => {
    setSuccessMessage('')
    setErrMessage('')
    
    let marketplaceId = 'ATVPDKIKX0DER'; // US
    let marketpalceUrl = process.env.REACT_APP_USA_MARKET_URL;
    if(amazonMarketRegion == 'canada') {
      marketplaceId = 'A2EUQ1WTGCTBG2';
      marketpalceUrl = process.env.REACT_APP_CANADA_MARKET_URL;
    }
    const amazon_data = { store:'amazon', store_id: document.getElementById('select_platform').value, store_name: values['amazon-store-name'], amazon_seller_central_email: values['amazon-seller-central-email'], marketplace: marketplaceId };
     
   //window.location.href = `${marketpalceUrl}/apps/authorize/consent?application_id=${process.env.REACT_APP_SELLER_CENTRAL_APP_ID}&state=mystate&version=beta`
    post(url.POST_USERSTORES_SP, amazon_data)
    .then(response => {
      if(response.message == "success") {
        document.getElementById('select_platform').value = 0;
        document.getElementById('amazon').style.display = 'none';
        const stateId = response.stateId;
        
        // TODO: remove version=beta for the following URL for production usage.
        window.location.href = `${marketpalceUrl}/apps/authorize/consent?application_id=${process.env.REACT_APP_SELLER_CENTRAL_APP_ID}&state=${stateId}&version=beta`
        setSuccessMessage('You will be redirected soon to Amazon Seller Central website for authentication and authorization.');
      }
      else {
        setSuccessMessage('');
        setErrMessage(response.message);
      }
    })
    .catch(error => {
      console.log(error)
      if(error &&  error.response && error.response.data && error.response.data.error)
        setErrMessage(error.response.data.error);
      else
        setErrMessage('Request can not be processed at this time');
    });
  }

  const handleWishRequest = (event, values) => {
      
    setSuccessMessage('')
    setErrMessage('')

    //const wish_d = '{"store_id":1,"store_name":"wise","client_id":"123123123123","client_secret":"Satti"}'
    localStorage.setItem('wish_client_id', values['wish_client_id']);
    localStorage.setItem('wish_store_name', values['wish_store_name']);
    localStorage.setItem('wish_store_id', document.getElementById('select_platform').value);
    localStorage.setItem('wish_client_secret', values['wish_client_secret']);

    //const wishurl = "https://merchant.wish.com/oauth/authorize?client_id=" + values['wish_client_id'];
    //const wishurl = "https://sandbox.merchant.wish.com/v3/oauth/authorize?client_id=" + values['wish_client_id'];
    const wishurl = "https://merchant.wish.com/v3/oauth/authorize?client_id=" + values['wish_client_id'];

    console.log(wishurl);
    window.location.href = wishurl; 
    return null;
  }
  
  const handleEbaySubmit = (event, values) => {
    setSuccessMessage('')
    setErrMessage('')
    const ebay_data = { store:'ebay', store_id: document.getElementById('select_platform').value, store_name: values['ebay_store_name'], client_id: values['ebay_seller_id'], client_secret: '', access_token: '', refresh_token: '' };

    post(url.POST_USERSTORES, ebay_data)
    .then(response => {
      console.log(response)
      if(response.message == "success") {
        document.getElementById('select_platform').value = 0;
        document.getElementById('ebay').style.display = 'none';
        setSuccessMessage('eBay store successfully created, <a href="eBay">Click here</a> to view store');

        get(url.GET_USER_PROFILE) 
        .then(res => {
            if(res) {
              localStorage.setItem("authUser", JSON.stringify(res));
            }
        })
        .catch(error => {
          console.log(error);
        });
      }
      else {
        setSuccessMessage('');
        setErrMessage(response.message);
      }
    })
    .catch(error => {
      console.log(error)
      if(error &&  error.response && error.response.data && error.response.data.error)
        setErrMessage(error.response.data.error);
      else
        setErrMessage('Request can not be processed at this time');
    });
  }  

  const handleWalmartSubmit = (event, values) => {
    setSuccessMessage('')
    setErrMessage('')
    const ebay_data = { store:'walmart', store_id: document.getElementById('select_platform').value, store_name: values['walmart_store_name'], client_id: values['walmart_client_id'], client_secret: values['walmart_client_secret'], access_token: '', refresh_token: '' };

    post(url.POST_USERSTORES, ebay_data)
    .then(response => {
      console.log(response)
      if(response.message == "success") {
        document.getElementById('select_platform').value = 0;
        document.getElementById('walmart').style.display = 'none';
        setSuccessMessage('Walmart store successfully created, <a href="walmart">Click here</a> to view store');

        get(url.GET_USER_PROFILE) 
        .then(res => {
            if(res) {
              localStorage.setItem("authUser", JSON.stringify(res));
            }
        })
        .catch(error => {
          console.log(error);
        });
      }
      else {
        setSuccessMessage('');
        setErrMessage(response.message);
      }
    })
    .catch(error => {
      console.log(error)
      if(error &&  error.response && error.response.data && error.response.data.error)
        setErrMessage(error.response.data.error);
      else
        setErrMessage('Request can not be processed at this time');
    });
  }  


    return (
      <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Koloden" breadcrumbItem="Add Store" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                <Row style={{margin: '30px 0', marginTop: "10px"}}>
                            
                  <Col className="col-sm-3 col-12">
                    <Label htmlFor="horizontal-email-input" className=" col-form-label">Select your Platform</Label>
                  </Col>
                  <Col className="col-sm-3 col-12">
                    <select className="select_Platform" id="select_platform" onChangeCapture={shoot} style={{width: "100%"}}>
                     <option value="0">Select Platform</option>
                      {selectOptions.map((opt, index) => (
                          <option key={opt.value} value={opt.value}>{opt.label}</option>
                      ))}
                    </select>
                  </Col>
                  </Row>

                  <Row></Row>
                  <Row></Row>
                  <Row></Row>
                  <Row></Row>
                  <Row><p className=" col-form-label">Note: Products will appear in the dashboard usually within 24 hours.</p></Row>

                  <Row>
                  <Col id="none_platform" lg={8} className="mr-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <h5 className="font-size-14 mb-4"></h5>
                      </div>
                    </Col>

                    {successMessage ? (
                        <div className="alert alert-success fade show">
                          <div dangerouslySetInnerHTML={{__html: successMessage}} />
                        </div>
                      ) : null}

                     {errMessage ? (
                        <Alert name="error-message" color="danger">{errMessage}</Alert>
                      ) : null}

                    <Col id="wish" style={{display: 'none'}} lg={12} className="mr-lg-auto">
                      <div className="row">
                        <div className="mt-5 col-lg-8" >
                          <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Wish.com</h5>
                          
                          <AvForm name="wish-form" className="form-horizontal" onValidSubmit={(e, v) => {handleWishRequest(e, v)}} >
                            <Row className="mb-4">
                              <Col sm={12}>
                                <AvField name="wish_store_name" label="App Name *" type="text" validate={{
                                      required: {value: true, errorMessage: "Please enter App Name"},
                                      minLength: {value: 1, errorMessage: 'App Name must be greater then 3 characters'},
                                  }}/>

                                <div className="invalid-feedback" style={{display: "none"}}>This is an error field.</div>
                              </Col>
                            </Row>
                            <Row className="mb-4">
                              <AvField name="wish_client_id" label="Client ID *" type="text" validate={{
                                    required: {value: true, errorMessage: "Please enter Client ID"},
                                    minLength: {value: 1, errorMessage: 'Client ID must be greater then 3 characters'},
                                }}/>
                            </Row>
                            <Row className="mb-4">
                              <AvField name="wish_client_secret" label="Client Secret *" type="text" validate={{
                                    required: {value: true, errorMessage: "Please enter Client Secret"},
                                    minLength: {value: 1, errorMessage: 'Client Secret must be greater then 3 characters'},
                                }}/>
                            </Row>

                            <div className="row justify-content-start">
                              <Col sm={9}>
                                <div>
                                  <Button type="submit" color="primary" className="w-md">Submit</Button>
                                </div>
                              </Col>
                            </div>
                          </AvForm>

                          {/* FOR WISH STORE ------- STARTS HERE */}
                          <h4 className="instr-head">Instructions</h4>
                          <div className="row justify-content-center wish-container">
                              <div className="col-sm-12 instr">
                                  <h1>Step 1: Setup your Wish API credentials.</h1>
                                  <div className="details">
                                      <ul>
                                          <li>After logging in your Wish account (<a href="https://merchant.wish.com">https://merchant.wish.com</a>) click the <b>Settings</b> sub menu specified in Account menu at top right.</li>
                                          <li>In the settings page displayed, click <b>API Settings</b> in the left navigation pane.</li>
                                          <li>There you can enter your <b>App Name</b> (e.g., “Koloden”) and specify <b>Redirect URI</b> as <b>“https://koloden.com/frontend/web/site/app”</b> and click <b>Create App</b> button.</li>
                                          <li>In the popup displayed click <b>Confirm</b> button.  A page will be displayed where you can view your App Name, Client Id, Client Secret and Redirect URI.</li>
                                      </ul>
                                      <div className="img-detail">
                                          <img width="60%" src="kolodenTheme/img/wish-1.png" alt="" />
                                      </div>
                                      <ul>
                                          <li>Please jot down the Client ID and Client Secret for future reference.</li>
                                      </ul>
                                  </div>
                              </div>
                              <div className="col-sm-12">
                                  <h1>Step 2: Connect your Wish store to your Koloden account.</h1>
                                  <div className="details">
                                      <ul>
                                          
                                          <li>If you already have an account in Koloden please login, if not register using the URL ( <a href="https://koloden.com/register">https://koloden.com/register</a> ).</li>
                                          <li>After successful login, in Risk Status page (<a href="https://koloden.com/dashboard">https://koloden.com/dashboard</a>)Click <strong>Platforms</strong> link specified in left nav.</li>
                                          <li>In the page displayed click the <strong>Add Store</strong> button corresponding to Wish.com.</li>
                                      </ul>
                                      <div className="img-detail">
                                          <img width="100%" src="kolodenTheme/img/img.png" alt="" />
                                      </div>
                                      <ul>
                                          <li>In the page displayed enter the Store name, Client id and Secret key (which is displayed in your wish.com account <strong>API Settings</strong> page) and click <strong>Submit</strong> button.</li>
                                          <li>A Wish.com page will be displayed requesting for permission.&nbsp;Click <strong>Confirm</strong> button on the page and on the confirm popup displayed.</li>
                                          <li>Within ~24 hours, Koloden will integrate your Wish store and will start scanning your Wish store product listings on a daily basis.</li>
                                          <li>Repeat this process for additional Wish storefronts.</li>
                                      </ul>
                                      <div className="img-detail">
                                          <iframe src="https://play.vidyard.com/WrHYbCtC346ZQQSNSTbYCB?disable_popouts=1&v=4.2.30&type=inline" frameborder="0"></iframe>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          {/* WISH STORE ENDS HERE -------------------------- */}
                        </div>
                        <div className="mt-5 col-lg-4 text-center" >
                          <img src="https://koloden.com/v2api/public/cms/images/c0fc9b8063599ea7ef70dee25d14c96ac2cd4327.png" alt=""></img>
                        </div>
                      </div>
                      
                    </Col>
                    
                    <Col id="amazon" lg={12} style={{display: 'none'}} className="mr-lg-auto">
                      <div className="row">
                        <div className="mt-5 col-lg-8">
                          <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Amazon.com</h5>

                          <AvForm name="amazon-form" className="form-horizontal" onValidSubmit={(e, v) => {handleAmazonRequest(e, v)}} >
                            <Row className="mb-4">
                              <AvField name="amazon-store-name" label="Store Name *" type="text" validate={{
                                required: {value: true, errorMessage: "Please enter Store Name"},
                                minLength: {value: 1, errorMessage: 'Store Name must be greater then 3 characters'},
                              }}/>
                            </Row>
                            <Row className="mb-4">
                              <AvField name="amazon-seller-central-email" label="Seller Central Email *" type="email" validate={{
                                required: {value: true, errorMessage: "Please enter Amazon Seller Central email"},
                                minLength: {value: 4, errorMessage: 'Email must be greater then 3 characters'},
                              }}/>
                            </Row>
                          
                            {/* <Row className="mb-4">
                              <Label className="col-form-label">Marketplace</Label>
                              <select id="amazon-marketplace" className="chkbox-mrket form-control">
                                <option>---Select Marketplace---</option>
                                <option key="A2EUQ1WTGCTBG2" value="A2EUQ1WTGCTBG2">Canada</option>
                                <option key="ATVPDKIKX0DER" value="ATVPDKIKX0DER">US</option>
                              </select>
                            </Row> */}

                            <Row className="mb-4">
                              <Label className="col-form-label">Marketplace</Label>
                              <select id="amazon-marketplace" className="chkbox-mrket form-control" onChange={(e) => setAmazonMarketRegion(e.target.value)}>
                                <option>---Select Marketplace---</option>
                                <option key="A2EUQ1WTGCTBG2" value="canada">Canada</option>
                                <option key="ATVPDKIKX0DER" value="usa">US</option>
                              </select>
                            </Row>
                            
                            <div className="row justify-content-start">
                              <Col sm={9}>
                                <div>
                                  <Button type="submit" color="primary" className="w-md">Authenticate Account</Button>
                                </div>
                              </Col>
                            </div>
                          </AvForm>

                          {/* Amazon STORE ---------------- STARTS HERE */}
                          <h4 className="instr-head">Instructions</h4>
                          <div className="row justify-content-center">
                              <div className="col-sm-12 instr">
                                  <h1>Step 1: Setup a seller account in amazon.com</h1>
                                  <div className="details">
                                      <ul>
                                          <li>Create a Seller account in <a href="https://sellercentral.amazon.com" target="_blank">sellercentral.amazon.com</a>.</li>
                                          <li>Now to add products, click <strong>Inventory</strong>&nbsp;and select <b>Manage Inventory</b>.</li>
                                          <li>Click on <strong>Add a Product</strong> button and you can add products from here.</li>
                                      </ul>
                                  </div>
                              </div>
                              <div className="col-sm-12 instr">
                                  <h1>Step 2: Connect Amazon.com to Koloden.</h1>
                                  <div className="details">
                                      <ul>
                                          <li>If you already have an account in Koloden please login, if not register using the URL (<a href="https://koloden.com/register">https://koloden.com/register/</a>).</li>
                                          <li>After successful login, click the <strong>Add Stores</strong> link in the left navigation.</li>
                                          <li>Select <strong>Amazon.com</strong> from the Platform dropdown.</li>
                                      </ul>
                                      <div className="img-detail">
                                          <img width="70%" src={addAmazonStore} alt="" />
                                          {/* <img width="70%" src="https://koloden.com/kolodenTheme/img/add-amazon-store.jpg" alt="" /> */}
                                          {/* <img width="100%" src="kolodenTheme/img/img.png" alt="" /> */}
                                      </div>
                                      <ul>
                                
                                          <li>Store name – Enter a name for your store.</li>
                                          <li>Seller Central Email – The email address associated with your Amazon Seller Central account. This should be a unique email address for Koloden.com.</li>
                                          <li>Marketplace – Select your area of sales (e.g. U.S.).</li>
                                          <li>Click the <strong>Authenticate Account</strong> button. This will redirect you to the Amazon Seller Central website where you can login with your Seller Central account.</li>
                                          <li>Once logged in, Amazon will redirect you to the authorization page. Please review the permissions and click the <strong>Confirm</strong> button to authorize Koloden.com to complete the store registration process.</li>
                                          <div className="img-detail">
                                            <img width="70%" src={authorizationAmazonSeller} alt="" />
                                            {/* <img width="70%" src="https://koloden.com/kolodenTheme/img/authorization-amazon-seller.jpg" alt="" /> */}
                                            {/* <img width="100%" src="kolodenTheme/img/Picture2.png" alt="" /> */}
                                        </div>
                                          <li>After successful authorization, you will be redirected back Koloden.com.</li>
                                          <li>Within ~24 hours your Amazon store will be integrated with your Koloden account.</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>

                          {/* AMAZON STORE ENDS HERE ---------------- */}
                        </div>
                        <div className="mt-5 col-lg-4 text-center" >
                          <img src="https://koloden.com/v2api/public/cms/images/a9b64900c452c70f89e97a36815b6a21321a695d.png" alt=""></img>
                        </div>
                      </div>
                    </Col>

                    <Col id="ebay" lg={12} style={{display: 'none'}} className="mr-lg-auto">
                      <div className="row">
                        <div className="mt-5 col-lg-8">
                          <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Ebay.com</h5>

                          <AvForm name="ebay-form" className="form-horizontal" onValidSubmit={(e, v) => {handleEbaySubmit(e, v)}} >
                            <Row className="mb-4">
                                <AvField name="ebay_store_name" label="Store Name *" type="text" validate={{
                                    required: {value: true, errorMessage: "Please enter Store Name"},
                                    minLength: {value: 1, errorMessage: 'Store Name must be greater then 3 characters'},
                                  }}/>
                            </Row>
                            <Row className="mb-4">
                              <AvField name="ebay_seller_id" label="Seller ID *" type="text" validate={{
                                required: {value: true, errorMessage: "Please enter Seller ID"},
                                minLength: {value: 1, errorMessage: 'Seller ID must be greater then 3 characters'},
                              }}/>
                            </Row>

                            <div className="row justify-content-start">
                              <Col sm={9}>
                                <div>
                                  <Button type="submit" color="primary" className="w-md">Submit</Button>
                                </div>
                              </Col>
                            </div>
                          </AvForm>

                          {/* FOR EBAY STORE ------------- STARTS HERE */}
                          <h4 className="instr-head">Instructions</h4>
                          <div className="row justify-content-center ebay-container">
                                  <div className="col-sm-12 instr">
                                      <h1>Step 1: Obtain eBay.com Seller ID.</h1>
                                      <div className="details">
                                          <ul>
                                              <li>When logged into your eBay Seller account, you can see your Seller ID as in the image below:</li>
                                          </ul>
                                          <div className="img-detail">
                                              <img width="70%" src="kolodenTheme/img/image55.png" alt="" />
                                          </div>
                                          <ul>
                                              <li>Please note down the Seller ID for future reference.</li>
                                          </ul>
                                      </div>
                                  </div>
                                  <div className="col-sm-12">
                                      <h1>Step 2: Connect your eBay store to your Koloden account.</h1>
                                      <div className="details">
                                          <ul>
                                              
                                              <li>If you already have an account in Koloden please login, if not register using the URL ( <a href="https://koloden.com/register">https://koloden.com/register</a> ).</li>
                                              
                                              <li>After successful login, in Risk Status page (<a href="https://koloden.com/dashboard">https://koloden.com/dashboard</a>) Click <strong>Platforms</strong> link specified in left nav.</li>
                                              <li>In the page displayed click the <strong>Add Store</strong> button corresponding to Ebay.com.</li>
                                          </ul>
                                          <div className="img-detail">
                                              <img width="100%" src="kolodenTheme/img/img (1).png" alt="" />
                                          </div>
                                          <ul>
                                              <li>In the popup, enter the Store name, Seller ID (which you obtained in Step 1) and click <strong>Submit</strong> button.</li>
                                              <li>You will be redirected to your Koloden dashboard after successful submission.</li>
                                              <li>Within ~24 hours, Koloden will integrate your eBay store and will start scanning your eBay store product listings on a daily basis.</li>
                                              <li>Repeat this process for additional eBay storefronts.</li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                        {/* EBAY STORE ENDS HERE -------- */}
                        </div>
                        <div className="mt-5 col-lg-4 text-center" >
                          <img src="https://koloden.com/v2api/public/cms/images/c50f63e5d16b95b9ac0dc50b033a3b3f87bbdba1.png" alt=""></img>
                        </div>
                      </div>
                      
                    </Col>

                    <Col id="walmart" lg={12} style={{display: 'none'}} className="mr-lg-auto">
                      <div className="row">
                        <div className="mt-5 col-lg-8">
                          <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Walmart.com</h5>

                          <AvForm name="walmart-form" className="form-horizontal" onValidSubmit={(e, v) => {handleWalmartSubmit(e, v)}} >
                            <Row className="mb-4">
                              <AvField name="walmart_store_name" label="Store Name *" type="text" validate={{
                                required: {value: true, errorMessage: "Please enter Store Name"},
                                minLength: {value: 1, errorMessage: 'Store Name must be greater then 3 characters'},
                              }}/>
                            </Row>
                            <Row className="mb-4">
                              <AvField name="walmart_client_id" label="Client ID *" type="text" validate={{
                                required: {value: true, errorMessage: "Please enter Client ID"},
                                minLength: {value: 1, errorMessage: 'Client ID must be greater then 3 characters'},
                              }}/>
                            </Row>
                            <Row className="mb-4">
                              <AvField name="walmart_client_secret" label="Client Secret *" type="text" validate={{
                                required: {value: true, errorMessage: "Please enter Client Secret"},
                                minLength: {value: 1, errorMessage: 'Client Secret must be greater then 3 characters'},
                              }}/>
                            </Row>
                            <div className="row justify-content-start">
                              <Col sm={9}>
                                <div>
                                  <Button type="submit" color="primary" className="w-md">Submit</Button>
                                </div>
                              </Col>
                            </div>
                          </AvForm>

                          {/* WALLMART STORE ----------- STARTS HERE */}
                          <h4 className="instr-head">Instructions</h4>
                          <div className="row justify-content-center">
                                <div className="col-sm-12 instr">
                                    <h1>Step 1 : Setup your Walmart API credentials</h1>
                                    <div className="details">
                                        <ul>
                                            <li>Login to your walmart seller account at&nbsp;https://seller.walmart.com/login</li>
                                            <li>From the Settings menu click on <strong>Consumer IDs &amp; Private Keys</strong> under API section.</li>
                                            <li>Click on <strong>Walmart Developer Portal</strong> button in the api page</li>
                                            <li>Login to the developer portal</li>
                                            <li>Copy the Client ID and Client Secret</li>
                                        </ul>
                                        <div className="img-detail">
                                            <img width="100%" src="kolodenTheme/img/wallmart-devportal_hiden.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div     className="col-sm-12">
                                    <h1>Step 2 : Connect Walmart.com to Koloden.</h1>
                                    <div className="details">
                                        <ul>
                                            
                                            <li>if you already have an account in Koloden please login, if not register using the URL (&nbsp;<a href="https://koloden.com/register">https://koloden.com/register</a>&nbsp;).</li>
                                            
                                            <li>After successful login, in Risk Status page (<a href="https://koloden.com/dashboard">https://koloden.com/dashboard</a>)Click&nbsp;<strong>Platforms</strong>&nbsp;link specified in left nav.</li>
                                            <li>In the page displayed click the&nbsp;<strong>Add Store</strong>&nbsp;button corresponding to Walmart.com.</li>
                                        </ul>
                                        <div className="img-detail">
                                            <img width="100%" src="kolodenTheme/img/walmart_menu.png" alt="" />
                                        </div>
                                        <ul>
                                            <li>In the page displayed enter the details below and click&nbsp;<strong>Submit</strong>&nbsp;button.</li>
                                            <li>Store name – Enter a name for your store.</li>
                                            <li>Client ID – The Client ID taken from walmart development portal</li>
                                            <li>Client Secret- The Client Secret taken from walmart development portal</li>
                                            <li>You will be redirected to the Koloden dashboard after successful submission.</li>
                                            <li>Within ~24 hours your Walmart store will be integrated with your Koloden account.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                          {/* WALLMART STORE ENDS HERE ---------------- */}
                        </div>
                        <div className="mt-5 col-lg-4 text-center" >
                          <img src="https://koloden.com/v2api/public/cms/images/ab958d3d0f3cb93610765c863fd85a689f8916ab.png" alt=""></img>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
    )
  }

export default AddStoreSp