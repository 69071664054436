import React, { useEffect, useState } from "react"
import { Bar } from "react-chartjs-2"
import { Container, Row, Col, CardBody, Card, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"
import { post, del, get, put } from "../../../src/helpers/api_helper"
import * as url from "../../../src/helpers/url_helper"
import ReactApexChart from "react-apexcharts"
import axios from 'axios'
import { getDefaultPaymentMethod } from "../../helpers/payment_method_helper"


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Components
import MiniWidget from "./mini-widget"
//import SplineArea from "../AllCharts/apex/SplineArea"
import TopUser from "./topuser"
import Doughnut from "../AllCharts/echart/doughnutchart"

//Import Image
import setupanalytics from "../../assets/images/setup-analytics-amico.svg"
import $ from 'jquery';
import RiskFeed from "../RiskFeed"

const series1 = [{
  data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54]
}]

const options1 = {
  fill: {
    colors: ['#00ab4e']
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '50%'
    }
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1
    },
  },
  tooltip: {
    fixed: {
      enabled: !1
    },
    x: {
      show: !1
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return ''
        }
      }
    },
    marker: {
      show: !1
    }
  }
};

const series2 = [70]

const options2 = {
  fill: {
    colors: ['#34c38f']
  },
  chart: {
    sparkline: {
      enabled: !0
    }
  },
  dataLabels: {
    enabled: !1
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: '60%'
      },
      track: {
        margin: 0
      },
      dataLabels: {
        show: !1
      }
    }
  }
};

const series3 = [55]

const options3 = {
  fill: {
    colors: ['#00ab4e']
  },
  chart: {
    sparkline: {
      enabled: !0
    }
  },
  dataLabels: {
    enabled: !1
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: '60%'
      },
      track: {
        margin: 0
      },
      dataLabels: {
        show: !1
      }
    }
  }
};

const series4 = [{
  data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
}]

const options4 = {

  fill: {
    colors: ['#f1b44c']
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '50%'
    }
  },
  labels: [1, 22, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1
    },
  },
  tooltip: {
    fixed: {
      enabled: !1
    },
    x: {
      show: !1
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return ''
        }
      }
    },
    marker: {
      show: !1
    }
  }
};

const Dashboard = props => {

  const [successMessage, setSuccessMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [card, setCard] = useState('');
  const [reports_data, setReport] = useState([]);
  const [bar_data, setBarData] = useState({});
  const [series_data, setSeriesData] = useState([]);
  const [category_data, setCategoryData] = useState([]);
  const [selectedHistory, setHistory] = useState('');
  const [menuType, setMenu] = useState([])

  useEffect(() => {
    setReport([])
    setSuccessMessage('')
    setErrMessage('')
    getReports()
    getBarData()
    setSeriesData([])
    getSeries();
    setCategoryData([]);
    setMenu([]);
    getDefaultPaymentMethod();
  }, [])

  $(function() {
    const defaultPaymentMethod = window.localStorage.getItem('default_payment_method');
    const defaultPaymentSource = window.localStorage.getItem('default_payment_source');
    const cardInfo = window.localStorage.getItem('m_card');
    if( ((!defaultPaymentMethod || defaultPaymentMethod == 'card') && 
     (!defaultPaymentSource || defaultPaymentSource == 'N/A')) && 
      (!cardInfo || cardInfo == '0') ) {
      setCard(0);
    } else {
      setCard(1);
    }

    var m_type = window.localStorage.getItem('m_type');
    if(m_type == '1') {
      $('#menu-users').show();
      window.location.href = '/dashboard/user-totals';
      setMenu('1');
    } else if(m_type == '2') {
      $('#menu-users').html(''); 
      setMenu('2');
    } else {
      setMenu('2');
    }
  })

  const reports = []

  const getReports = async () => {

    let td = new Date().toISOString().slice(0, 10);
    var uid = localStorage.getItem('m_id');
    var cdrt = localStorage.getItem('c_d_r_t_' + uid + '_' + td);

    if(cdrt){
      var res = JSON.parse(cdrt);
      res.map(d => (
        reports.push({
        "id" : d.id,
        "icon" : d.icon,
        "title" : d.title,
        "value" : d.value,
        "decimal" : d.decimal,
        "prefix" : d.prefix,
        "suffix" : d.suffix,
        "charttype" : d.chart_type,
        "chartheight" : d.chart_height,
        "chartwidth" : d.chart_width,
        "badgeValue" : d.badge_value,
        "color" : d.color,
        "desc" : d.desc,
        "series" : d.series,
        "options" : options1,
      })))
      setReport(reports);
    }else{
      await post(url.CHART_DASHBOARD_REPORTS_TODAY) 
      .then(res => {
          if(res) {
              localStorage.setItem('c_d_r_t_' + uid + '_' + td, JSON.stringify(res));
              res.map(d => (
                reports.push({
                "id" : d.id,
                "icon" : d.icon,
                "title" : d.title,
                "value" : d.value,
                "decimal" : d.decimal,
                "prefix" : d.prefix,
                "suffix" : d.suffix,
                "charttype" : d.chart_type,
                "chartheight" : d.chart_height,
                "chartwidth" : d.chart_width,
                "badgeValue" : d.badge_value,
                "color" : d.color,
                "desc" : d.desc,
                "series" : d.series,
                "options" : options1,
              })))
          }
        setReport(reports);
      })
      .catch(error => {
        //setErrMessage(error)
      });
    }
  }

  const barData = {}
  const bar_options = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.4,
        },
      ],
    },
    legend: {
        display: false,
    },
  }

  const data = {}

  const getBarData = async () => {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    today = yyyy + '-' + mm

    let td = new Date().toISOString().slice(0, 10);
    var uid = localStorage.getItem('m_id');
    var cdcbp = localStorage.getItem('c_d_ce_p_' + uid + '_' + td);

    if(cdcbp){
      var res = JSON.parse(cdcbp);
      let dd = res
      setBarData(dd);
    }else{
      await post(url.CHART_DASHBOARD_CE_BY_PLATFORM + '?ym=' + today)
      .then(res => {
          localStorage.setItem('c_d_ce_p_' + uid + '_' + td, JSON.stringify(res));
          let dd = res
          setBarData(dd);
      })
      .catch(error => {
        //setErrMessage(error)
      });
    }
  }

  const option = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.4,
        },
      ],
    },
    legend: {
        display: false,
    },
  }

  function handleHistoryChange(e) {
    var history = e.target.value
    setHistory(history)
    post(url.CHART_DASHBOARD_SERIES + '?h=' + history) 
    .then(res => {
        if(res) {
            //category_data.xaxis.categories= res[0].xaxis;
            setCategoryData(res[0].xaxis);
            res.map(d => (
              series.push({
              "name" : d.name,
              "data" : d.data
            })))
        }
      
      //setCategoryData(category_data);
      setSeriesData(series);
    })
    .catch(error => {
      //setErrMessage(error)
    });
  }

  async function handleMonthChange(e) {
    var today = e.target.value
    await post(url.CHART_DASHBOARD_CE_BY_PLATFORM + '?ym=' + today) 
    .then(res => {
        let dd = res
        setBarData(dd);
    })
    .catch(error => {
      //setErrMessage(error)
    });
  }

  function leftPad(number, targetLength) {
    var output = number + '';
    while (output.length < targetLength) {
        output = '0' + output;
    }
    return output;
  }
  function createSelectMonths() {
    var d = new Date()
    var y = d.getFullYear()
    var m = d.getMonth()
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ]
    let months = []       
    for (let i = 1; i <= 12; i++) {
        if(m == (i-1)){
          months.push(<option value={y + '-' + leftPad(i, 2)} selected>{monthNames[i-1]}</option>)
        }else{
          months.push(<option value={y + '-' + leftPad(i, 2)}>{monthNames[i-1]}</option>)
        }
    }
    return months;
}


const series = []

const series_options = {
  dataLabels: {
    enabled: !1
  },
  stroke: {
    curve: 'smooth',
    width: 3,
  },
  series: [{
    name: 'Listings',
    data: [11, 40, 28, 52, 42, 109, 100, 11, 40, 28, 52, 42]
  },
  {
    name: 'series2',
    data: [60, 34, 46, 34, 52, 41, 22, 60, 34, 46, 34, 52]
  },
  {
    name: "series3",
    data: [50, 31, 41, 14, 92, 11, 33, 50, 31, 41, 14, 92],
  },
  {
    name: "series4",
    data: [14, 92, 11, 30, 50, 31, 14, 92, 11, 30, 50, 31],
  }
],
  colors: ['black', '#f46a6a', '#f1b44c', '#34c38f', '#909090'],
  xaxis: {
    type: 'text',
    categories: [],
  },
  grid: {
    borderColor: '#f1f1f1',
  },
  tooltip: {
    x: {
      format: 'dd/MM/yy HH:mm'
    },
  }
}

const getSeries = () => {
  post(url.CHART_DASHBOARD_SERIES) 
  .then(res => {
      if(res) {
          setCategoryData(res[0].xaxis);
          res.map(d => (
            series.push({
            "name" : d.name,
            "data" : d.data
          })))
      }
    //setCategoryData(series_options);
    setSeriesData(series);
  })
  .catch(error => {
    //setErrMessage(error)
  });
}  

const getHSeries = () => {    
  post(url.CHART_DASHBOARD_SERIES + '?h=' + selectedHistory) 
  .then(res => {
      if(res) {
          series_options.xaxis.categories= res[0].xaxis;
          res.map(d => (
            series.push({
            "name" : d.name,
            "data" : d.data
          })))
      }
    setCategoryData(series_options);
    setSeriesData(series);
  })
  .catch(error => {
    //setErrMessage(error)
  });
}

const getCurrentDate = (separator='-') => {

  let newDate = new Date()
  let date_raw = newDate.getDate();
  let month_raw = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  var date, month
    
  if (date_raw<10)  {  date ="0"+date_raw.toString()} else {  date =date_raw.toString()}
  if (month_raw<10)  {  month ="0"+month_raw.toString()} else {  month =month_raw.toString()}
  
  return (
    <span>{month}{separator}{date}{separator}{year}</span>
  );
}

  return (
    <React.Fragment>
      {menuType && menuType.length > 0 && menuType == '2' ? (
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Koloden" breadcrumbItem="Risk Status" />
          {/* <span class="err-fld">{ card == '0' ? 'Add your payment details by clicking on User Account > Payment Method' : '' }</span> */}
          

          <Row>
            {/* <Col xl={8}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4"> Account History </CardTitle>
                  <div className="date-months">
                      <label>Select:</label>
                      <select onChange={ handleHistoryChange }>
                        <option value="1d">1 Day</option>
                        <option value="2d">2 Days</option>
                        <option value="5d">5 Days</option>
                        <option value="15d">15 Days</option>
                        <option value="1m" selected>1 Month</option>
                        <option value="2m">2 Months</option>
                        <option value="3m">3 Months</option>
                        <option value="6m">6 Months</option>
                        <option value="1y">1 Year</option>
                      </select>
                    </div>
                    <div>
                      {series_data && series_data.length > 0 ? (
                          <ReactApexChart options={category_data} series={series_data} type="area" height="350" className="apex-charts"/>
                      ) : null}
                    </div>
                </CardBody>
              </Card>
              <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Critical Errors by Platform {getCurrentDate()}</CardTitle>
                    
                    <div className="date-months">
                      <label>Select Month:</label>
                      <select onChange={handleMonthChange}>
                          {createSelectMonths()}
                      </select>
                    </div>
                   
                    <Bar width={474} height={300} data={bar_data} options={bar_options} />
                  </CardBody>
              </Card>
            </Col> */}
            
            <Col xl={6}>
              {reports_data && reports_data.length > 0 ? (
                <MiniWidget reports={reports_data} />
              ) : null}
            </Col>
            <Col xl="6">
              <Card className="cst-mb">
                <CardBody>
                  <CardTitle>Listing Scan Summary</CardTitle>
                  <div id="doughnut-chart" className="e-chart">
                    <Doughnut />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={8}>
            </Col>
          </Row>
          <RiskFeed></RiskFeed>
        </Container>
      </div>
      ) : menuType == '1' ? (
        <div className="page-content"></div>
      ) : (
        <div className="page-content"></div>
      )}
    </React.Fragment>
  )
}

export default Dashboard