import { get } from "./api_helper";
import * as url from "./url_helper";

function getDefaultPaymentMethod() {
  try {
    const defaultPaymentMethod = localStorage.getItem("default_payment_method");
    const defaultPaymentSource = localStorage.getItem("default_payment_source");
    if (defaultPaymentMethod && defaultPaymentSource) {
      return;
    } else {
      get(url.STRIPE_USER_GET_PAYMENT_METHOD)
        .then((response) => {
          if (response?.defaultPaymentMethod && response?.defaultSource) {
            localStorage.setItem(
              "default_payment_method",
              response.defaultPaymentMethod
            );
            localStorage.setItem(
              "default_payment_source",
              response.defaultSource
            );
          }
        })
        .catch((err) => {
          return;
        });
    }
  } catch (error) {
    return;
  }
}
export { getDefaultPaymentMethod };
