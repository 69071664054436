import PropTypes from 'prop-types'
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { logoutUser } from "../../store/actions"

const Logout = props => {
  localStorage.removeItem("authUser")
  localStorage.removeItem("jwt")
  useEffect(() => {
    props.logoutUser(props.history)

    let td = new Date().toISOString().slice(0, 10);
    var uid = localStorage.getItem('m_id');
    localStorage.removeItem('c_d_s_' + uid + '_' + td);
    localStorage.removeItem('c_d_ce_p_' + uid + '_' + td);

    localStorage.removeItem('c_d_r_t_' + uid + '_' + td);
    localStorage.removeItem('c_d_r_t_AMZ_' + uid + '_' + td);
    localStorage.removeItem('c_d_r_t_EBA_' + uid + '_' + td);
    localStorage.removeItem('c_d_r_t_WAL_' + uid + '_' + td);
    localStorage.removeItem('c_d_r_t_WIS_' + uid + '_' + td);

    localStorage.removeItem('c_d_r_lss_' + uid + '_' + td);
    localStorage.removeItem('c_d_r_lss_AMZ_' + uid + '_' + td);
    localStorage.removeItem('c_d_r_lss_EBA_' + uid + '_' + td);
    localStorage.removeItem('c_d_r_lss_WAL_' + uid + '_' + td);
    localStorage.removeItem('c_d_r_lss_WIS_' + uid + '_' + td);

    localStorage.removeItem("m_type");
    localStorage.removeItem("m_id");

    localStorage.clear();
  })

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
  logoutUser: PropTypes.func
}

export default withRouter(connect(null, { logoutUser })(Logout))
