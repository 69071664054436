import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { MDBDataTable } from "mdbreact"
import { Container, Table, Pagination, PaginationItem, PaginationLink, Form, FormGroup, Label, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Input,
Button} from "reactstrap"
import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"

//Import Components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import "./datatable-cstm.css"

const Referral = props => {

  const [table_data, setTableData] = useState({});

  useEffect(() => {

    setTableData({});

    getTableData();

  }, [])

  const data = {}

  const getTableData = async () => {
    get(url.GET_ADMIN_REFERRAL_INFO) 
    .then(res => {
        if(res) {
          setTableData(res)
        }
    })
    .catch(error => {
      //setErrMessage(error)
    });
  }

  

  return (
      <React.Fragment>
          <div className="showTableFoot">
              <Container fluid>
                  <div className="page-content">
                      <div className="container-fluid">
                      <Breadcrumbs title="Koloden" breadcrumbItem="Users Information / Referral" />

                      <Row>
                          <Col className="col-12 datatb-prod">
                          <Card>
                              <CardBody>
                              <MDBDataTable responsive bordered data={table_data} />
                              </CardBody>
                          </Card>
                          </Col>
                      </Row>
                      </div>
                  </div>

              </Container>
          </div>
      </React.Fragment>
  )
}

export default Referral
