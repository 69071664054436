import React, { SyntheticEvent, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../Site/layout/Header";
import Footer from "../Site/layout/Footer";
import { Redirect } from "react-router";
import $ from "jquery";
import { get, post } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

import hidePswd from "../../assets/icon-pswd/show.png";
import showPswd from "../../assets/icon-pswd/hidden.png";
import { AvForm, AvField } from "availity-reactstrap-validation";

const PasswordResetForm = () => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [strongPswd, setStrongPswd] = useState("");
  const [tokenVerified, setTokenVerified] = useState(false);
  const [verifyTokenError, setVerifyTokenError] = useState('');

  const verifyResetPasswordToken = () => {
    const token = window.location.pathname.split("/").pop();
    post(url.VERIFY_RESET_PASSWORD_TOKEN, {
      token,
    })
      .then((response) => {
        if (response?.message == "success") {
          setTokenVerified(true);
        } 
        if(response.error) {
          setVerifyTokenError(response.error)
        }
      })
      .catch((error) => {
        setVerifyTokenError('Error verifying the link');
      });
  };

  useEffect(() => {
    document.title = "Reset Your Password | Koloden";
    verifyResetPasswordToken();
  }, []);

  const submit = async (e) => {
    e.preventDefault();
    const token = window.location.pathname.split("/").pop();
    const resetURL = `${url.RESET_PASSWORD}`;

    await post(resetURL, {
      password,
      passwordConfirm,
      token,
    })
      .then((response) => {
        setRedirect(true);
      })
      .catch((error) => {
        if (error.response) {
          var errors = error.response.data.errors;
          var response = error.response.data;
          $.each(response.errors, function (key, value) {
            $(".err-" + key).html(value);
            $(".err-" + key).show();
          });
        }
      });
  };

  const showPass = (e) => {
    let target = e.target.parentNode;
    let targetParent = target.parentNode;
    let inputField = targetParent.querySelector("input");
    if (inputField.type === "password") {
      inputField.type = "text";
      e.target.src = hidePswd;
    } else {
      inputField.type = "password";
      e.target.src = showPswd;
    }
    console.log(inputField);
  };

  if (redirect) {
    return <Redirect to="/login" />;
  }

  const showPasswordCheckerMsg = (msg) => {
    $("#password-strength").show();
    $("#password-strength").html(msg);
    // if(msg == 'Strong' || msg == 'Medium') {
    //   $('#password-hint').hide();
    // }
    if (msg == "Very weak - Please enter a stronger password") {
      $("#password-strength").removeClass();
      $("#password-strength").addClass("weak-pswd");
    }
    if (msg == "Weak" || msg == "Weak - Please enter a stronger password") {
      $("#password-strength").removeClass();
      $("#password-strength").addClass("Weak");
    }
    if (msg == "Medium") {
      $("#password-strength").removeClass();
      $("#password-strength").addClass("Medium");
    }
    if (msg == "Strong") {
      $("#password-strength").removeClass();
      $("#password-strength").addClass("Strong");
    } else {
      $("#password-hint").show();
    }
  };

  const strengthChecker = (password) => {
    var strength = 0;
    var msg = "";
    if (password.match(/[a-z]+/)) {
      strength += 1;
    }
    if (password.match(/[A-Z]+/)) {
      strength += 1;
    }
    if (password.match(/[0-9]+/)) {
      strength += 1;
    }
    if (password.match(/[$@#&!]+/)) {
      strength += 1;
    }

    if (password.length < 12) {
      msg = "Very weak - Please enter a stronger password";
      showPasswordCheckerMsg(msg);
      setStrongPswd(false);
      return;
    }

    if (password.length > 25) {
      msg = "maximum number of characters is 25";
      setStrongPswd(false);
      showPasswordCheckerMsg(msg);
      return;
    }

    switch (strength) {
      case 0:
        msg = "Very weak - Please enter a stronger password";
        setStrongPswd(false);
        break;

      case 1:
        msg = "Weak - Please enter a stronger password";
        setStrongPswd(false);
        break;

      case 2:
        msg = "Medium";
        setStrongPswd(false);
        break;

      case 3:
        msg = "Medium";
        setStrongPswd(false);
        break;

      case 4:
        msg = "Strong";
        setStrongPswd(true);
        break;
    }
    showPasswordCheckerMsg(msg);
  };

  return (
    <div className="container_home">
      <div
        className="koloden-main"
        style={{ paddingTop: "0px", background: "initial" }}
      >
        <Header />
      </div>
      <div className="container">
   
        <AvForm
          className="form-signin"
          onValidSubmit={(e, v) => {
            submit(e, v);
          }}
        >
          {/* <img src="/static/media/logo.7fc7a248.png" alt="" height="22" class="logo logo-dark" /> */}

          <h1 className="h3 mb-3 font-weight-normal">Reset your password</h1>
          {tokenVerified ? (
            <>
          <div className="eye-pswd refresh-eye resetInitial">
            <AvField
              name="password"
              label="Password *"
              type="password"
              placeholder="Password"
              onChange={(val, prevVal) => {
                strengthChecker(prevVal);
                setPassword(prevVal);
              }}
              autoComplete="new-password"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Please enter Password",
                },
                minLength: {
                  value: 12,
                  errorMessage:
                    "Password should be at least 12 characters long",
                },
                maxLength: {
                  value: 100,
                  errorMessage:
                    "Password should be less than or equals to 100 characters",
                },
              }}
            />
            <div className="eye-icon mt-4">
              <img
                src={showPswd}
                onClick={(e) => {
                  showPass(e);
                }}
              ></img>
            </div>
            <span
              id="password-strength"
              style={{ display: "none", fontSize: "12px", color: "#333" }}
            >
              Weak - Please enter a stronger password
            </span>
            <span
              id="password-hint"
              style={{ display: "none", color: "#333", fontSize: "11px" }}
            >
              Hint: The password should be at least 12 characters long. To make
              it stronger, use upper and lower case letters, numbers and symbols
              like !"?$%^&).
            </span>
          </div>

          <div className="eye-pswd refresh-eye resetInitial mb-3 mt-3">
            <AvField
              name="password_confirm"
              label="Confirm Password *"
              placeholder="Confirm Password"
              type="password"
              autoComplete="new-password"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Please enter Confirm Password",
                },
                match: {
                  value: "password",
                  errorMessage: "Password and Confirm Password Does Not Match",
                },
              }}
              onChange={(val, prevVal) => setPasswordConfirm(prevVal)}
            />
            <div className="eye-icon mt-4">
              <img
                src={showPswd}
                onClick={(e) => {
                  showPass(e);
                }}
              ></img>
            </div>
          </div>

          {strongPswd ? <>
            
            <button
              className="btn btn-lg btn-primary btn-block" type="submit">
              Reset Password
            </button>

          </> : <>
            <button
              disabled
              className="btn btn-lg btn-primary btn-block" type="submit">
              Reset Password
            </button>
          </>} 

          </>
           ) : (
            <>
            <p>Please wait, while we are verifying the reset password link.</p>
            {verifyTokenError ? <div className="alert alert-danger">{verifyTokenError}</div> : ''}
            </>
            
          )}
        </AvForm>
       
      </div>
      <div
        className="koloden-main"
        style={{ paddingTop: "0px", background: "white", width: "100%" }}
      >
        <div className="section-info dark">
          <div className="container">
            <div className="row">
              <h1 className="title">Have any questions?</h1>
              <p>Check our FAQ or</p>
              <div className="get-started-btn">
                <Link to="/contact-us">
                  <button>CONTACT US</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default PasswordResetForm;
