import React, {useEffect, useState} from 'react'
import Help from './layout/Help'
import Header from './layout/Header'
import Footer from './layout/Footer'
import {Link, useParams} from 'react-router-dom'
// import {Accordion} from 'bootstrap-4-react';

import {post, del, get, put} from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"

import '../../kolodenTheme/css/bootstrap.min.css'
import '../../kolodenTheme/css/fonts.css'
import '../../kolodenTheme/css/all.min.css'
import '../../kolodenTheme/css/style.css'
import '../../kolodenTheme/css/blog.css'

import $ from 'jquery'

const Blog = () => {

    const [blogs, setBlogs] = useState([]);
    let {slug} = useParams();

    document.title = 'Blog | Koloden';

    useEffect(() => {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        xhr.addEventListener("readystatechange", function () {
            if (this.readyState === 4) {
                setBlogs(JSON.parse(this.responseText));
            }
        });

        xhr.open("GET", "https://koloden.com/blog/wp-json/wp/v2/posts?_embed&categories=3&context=view&slug=" + slug);

        xhr.send();
    }, []);

    return (
        <div className="koloden-main">

            <Help/>
            <Header/>

            <div className="blog-container">
                <div className="container">
                    {blogs.map((blog, index) => (

                        <div class="post-detail">
                            <h1 className='mb-4'>{blog.title.rendered}</h1>
                            <div class="featured-image">
                                <img src={blog._embedded["wp:featuredmedia"]["0"].source_url} alt={blog.title.rendered} />
                            </div>

                            <div class="content" dangerouslySetInnerHTML={{__html: blog.content.rendered}}></div>
                        </div>

                    ))}

                </div>
            </div>

            <Footer/>
        </div>
    );
};

export default Blog;
