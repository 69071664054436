import React from 'react';
import $ from 'jquery';
import {Link} from 'react-router-dom';
import Helmet from 'react-helmet';

const Footer = () => {

    const currentRoute= window.location.pathname

    const segments = document.URL;
    const currentLink = segments.substr(segments.lastIndexOf('/') + 1)

    $(function() {
        $('.nav-item').removeClass("mm-active_");
        $('#menu-' + currentLink).addClass("mm-active_");

        $('.nav-item').removeClass("mm-active_");
        $('#menu-' + currentLink).addClass("mm-active_");
    })


    return (
        
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 ml-auto">
                        <div className="link-container">
                            <ul>
                                <li id="menu-terms-of-service" className={"nav-item " + (currentRoute === '/terms-of-service' ? "active" : "")}>
                                    <Link to="/terms-of-service">Terms of Service </Link>
                                </li>
                                <li id="menu-privacy" className={"nav-item " + (currentRoute === '/privacy' ? "active" : "")}>
                                    <Link to="/privacy">Privacy </Link>
                                </li>
                                <li id="menu-contact-us" className={"nav-item " + (currentRoute === '/contact-us' ? "active" : "")}>
                                    <Link to="/contact-us">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-6 right-foot footer-cstm">
                        <div className="mb-5 flex-center" style={{"position": "relative", "top": "18px"}}>
                            <a className="li-ic mr-3">
                              <img className="p-ssl" src="https://www.positivessl.com/images/seals/positivessl_trust_seal_md_167x42.png"></img>
                            </a>
                            
                            {/*
                            <a className="fb-ic" href="https://www.facebook.com/kolodendotcom" target="_blank">
                              <i className="fab fa-facebook-f fa-lg white-text mr-4"> </i>
                            </a>
                            
                            <a className="tw-ic" href="https://twitter.com/kolodendotcom" target="_blank">
                              <i className="fab fa-twitter fa-lg white-text mr-4"> </i>
                            </a>
                            
                            <a className="li-ic" href="https://www.linkedin.com/company/6596143/" target="_blank">
                              <i className="fab fa-linkedin-in fa-lg white-text mr-4"> </i>
                            </a>
                            */}
                            
                            
                  
                          </div>
                    </div>
                </div>
            </div>

            <Helmet>
                <script src="kolodenTheme/js/bootstrap.min.js" type="text/javascript" />
            </Helmet>
        </footer>
        
    );
};

export default Footer;