import React, { useEffect, useState } from "react"
import axios, { AxiosError, AxiosResponse } from 'axios';
import { 
  Container, 
  Row, 
  Col, 
  CardBody, 
  Card, 
  CardTitle,
  Dropdown,
  Button,
  Table,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  Form,
  Label,
  Input,
  FormGroup,
  Spinner, 
  CardHeader} from "reactstrap"
import { Link } from "react-router-dom"
import "../../assets/scss/custom-style.css"


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PaymentMethodsRadioGroup from "../../components/PaymentMethods/MethodsRadioGroup";

// Form Mask
import InputMask from "react-input-mask"
import MaterialInput from "@material-ui/core/Input"

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import $ from 'jquery'
import AmazonPay from "../../components/AmazonPay"


const AddPaymentMethod = props =>  {

  const [successMessage, setSuccessMessage] = useState('')
  const [errMessage, setErrMessage] = useState('')
  const [loading, setLoading] = useState(false);
  const [subscriptionFlag, setSubscriptionFlag] = useState(0);
  const [next_billing_period, setBillingPeriod] = useState('')
  const price = 14.99;
  const [msg, setMsg] = useState('')
  const [card_number, setCardNumber] = useState('')
  const [card_brand, setCardBrand] = useState('')
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState('')

  const [showCard, setShowCard] = React.useState(true);

  useEffect(() => {
    checkSubscribe();
    setMsg('')
    setSuccessMessage('')
    setErrMessage('')
    handleButtonClick()
    setSubscriptionFlag(0);
    setCardNumber('')
    setCardBrand('')
    getUserCardInfo()
    const defaultMethod = localStorage.getItem('default_payment_method');
    setDefaultPaymentMethod(defaultMethod);
    showDefaultPaymentMethod()
  }, [])

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      //https://buy.stripe.com/test_aEUdSA0mEfHT3x63cc
      const userObj = JSON.parse(localStorage.getItem("authUser"));
      const response = await axios.post('https://koloden.com/v2api/public/api/stripe/subscription', {
            first_name: userObj.first_name,
            last_name: userObj.last_name,
            name: userObj.name,
            phone: userObj.phone,
            email: userObj.email,
            price: price,
            userid: userObj.id
      });
      if (response.status === 200) {
        window.location.replace(response.data.url);
      }
      // Handle success
    } catch (error) {
      console.error('Subscription failed:', error);
      // Handle error
    }
    setLoading(false);
  }

  const checkSubscribe = async (e) => {
    setLoading(true);
    try {
      //https://buy.stripe.com/test_aEUdSA0mEfHT3x63cc
      const userObj = JSON.parse(localStorage.getItem("authUser"));
      const response = await axios.get('https://koloden.com/v2api/public/api/stripe/subscription?userid='+userObj.id);
      if (response.status === 200) {
        if(response.data.status == 'paid') {
          setSubscriptionFlag(1);
          var res = response.data.payment;
          console.log(res);

          const end_date = new Date(res.end_date);  // 2009-11-10  
          const end_month = end_date.toLocaleString('en-us', { day: 'numeric', month: 'long', year: 'numeric'});
          setBillingPeriod(end_month);
        }
        else {
          setSubscriptionFlag(2);
        }
      }
      // Handle success
    } catch (error) {
      console.error('Subscription not found:', error);
      // Handle error
    }
    setLoading(false);
  }

  const handleButtonClick = () => {
    var num = $('#card_num').val()
    var exp = $('#card_exp').val()

    const data = { num: num, exp: exp }
    setErrMessage('')
    setSuccessMessage('')
    setMsg('')

    $("#send_invoice_section").hide()
    $("#amazon_pay_section").hide()
    post(url.STRIPE_USER_CARD, data)
      .then(res => {
          if(res) {
            console.log(res)
            setCardBrand(res.brand)
            setCardNumber(res.last4)
            setMsg('')
            setSuccessMessage('Card updated successfully.')
            /*//$("#card_section").show("fast")*/
          }
      })
      .catch(error => {
        console.log(error)
        setMsg('')
        setErrMessage('Your card number is incorrect.')
        $('#card_num').val(num)
        $('#card_exp').val(exp)
        /*//$("#card_section").show("fast")*/
        $("#send_invoice_section").hide()
        $("#amazon_pay_section").hide()
    });
  }

  const getUserCardInfo = () => {
    post(url.STRIPE_USER_INFO)
    .then(res => {
        if(res) {
          setCardBrand(res.brand)
          setCardNumber(res.last4)
        }
    })
    .catch(error => {
      //setErrMessage(error)
    });

    

    document.getElementById("btn-update-card").disabled = true;

    $(document).on('input', '#card_num', function() {
      var cardNum = $('#card_num').val();
      var cardExp = $('#card_exp').val();

      if(cardNum.length >= 22 && cardExp.length >= 12){
        document.getElementById("btn-update-card").disabled = false;
      }else{
        document.getElementById("btn-update-card").disabled = true;
      }
    })

    $(document).on('input', '#card_exp', function() {
      var cardNum = $('#card_num').val();
      var cardExp = $('#card_exp').val();

      if(cardNum.length >= 22 && cardExp.length >= 12){
        document.getElementById("btn-update-card").disabled = false;
      }else{
        document.getElementById("btn-update-card").disabled = true;
      }
    })
   
  }

  // On page load show the selected part of the payment method
  const showDefaultPaymentMethod = () => {
    if(defaultPaymentMethod == 'card') {
      $('#card_section').hide();
      $('#send_invoice_section').hide();
      $('#amazon_pay_section').hide();
    }
    if(defaultPaymentMethod == 'amazon_pay') {
      $('#card_section').hide();
      $('#send_invoice_section').hide();
      $('#amazon_pay_section').show();
    }
    if(defaultPaymentMethod == 'send_invoice') {
      $('#card_section').hide();
      $('#send_invoice_section').show();
      $('#amazon_pay_section').hide();
    }
  }

  const IPV4 = props => (
    <InputMask
      mask="9999  9999  9999  9999"
      value={props.value}
      className="form-control input-color"
      onChange={props.onChange}
    >
      {inputProps => (
        <MaterialInput {...inputProps} type="tel" id="card_num" disableUnderline />
      )}
    </InputMask>
  )

  const Date2 = props => (
    <InputMask
      mask="99/99 - 9999"
      value={props.value}
      className="form-control input-color"
      onChange={props.onChange}
    >
      {inputProps => (
        <MaterialInput {...inputProps} type="tel" id="card_exp" disableUnderline />
      )}
    </InputMask>
  )
  
    return (
      <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div id="grand_Slide" className={loading ? 'grand_Slide show' : 'grand_Slide hidden'}>
            <Spinner id="loader-page" className="m-1 .loader-table" color="primary" />
          </div>
          <Breadcrumbs title="Koloden" breadcrumbItem="Manage Payment Method" />


          <Row>
            <Col lg={12}>
              <Card>
              <CardBody>
                <h5>Set Default Payment Method</h5>
                 <PaymentMethodsRadioGroup />
              </CardBody>
              </Card>
            </Col>
          </Row>
          
          <Row>
            <Col lg={12}>
              <Card>
              <CardBody>
                <h5>Subscription</h5>
                <div className={subscriptionFlag == 2 ? 'show' : 'hidden'}>
                  <div class="row listing-packages mb-2">
                    <div className="col-4">
                      <span>$14.99/month (Limited Offer)</span>
                      <ul>
                        <li>Unlimited storefronts</li>
                        <li>Unlimited products</li>
                        <li>Scanned daily</li>
                        <li>Cancel Any time</li>
                      </ul>
                    </div>
                  </div>
                  <Button id="btn-subscription" onClick={handleSubscribe} type="button" color="primary" className="w-md" style={{padding: "12px 25px"}}>To Subscribe ${price} package</Button>
                </div>
                <div className={subscriptionFlag == 1 ? 'show' : 'hidden'}>
                  <p>You have already subscribed to monthly subscription plan</p>
                  <p>Your plan will be renewed on <b>{next_billing_period}</b></p>
                </div>
              </CardBody>
              </Card>
            </Col>
          </Row>





            <Row id="card_section" style={{display:'none'}}>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <h5>Credit or Debit cards</h5>
                    
                    </CardBody>
                    
                  <CardBody>
                    
                    <h3 class="mb-3" style={{paddingLeft:"20px"}}>Active Card</h3>
                    <h5 class="mb-1" style={{paddingLeft:"40px"}}>Brand : <small class="text-muted fw-normal">{card_brand}</small></h5>
                    <h5 class="mb-1" style={{paddingLeft:"40px"}}>Card Number : <small class="text-muted fw-normal">****{card_number}</small></h5>

                  </CardBody>
                    
                  <CardBody style={{paddingLeft:"40px", marginTop:"30px"}}>
                    <h3 class="mb-3">Add new card</h3>
                    <Row>
                    <Col lg="4">
                        <div className="mt-4 mt-lg-0">
                          <Form action="#">
                            <div className="mb-4">
                              <Label>Credit or debit card</Label>
                              <IPV4 />
                              <span className="font-13 text-muted">
                                0000 0000 0000 0000
                              </span>
                            </div>
                          </Form>
                        </div>
                      </Col>
                      <Col lg="2">
                        <div>
                          <Form action="#">
                            <div className="mb-4">
                              <Label style={{opacity:"0"}}>Date Style 2</Label>
                              <Date2 />
                              <span className="font-13 text-muted">
                                MM/YY - CVC
                              </span>
                            </div>
                          </Form>
                        </div>
                      </Col>

                      <Col lg="2">
                          <Button id="btn-update-card" onClick={handleButtonClick} type="submit" color="primary" className="w-md" style={{marginTop:"29px",padding: "12px 25px"}}>Update</Button>
                      </Col>
                      
                    </Row>
                    <Row>
                    <Col lg="8">
                      <div className="error-family">
                        <p className="defaultText">{msg}</p>
                        <p className="errorText">{errMessage}</p>
                        <p className="successText">{successMessage}</p>
                      </div>
                      </Col>
                      
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          
          <Row id="send_invoice_section" style={{display:'none'}}>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h5>WechatPay/AliPay</h5>
                  By selecting the "WeChat/AliPay (Send Payment Link)" payment option, you will receive a payment link on a monthly basis via email.<br></br> The link will be sent to the email address associated with your account.               
                  </CardBody>
              </Card>
            </Col>
          </Row>
          <Row id="amazon_pay_section" style={{display:'none'}}>
            <Col lg={12}>
              <Card>
                <CardBody>
                 <AmazonPay />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
    )
  }

export default AddPaymentMethod