import React, { useState, useEffect } from "react"
import { AvForm, AvField } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { post, del, get, put } from "../../helpers/api_helper"
import * as url_helper from "../../helpers/url_helper"

import {
  Card,
  CardBody,
  Col,
  Row,
  Alert,
  CardTitle,
  Form,
  Container,
  Label,
  Input,
  FormGroup,
  Button
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import hidePswd from "../../assets/icon-pswd/show.png"
import showPswd from "../../assets/icon-pswd/hidden.png"

// Form Mask
import InputMask from "react-input-mask"


const CancelSubscription = props => { 
    const userObj = JSON.parse(localStorage.getItem("authUser")) 
    const newUserStorage = JSON.parse(localStorage.getItem("updatedUserAcc")); 

    const [successMessage, setSuccessMessage] = useState('');
    const [errMessage, setErrMessage] = useState('');

    const [phoneWithMask, setPhoneWithMask] = useState('');
    const [messageReq, setMessageReq] = useState('');

    const [loader, setLoader] = useState(false);

    useEffect(()=>{
      try {
        let phoneDig = newUserStorage?.phone_number ? newUserStorage?.phone_number : userObj.phone;
        NormalizePhoneNumber(phoneDig)
      }
      catch(err) {
        setPhoneWithMask('');
        console.log("Error in the Cancel Subscription is for => " + err);
      }
    },[0])

    const NormalizePhoneNumber = (phone) => {
      const numericOnly = phone.replace(/\D/g, '');
      let maskedNumber = '';
      if (numericOnly.length > 0) {
      maskedNumber = `(${numericOnly.slice(0, 3)}`;
      }
      if (numericOnly.length > 3) {
      maskedNumber += `) ${numericOnly.slice(3, 6)}`;
      }
      if (numericOnly.length > 6) {
      maskedNumber += `-${numericOnly.slice(6, 10)}`;
      }
      setPhoneWithMask(maskedNumber);

    }

    const handleValidSubmit = (event, values) => {
      let messageField = document.getElementById('message');
      let submitBtn = document.getElementById('submitBtn');
      messageField.setAttribute('disabled','disabled');
      submitBtn.setAttribute('disabled','disabled');

      
      setLoader(true);
      let userJwt = localStorage.getItem('jwt');
      
      setSuccessMessage('')
      setErrMessage('')

      const axios = require('axios');

      let data = JSON.stringify({
          "email": values.email,
          "message": values.message,
          "name": values.first_name + " " + values.last_name,
          "phone": phoneWithMask,
          "subject": values.subject
      }); 
      console.log( "The jwt is =>>>> " + userJwt);

      let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: url_helper.POST_CONTACT_US_USER,
          headers: { 
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + userJwt
          },
          data : data
      };

      axios.request(config)
          .then((response) => {
              console.log(JSON.stringify(response.data));
              window.scrollTo({ top: 0, behavior: 'smooth' });
              setSuccessMessage('Your cancellation request has been submitted successfully.')
              setMessageReq("");
              setLoader(false);

              // setTimeout(()=>{
              //   setSuccessMessage('');
              // },5000)
          })
          .catch((error) => { 
            
              
              messageField.removeAttribute('disabled','disabled');
              submitBtn.removeAttribute('disabled','disabled');
              
              let errorText = '';
              for (var x in error.response.data.errors) {
                errorText += error.response.data.errors[x] + '</br>'
              } 
              setErrMessage(errorText)
              setLoader(false);
          });
                





      // const postProfile = post(url.POST_EDIT_PROFILE, values)
      // .then(response => {
      //   if(response == 0) {
      //     setErrMessage('Your current password is wrong')
      //     return response
      //   }
      //   setSuccessMessage('Your Account Information has been updated successfully.')
      //   return response.data
      // })
      // .catch(error => {
      //   let errorText = '';
      //   for (var x in error.response.data.errors) {
      //     errorText += error.response.data.errors[x] + '</br>'
      //   } 
      //   setErrMessage(errorText)
      // });
    }
    
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Accounts" breadcrumbItem="Cancel Subscription" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={6}>
                        <div className="mt-4">
                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => {handleValidSubmit(e, v)}} >

                          {successMessage  && typeof successMessage === "string"? (
                            <Alert color="success">
                            {successMessage}
                            </Alert>
                          ) : null}

                            {errMessage ? (
                                <div className="alert alert-danger fade show">
                                  <div dangerouslySetInnerHTML={{__html: errMessage}} />
                                </div>
                              ) : null}

                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <AvField disabled name="first_name" label="First name *" type="text"
                                  value={ newUserStorage?.first_name ? newUserStorage?.first_name : userObj.first_name } 
                                  validate={{
                                    required: {value: true, errorMessage: "Please enter First name"},
                                    minLength: {value: 1, errorMessage: 'First name must be greater then 3 characters'},
                                  }}/>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <AvField disabled name="last_name" label="Last name *" type="text" 
                                  value={ newUserStorage?.last_name ? newUserStorage?.last_name : userObj.last_name }  
                                  validate={{
                                    required: {value: true, errorMessage: "Please Enter Last Name"},
                                  }} />
                                </div>
                              </Col>
                            </Row> 
                            
                            {
                              phoneWithMask ? <>
                                <div className="mb-3">
                                  <AvField disabled name="phone" label="Phone" type="text" value={newUserStorage?.phone_number ? newUserStorage?.phone_number : userObj.phone} validate={{
                                        // required: {value: true, errorMessage: "Please Enter Phone Number"},
                                      }}/>
                                </div>
                              </> : <></>
                            }

                            <div className="mb-3">
                              <AvField disabled id="email" name="email" value={userObj.email} label="Email address *" className="form-control" type="email" validate={{
                                    required: {value: true, errorMessage: "Please Enter Email Address"},
                                    pattern: {value: '/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/', errorMessage: 'Invalid Email Address'},
                                  }} />
                            </div>

                            <div className="mb-3">
                              <AvField disabled id="subject" name="subject" value="Cancel Service" label="Subject *" className="form-control" type="text" validate={{
                                  }} />
                            </div>

                            <div className="mb-3">
                              <AvField Row={"5"} id="message" name="message" value={messageReq} label="Message for Support Team *" className="form-control" type="textarea" 
                              validate={{
                                    required: {value: true, errorMessage: "Please enter a message"},
                                  }} />
                            </div>

                            <div className="mt-4">
                              <Button type="submit" color="primary" className="w-md" id="submitBtn">
                                {loader ? "Submitting..." : "Submit"}
                              </Button>
                            </div>
                          </AvForm>
                        </div>
                      </Col>
                      
                    </Row>
                    </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }

export default CancelSubscription