import React, {useEffect, useState} from 'react'
import Help from '../Site/layout/Help'
import Header from '../Site/layout/Header'
import Footer from '../Site/layout/Footer'
import '../../kolodenTheme/css/bootstrap.min.css'
import '../../kolodenTheme/css/fonts.css'
import '../../kolodenTheme/css/all.min.css'
import '../../kolodenTheme/css/style.css'
import '../../kolodenTheme/css/blog.css'


const StoreAuthorizationError = () => {

    return (
        <div className="koloden-main">
            <Help/>
            <Header/>
            <div className="faq-container">
                <div className="container">
                    <div id='action-item' style={{marginBottom: '200px'}}>
                        <h3>Your seller account authorization has failed.</h3>
                         <p>Please try again to add new store and authenticate it with your Amazon seller account credentials.</p>
                        {/* <p>Your payment method has been declined. Please select a different payment method from User Account section.</p> */}
                    </div>
                   <br></br><br></br>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default StoreAuthorizationError;