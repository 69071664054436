import React from 'react'
import Help from './layout/Help'
import Header from './layout/Header'
import Footer from './layout/Footer'
import {Link} from 'react-router-dom'

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"

import '../../kolodenTheme/css/bootstrap.min.css'
import '../../kolodenTheme/css/fonts.css'
import '../../kolodenTheme/css/all.min.css'
import '../../kolodenTheme/css/style.css'
import MetaTags from 'react-meta-tags';
import $ from 'jquery'

const Pricing = () => {

    const showPageData = false;
    
    $(function() {
        var pageID = 2;
        function loadPageData(){
            get(url.CMS_CONTENT + "?page=" + pageID) 
            .then(res => {
                if(res) {
                    res.forEach(element => {
                        var page = pageID;
                        var section = element.section;
                        var column = element.column;
                        if(element.content !== undefined){
                            if(element.type == 'image') {
                                $("[data-set='"+ page + section + column +"']").attr('src', "https://koloden.com/v2api/public/cms/images/" + element.content );
                            } else if(element.type == 'text') {
                                $("[data-set='"+ page + section + column +"']").html(element.content);
                            }
                        }
                    })
                }
            })
            .catch(error => {
                console.log(error)
            });
        }

        loadPageData();
    }) 

    return(
        
        <div className="koloden-main">
            <MetaTags>
                <title>Intellectual Property Management Software Pricing | Koloden</title>
                <meta name="description" content="Looking for intellectual property management as an ebay, Wish, Amazon, or Walmart reseller? Learn more about our pricing structure & avoid platform strikes today!" />
                <meta property="og:title" content="Intellectual Property Management Software Pricing | Koloden" />
            </MetaTags>
            <Help />
            <Header />
            <div className="section-info contrast pricing">
                <div className="container">
                    <div className="row justify-content-center">
                        <h1 className="title"><span data-set="211">{ showPageData ? 'Koloden' : '' }</span><br />
                            <span data-set="212" className="secondary">{ showPageData ? 'Simple, one rate, usage-based pricing' : '' }</span></h1>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-8">
                            <div className="box-detail">
                            <h2 className="product-listing"><span className="secondary-v2" data-set="213">{ showPageData ? '1 – ∞' : '' } </span><span className="secondary f-medium-v2" style={{paddingLeft: "10px"}} data-set="214">{ showPageData ? 'product listings' : '' }</span></h2>
                                <p className='bottom-zero'>
                                    <span data-set="215">{ showPageData ? '$0.05 / listing / day' : '' }</span><br />
                                </p>
                                <h2 className="storefronts"><b data-set="216">{ showPageData ? 'Unlimited storefronts, scanned daily' : '' }</b></h2>
                                <h2 className="storefronts"><b data-set="219">{ showPageData ? 'Unlimited products' : '' }</b></h2>
                                <h2 className="storefronts"><b data-set="2110">{ showPageData ? 'Scanned daily' : '' }</b></h2>
                                <h2 className="storefronts"><b data-set="2111">{ showPageData ? 'Cancel Any time' : '' }</b></h2>
                                {/* <h2 className="storefronts"><b>Unlimited products</b></h2>      
                                <h2 className="storefronts"><b>Scanned daily</b></h2>      
                                <h2 className="storefronts"><b>Cancel Any time</b></h2>       */}
                                <img className="pricing-img" data-set="217" src="{ showPageData ? 'kolodenTheme/img/packing.png' : '' }" alt="" />
                                <div className="get-started-btn">
                                    <Link to="/register">
                                        <button>SIGN UP NOW</button>
                                    </Link>
                                    <span className="f-light" data-set="218">{ showPageData ? 'All amounts shown are in USD.' : '' }</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-info dark">
                <div className="container">
                    <div className="row">
                        <h2 className="title-v2" data-set="221">{ showPageData ? 'Have any questions?' : '' }</h2>
                        <p data-set="222">{ showPageData ? 'Check our FAQ or' : '' }</p>
                        <div className="get-started-btn">
                            <Link to="/contact-us">
                                <button>CONTACT US</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            
            <Footer />
        </div>

    );
};

export default Pricing;