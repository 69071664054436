import PropTypes from 'prop-types'
import React, { useEffect, useRef } from "react"
import { Row, Col, Alert, Container, CardBody ,Card} from "reactstrap"
import Header from '../Site/layout/Header'
import Footer from '../Site/layout/Footer'

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { userForgetPassword } from "../../store/actions"

 

const ForgetPasswordPage = props => {
  const resetBtnRef = useRef(0);

  useEffect(() => {
    document.body.className = "authentication-bg";
    if(props.forgetError) {
      resetBtnRef.current.disabled = false;
    }
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  function handleValidSubmit(event, values) {
    resetBtnRef.current.disabled = true;
    props.userForgetPassword(values, props.history);
  }

  return (
    <React.Fragment>
      <div className="koloden-main" style={{"paddingTop":"0px","background":"initial"}}>
        <Header />
      </div>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="mdi mdi-home-variant h2"></i>
        </Link>
      </div>

      <div className="account-pages my-5  pt-sm-5">
        <Container>
          <div className="row justify-content-center">

            <div className="col-md-8 col-lg-6 col-xl-5">
              <div>

                {/* <a href="/" className="mb-5 d-block auth-logo">
                  <img src={logo} alt="" height="22" className="logo logo-dark" />
                  <img src={logolight} alt="" height="22" className="logo logo-light" />
                </a> */}
                <Card>

                  <CardBody className="p-4">

                    <div className="text-center mt-2">
                      <h5 className="text-primary">Reset Password</h5>
                      
                    </div>
                    <div className="p-2 mt-4">
                      <div className="alert alert-success text-center mb-4" role="alert">
                      Please enter your username or email address. You will receive a link to create a new password via email.
                      </div>
                      {props.forgetError && props.forgetError ? (
                        <Alert color="danger" className="text-center mb-4" style={{ marginTop: "13px" }}>
                          {props.forgetError}
                        </Alert>
                      ) : null}
                      {props.forgetSuccessMsg ? (
                        <Alert color="success" className="text-center mb-4" style={{ marginTop: "13px" }}>
                          {props.forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                      >
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div>
                        <Row className="row mb-0">
                          <Col className="col-12 text-end">
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                              ref={resetBtnRef}
                            >
                              Reset
                          </button>
                          </Col>
                        </Row>
                        <div className="mt-4 text-center">
                          <p className="mb-0">Remember It ? <Link to="/login" className="fw-medium text-primary"> Sign In </Link></p>
                        </div>
                      </AvForm>
                    </div>

                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="koloden-main" style={{"paddingTop":"0px","background":"white","width":"100%"}}>
      <div className="section-info dark">
                <div className="container">
                    <div className="row">
                        <h1 className="title">Have any questions?</h1>
                        <p>Check our FAQ or</p>
                        <div className="get-started-btn">
                            <Link to="/contact-us">
                                <button>CONTACT US</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
          <Footer />
        </div>
    </React.Fragment>
    /*
    <p className="text-muted">Reset Password with Minible.</p>
    <p>
      © {new Date().getFullYear()} Minible. Crafted with{" "}
      <i className="mdi mdi-heart text-danger" /> by Themesbrand
    </p>
    */
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
)
