import PropTypes from 'prop-types'
import React, { useEffect } from "react"
import Header from '../Site/layout/Header'
import Footer from '../Site/layout/Footer'

import { Row, Col, Alert, Container ,CardBody,Card} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"

// import images
import logo from "../../assets/images/logo.png"
import logolight from "../../assets/images/logo.png"
import hidePswd from "../../assets/icon-pswd/show.png"
import showPswd from "../../assets/icon-pswd/hidden.png"

// import "../../kolodenTheme/css/bootstrap.min.css"
// import "../../kolodenTheme/css/fonts.css"
// import "../../kolodenTheme/css/all.min.css"
// import "../../kolodenTheme/css/style.css"

import '../../../src/assets/scss/custom-style.css'

//Import config
import { facebook, google } from "../../config"

const Login = (props) => {
   // handleValidSubmit
    const handleValidSubmit = (event, values) => {

      let td = new Date().toISOString().slice(0, 10);
      var uid = localStorage.getItem('m_id');
      console.log('clear: ' + td);
      localStorage.removeItem('c_d_s_' + uid + '_' + td);
      localStorage.removeItem('c_d_ce_p_' + uid + '_' + td);

      localStorage.removeItem('c_d_r_t_' + uid + '_' + td);
      localStorage.removeItem('c_d_r_t_AMZ_' + uid + '_' + td);
      localStorage.removeItem('c_d_r_t_EBA_' + uid + '_' + td);
      localStorage.removeItem('c_d_r_t_WAL_' + uid + '_' + td);
      localStorage.removeItem('c_d_r_t_WIS_' + uid + '_' + td);

      localStorage.removeItem('c_d_r_lss_' + uid + '_' + td);
      localStorage.removeItem('c_d_r_lss_AMZ_' + uid + '_' + td);
      localStorage.removeItem('c_d_r_lss_EBA_' + uid + '_' + td);
      localStorage.removeItem('c_d_r_lss_WAL_' + uid + '_' + td);
      localStorage.removeItem('c_d_r_lss_WIS_' + uid + '_' + td);

      props.loginUser(values, props.history)
  }

  const signIn = (res, type) => {
    const { socialLogin } = props
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      socialLogin(postData, props.history, type)
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      socialLogin(postData, props.history, type)
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook")
  }

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  const showPass = (e) => {
    
    let target = e.target.parentNode;
    let targetParent = target.parentNode;
    let inputField = targetParent.querySelector("input");
    if (inputField.type === "password") {
      inputField.type = "text";
      e.target.src=hidePswd;
    } else {
      inputField.type = "password";
      e.target.src=showPswd;
    }
    console.log(inputField);
  }

  return (
    <React.Fragment>
      <div className="koloden-main" style={{"paddingTop":"0px","background":"initial"}}>
        <Header />
      </div>

      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="mdi mdi-home-variant h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>

        
          {/* <Row>
            <Col lg={12}>
              <div className="text-center">
                <Link to="/" className="mb-5 d-block auth-logo">
                  <img src={logo} alt="" height="22" className="logo logo-dark" />
                  <img src={logolight} alt="" height="22" className="logo logo-light" />
                </Link>
              </div>
            </Col>
          </Row> */}
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>

                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Welcome Back</h5>
                    <p className="text-muted">Sign in to continue to Koloden.</p>
                  </div>
                  <div className="p-2 mt-4">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      {props.error && props.error.request && props.error.request.status && props.error.request.status === 401 ? (
                        <Alert color="danger">Invalid Credentials</Alert>
                      ) : props.error && props.error.request && props.error.request.status && props.error.request.status === 499 ? (
                        <Alert color="danger">Please contact us at info@koloden.com for your access. Thank you.</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          type="email"
                          validate={{
                            required: {value: true, errorMessage: "Please enter valid Email Address"},
                            pattern: {value: '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$', errorMessage: 'Please enter valid Email Address'},
                          }}
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <div className="mb-3 eye-pswd">
                          <div className="float-end">
                            <Link to="/forgot-password" className="">Forgot your password?</Link>
                          </div>
                          <AvField
                            name="password"
                            label="Password"
                            type="password"
                            validate={{
                              required: {value: true, errorMessage: "Please enter valid Password"},
                            }}
                            required
                          />
                          <div className="eye-icon">
                            <img src={showPswd} onClick={(e)=>{ showPass(e) }}></img>
                          </div>
                        </div>
                        
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit">
                          Sign In
                        </button>
                      </div>

                     

                      <div className="mt-4 text-center">
                        <p className="mb-0">Don't have an account ? 
                          <a href="/register" className="fw-medium text-primary"> Register now</a>
                        </p>
                      </div>

                    </AvForm>

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
      
      <div className="koloden-main" style={{"paddingTop":"0px","background":"white","width":"100%"}}>
      <div className="section-info dark">
                <div className="container">
                    <div className="row">
                        <h1 className="title">Have any questions?</h1>
                        <p>Check our FAQ or</p>
                        <div className="get-started-btn">
                            <Link to="/contact-us">
                                <button>CONTACT US</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
          <Footer />
        </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}