import React, {useEffect, useState} from 'react'
import Help from './layout/Help'
import Header from './layout/Header'
import Footer from './layout/Footer'
// import {Helmet} from "react-helmet";
import MetaTags from 'react-meta-tags';
import {Link, useParams} from 'react-router-dom'
// import {Accordion} from 'bootstrap-4-react';

import {post, del, get, put} from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"

import '../../kolodenTheme/css/bootstrap.min.css'
import '../../kolodenTheme/css/fonts.css'
import '../../kolodenTheme/css/all.min.css'
import '../../kolodenTheme/css/style.css'
import '../../kolodenTheme/css/blog.css'

import $ from 'jquery'

const Page = () => {

    const [blogs, setBlogs] = useState([]);

    let {page} = useParams();

    useEffect(() => {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        xhr.addEventListener("readystatechange", function () {
            if (this.readyState === 4) {
                setBlogs(JSON.parse(this.responseText));
            }
        });

        xhr.open("GET", "https://koloden.com/blog/wp-json/wp/v2/pages?_embed&context=view&slug=" + page);

        xhr.send();

    }, [page]);

    return (
        <div className="koloden-main">

            <Help/>
            <Header/>

            <div className="blog-container">
                <div className="container">
                    {blogs.map((blog, index) => (
                        <div className="post-detail" key={""}>
                            <MetaTags>
                                <title>{ blog.yoast_head_json.title }</title>
                                <meta
                                    name="description"
                                    content={ blog.yoast_head_json.description }
                                />
                            </MetaTags>
                            <h1 className='mb-4' dangerouslySetInnerHTML={{__html: blog.title.rendered}}></h1>
                            {blog._embedded["wp:featuredmedia"]
                                ? <div class="featured-image"><img src={blog._embedded["wp:featuredmedia"]["0"].source_url} alt={blog.title.rendered} /></div>
                                : ''
                            }


                            <div className="content" dangerouslySetInnerHTML={{__html: blog.content.rendered}}></div>
                        </div>

                    ))}

                </div>
            </div>

            <Footer/>
        </div>
    );
};

export default Page;
