export function openModal(){
    let x = document.querySelector('.modal-popup');
    x.style.display="block";
}
export function closeModal(){
    let x = document.querySelector('.modal-popup');
    x.style.display="none";
}
export function changeRoutForModal(){
    window.location.href = '/payment-method';
}

export function changeRoutForModalDashboard(){
    window.location.href = '/dashboard';
}