import React, { useEffect, useState } from "react"
import { Container, Row, Col, CardBody, Card, CardTitle,
  Dropdown,
  Button,
  Table,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown } from "reactstrap"
import { Link } from "react-router-dom"


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Components
import { MDBDataTable } from "mdbreact"
import "./datatables.scss"
import "./datatable-cstm.css"

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import $ from 'jquery'


const Billing = props =>  {

  /***** Billing - Start *****/
  const [successMessage, setSuccessMessage] = useState('')
  const [errMessage, setErrMessage] = useState('')
  const [billing_total, setBillingTotal] = useState('')
  const [billing_discount, setBillingDiscount] = useState('')
  const [billing_net_total, setBillingNetTotal] = useState('')

  const [billing_data, setBillingData] = useState([])
  const [billingDataPages, setBillingDataPages] = useState(0);
  const [billingDataRecords, setBillingDataRecords] = useState(0);
  const [billingDataPerPage, setBillingDataPerPage] = useState(12);

  const [billing_month, setBillingMonth] = useState('')
  const [billing_current_period, setBillingPeriod] = useState('')

  const [selected_year_months, setSelectedYearMonths] = useState([])

  const [selected_month, setMonth] = useState([])
  const [selected_year, setYear] = useState([])
  /***** Billing - End *****/

  useEffect(() => {
    var d = new Date();
    var y = d.getFullYear();
    var m = ("0" + (d.getMonth() + 1)).slice(-2)
    setMonth(m)
    setYear(y)

    setSuccessMessage('')
    setErrMessage('')

    handleChangeOnMonth()
    handleChangeOnYear()

    setSelectedYearMonths([]);

    /***** Billing - Start *****/
    //getUserBillingYearMonths();
    //setBillingData([])
    //getUserBilling()

    getUserBillingData()

    setBillingTotal('')
    setBillingDiscount('')
    setBillingNetTotal('')

    //handleMonthYearClick()
    /***** Billing - End *****/
  }, [])

  const data_Stores = {}


  const getUserBillingYearMonths = () => {
    post(url.BILLING_USER_DATA_YearMonths)
    .then(res => {
        if(res) {
          setSelectedYearMonths(res);

          var html = '';
          var i = 1;
          for (const val in res.year) {
            if(i == 1){
              setYear(res.year[val].year)
            }
            html += '<option value="'+res.year[val].year+'">'+res.year[val].year+'</option>'
            i++;
          }
          $('#selectedYear').html(html)

          html = '';
          i = 1;
          for (const val in res.month) {
            if(res.year[0].year == res.month[val].year){
              if(i == 1){
                setMonth(res.month[val].month)
                html += '<option selected value="'+res.month[val].month+'">'+res.month[val].full_month+'</option>'
              }else{
                html += '<option value="'+res.month[val].month+'">'+res.month[val].full_month+'</option>'
              }
              i++;
            }
            $('#selectedMonth').html(html)
          }
        }
    })
    .catch(error => {
      setErrMessage(error)
    });
  }
  



  /***** Get User Billing Data - Start *****/
  const getUserBillingData = () => {
    post(url.BILLING_USER_DATA_ALL + '?limit=' + billingDataPerPage)
    .then(res => {
        if(res) {
          if(res != "No data found") {
            setBillingData(res.data);
            setBillingDataPages(res.pages);
            setBillingDataRecords(res.records);
          }
        }
    })
    .catch(error => {
      setErrMessage(error)
    });
  }

  const handlePaginationBillingData = (pageNumber) => {
    post(url.BILLING_USER_DATA_ALL + '?page=' + pageNumber + '&limit=' + billingDataPerPage)
    .then(res => {
        if(res) {
          if(res != "No data found") {
            setBillingData(res.data);
            setBillingDataPages(res.pages);
            setBillingDataRecords(res.records);
          }
        }
    })
    .catch(error => {
      setErrMessage(error)
    });
  }
  /***** Get User Billing Data - End *****/




  /***** Billing - Start *****/
  const getUserBilling = () => {
    post(url.BILLING_USER_DATA + '?')
    .then(res => {
        if(res) {
          if(res != "No data found") {
            //setBillingData(res);
            const start_date = new Date(res.start_date);  // 2009-11-10
            const end_date = new Date(res.end_date);  // 2009-11-10
            
            const month = start_date.toLocaleString('en-us', { month: 'long', year: 'numeric' });
            const start_month = start_date.toLocaleString('en-us', { day: 'numeric', month: 'long' });
            const end_month = end_date.toLocaleString('en-us', { day: 'numeric', month: 'long' });
            
            setBillingMonth(month)
            setBillingPeriod(start_month + ' - ' + end_month);
            setBillingTotal(res.total)
            //setBillingDiscount(res.discount)
            setBillingNetTotal(res.total)
          }
        }
    })
    .catch(error => {
      setErrMessage(error)
    });
  }
  /***** Billing - Start *****/

  const handleMonthYearClick = () => {
    var d = new Date();
    var y = d.getFullYear();
    var m = ("0" + (d.getMonth() + 1)).slice(-2)

    var mL = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    const months = mL;

    var yearMonth = '';
    var sm = m;
    var sy = y;
    if(selected_year === undefined) { yearMonth = y; sy = y; } else { yearMonth = selected_year; sy = selected_year; }
    if(selected_month === undefined) { yearMonth += '-' + m; sm = m; } else { yearMonth += '-' + selected_month; sm = selected_month; }
    
    post(url.BILLING_USER_DATA + '?ym=' + yearMonth)
    .then(res => {
        if(res) {
          if(res != "No data found") {
            const start_date = new Date(res.start_date);  // 2009-11-10
            const end_date = new Date(res.end_date);  // 2009-11-10
            
            const month = start_date.toLocaleString('en-us', { month: 'long', year: 'numeric' });
            const start_month = start_date.toLocaleString('en-us', { day: 'numeric', month: 'long' });
            const end_month = end_date.toLocaleString('en-us', { day: 'numeric', month: 'long' });
            
            setBillingMonth(month)
            setBillingPeriod(start_month + ' - ' + end_month);
            setBillingTotal(res.total)
            setBillingNetTotal(res.total)
          }
        }
    })
    .catch(error => {
      setErrMessage(error)
    });
  }

  async function handleChangeOnMonth(val) {
    setMonth(val)
    var m = $("#selectedMonth option:selected").text();
    var y = $("#selectedYear option:selected").text();
    $('#txt-month-year').html(' - ' + m + ' ' + y);
  }

  async function handleChangeOnYear(yearVal) {
    setYear(yearVal)

    var html = '';
    var i = 1;
    var res = selected_year_months;
    for (const val in res.month) {
      if(yearVal == res.month[val].year){
        if(i == 1){
          setMonth(res.month[val].month)
          html += '<option selected value="'+res.month[val].month+'">'+res.month[val].full_month+'</option>'
        }else{
          html += '<option value="'+res.month[val].month+'">'+res.month[val].full_month+'</option>'
        }
        i++;
      }
      $('#selectedMonth').html(html)
    }


    var m = $("#selectedMonth option:selected").text();
    var y = $("#selectedYear option:selected").text();
    $('#txt-month-year').html(' - ' + m + ' ' + y);
  }

  $(function() {
    var d = new Date();
    var y = d.getFullYear();
    var m = ("0" + (d.getMonth() + 1)).slice(-2)
    $('#selectedMonth [value=' + m + ']').attr('selected', 'true')
    $('#selectedYear [value=' + y + ']').attr('selected', 'true');
    
    var m = $("#selectedMonth option:selected").text();
    var y = $("#selectedYear option:selected").text();
    $('#txt-month-year').html(' - ' + m + ' ' + y);
  })

  /* <span class="bg-success badge badge-secondary">Bill Paid</span> */

  const [singlebtn1, setSinglebtn1] = useState(false)
  const [singlebtn2, setSinglebtn2] = useState(false)
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Koloden" breadcrumbItem="Billing" />


          <Row>
            <Col className="col-12 billing-option">
              <Card>
                {/*<CardBody>
                  <CardTitle>Billing <span id="txt-month-year"></span></CardTitle>
                  <Row style={{margin: '30px 0', marginTop: "10px"}}>
                    <Col className="col-8">
                      
                      <div className="date-months">
                          <select id="selectedYear" onChange={ (e) => handleChangeOnYear(e.target.value) }>
                            <option value="00">Not Available</option>
                          </select>
                      </div>

                      <div className="date-months">
                        <select id="selectedMonth" onChange={ (e) => handleChangeOnMonth(e.target.value) }>
                        <option value="00">Not Available</option>
                        </select>
                      </div>
                      
                      <button class="btn btn-primary waves-effect waves-light btn btn-primary" onClick={() => handleMonthYearClick() }>Submit</button>
                    </Col>
                  </Row>
                  <Row>
                  <Col lg={12}>
                    <Card>
                    <CardBody>
                      <div className="table-responsive">
                          <Table className="table mb-0">
                            <thead className="table-light">
                              <tr>
                                <th>Total</th>
                                <th>$ {billing_total}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">Billing Month</th>
                                <td>{billing_month}</td>
                              </tr>
                              <tr>
                                <th scope="row">Billing Period</th>
                                <td>{billing_current_period}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  </Row>
                </CardBody>*/}
                <CardBody className="custom-tabGroup">
                  <CardTitle>Find all of your Billing Details here</CardTitle>
                  <Row>
                    <Col lg={12}>
                      <Card>
                      <CardBody>
                        <div className="table-responsive">
                            <Table className="table mb-0 cstm-table">
                              <thead className="table-light">
                                <tr>
                                  <th scope="row">S.No.</th>
                                  <th scope="row">Invoice Date</th>
                                  <th scope="row" className="text-center">Customer Name</th>
                                  <th scope="row" className="text-center">Email</th>
                                  <th scope="row" className="text-center">Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {billing_data.map((bill, index) => (
                                <tr>
                                  <td>{bill.serial_number}</td>
                                  <td>{bill.invoice_date}</td>
                                  <td className="text-center">{bill.name}</td>
                                  <td className="text-center email">{bill.email}</td>
                                  <td className="text-center">{bill.amount}</td>
                                  {/* <td className="text-center"><button>Export</button></td> */}
                                </tr>
                                ))}
                              </tbody>
                            </Table>
                            { billing_data.length == 0 ? 
                            <p className="message-status">
                              No Billing Data currently available on your account.
                            </p> :
                            <nav aria-label="Page navigation example">
                              <ul class="pagination">
                                <li class="page-item">
                                  <a class="page-link" href="#" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                    <span class="sr-only">Previous</span>
                                  </a>
                                </li>
                                {[...Array(billingDataPages)].map((x, i) =>
                                  <li class="page-item"><a class="page-link" href="#" onClick={(e) => {handlePaginationBillingData(i+1)} }>{(i+1)}</a></li>
                                )}
                                <li class="page-item">
                                  <a class="page-link" href="#" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                    <span class="sr-only">Next</span>
                                  </a>
                                </li>
                              </ul>
                            </nav>
                            }
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Billing