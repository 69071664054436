import axios from "axios"
//import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
//const token = accessToken

//apply base url for axios
// const API_URL = "http://127.0.0.1:8000/api/"
//const API_URL = "https://koloden.com/api"
//const API_URL = "https://kolodenbackend.plego.pro/api"
const API_URL = "https://koloden.com/v2api/public/api" // This is the current live API
// const API_URL = "https://koloden.plego.cloud/v2api/public/api" // This is the plego dev API
//const API_URL = "http://koloden.local/api" // This is the local API 

const axiosApi = axios.create({
  baseURL: API_URL,
  withCredentials: true,
})

axiosApi.interceptors.request.use(function (config) {
  const token = localStorage.getItem('jwt');
  config.headers.Authorization =  token ? 'Bearer ' + token : '';
  return config;
});


axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
