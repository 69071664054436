import PropTypes from "prop-types"
import React, { useEffect, useState  } from "react"
import { Row, Col, CardBody, Card, Alert, Container, Input } from "reactstrap"
import Header from '../Site/layout/Header'
import Footer from '../Site/layout/Footer'

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

// Redux
import { connect } from "react-redux"
import { Link, useHistory } from "react-router-dom"

// import images
import logo from "../../assets/images/logo.png"
import logolight from "../../assets/images/logo.png"
import hidePswd from "../../assets/icon-pswd/show.png"
import showPswd from "../../assets/icon-pswd/hidden.png"
import MetaTags from 'react-meta-tags';
// Form Mask
import InputMask from "react-input-mask"
import $ from 'jquery';

const Register = props => {
  let history = useHistory();
  
  const [strongPswd, setStrongPswd] = useState(false);

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
      props.registerUser(values)
  }
  const [agree, setAgree] = useState(true);

  const checkboxHandler = () => {
    setAgree(!agree);
  }


  useEffect(() => {
    props.apiError("")
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  const showPasswordCheckerMsg = (msg) => {
    $('#password-strength').show();
    $('#password-strength').html(msg);
    // if(msg == 'Strong' || msg == 'Medium') { 
    //   $('#password-hint').hide(); 
    // } 
    if(msg == 'Very weak - Please enter a stronger password'){
      $('#password-strength').removeClass();
      $('#password-strength').addClass('weak-pswd');
    }
    if(msg == 'Weak' || msg == 'Weak - Please enter a stronger password'){
      $('#password-strength').removeClass();
      $('#password-strength').addClass('Weak');
    }
    if(msg == 'Medium'){
      $('#password-strength').removeClass();
      $('#password-strength').addClass('Medium');
    }
    if(msg == 'Strong'){
      $('#password-strength').removeClass();
      $('#password-strength').addClass('Strong');
    }
    
    else { $('#password-hint').show(); }
  }

  const strengthChecker = (password) => {
    var strength = 0;
    var msg = '';
    if (password.match(/[a-z]+/)) {
      strength += 1;
    }
    if (password.match(/[A-Z]+/)) {
      strength += 1;
    }
    if (password.match(/[0-9]+/)) {
      strength += 1;
    }
    if (password.match(/[$@#&!]+/)) {
      strength += 1;

    }

    if (password.length < 12) {
      msg = 'Very weak - Please enter a stronger password';
      showPasswordCheckerMsg(msg);
      setStrongPswd(false);
      return;
    }

    if (password.length > 25) {
      msg = "maximum number of characters is 25";
      setStrongPswd(false);
      showPasswordCheckerMsg(msg);
      return;
    }

    switch (strength) {
      case 0:
        msg = 'Very weak - Please enter a stronger password';
        setStrongPswd(false);
        break;

      case 1:
        msg = 'Weak - Please enter a stronger password';
        setStrongPswd(false);
        break;

      case 2:
        msg = 'Medium';
        setStrongPswd(false);
        break;

      case 3:
        msg = 'Medium';
        setStrongPswd(false);
        break;

      case 4:
        msg = 'Strong';
        setStrongPswd(true);
        break;
    }
    showPasswordCheckerMsg(msg);
  }

  if(props && props.user){
    history.push("/login");
    /*
    return (
      <React.Fragment>
            <div className="home-btn d-none d-sm-block">
              <Link to="/" className="text-dark">
                <i className="mdi mdi-home-variant h2"></i>
              </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="text-center">
                      <Link to="/" className="mb-5 d-block auth-logo">
                        <img src={logo} alt="" height="22" className="logo logo-dark" />
                        <img src={logolight} alt="" height="22" className="logo logo-light" />
                      </Link>
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center justify-content-center">
                  <Col md={8} lg={6} xl={5}>
                    <Card>
                      <CardBody className="p-4">
                        <div className="p-2 mt-4">
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                              handleValidSubmit(e, v)
                            }} 
                          >
                              <Alert color="success">
                                User Registered Successfully
                              </Alert>
  
                            <div className="mt-4 text-center">
                              <p className="text-muted mb-0">Click here to ? <Link to="/login" className="fw-medium text-primary"> Sign In</Link></p>
                            </div>
                          </AvForm>

                        </div>
                        </CardBody>
                    </Card>
                  </Col>
                </Row>
                </Container>
            </div>
          </React.Fragment>
    ) */
  }

  const showPass = (e) => {
    
    let target = e.target.parentNode;
    let targetParent = target.parentNode;
    let inputField = targetParent.querySelector("input");
    if (inputField.type === "password") {
      inputField.type = "text";
      e.target.src=hidePswd;
    } else {
      inputField.type = "password";
      e.target.src=showPswd;
    }
    console.log(inputField);
  }

  return (
    <React.Fragment>
            <MetaTags>
                <title>Sign Up For Intellectual Property Management For Resellers | Koloden</title>
                <meta name="description" content="Sign Up For Intellectual Property Management For Resellers | Koloden" />
                <meta property="og:title" content="Sign Up For Intellectual Property Management For Resellers | Koloden" />
            </MetaTags>
      <div className="koloden-main" style={{"paddingTop":"0px","background":"initial"}}>
        <Header />
      </div>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="mdi mdi-home-variant h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Register Account</h5>
                    <p className="text-muted">Create your account now!</p>
                  </div>
                  <div className="p-2 mt-4">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }} 
                    >
                      {props && props.user && props.user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}
                    
                    {props && props.registrationError && props.registrationError.response && props.registrationError.response.status &&
                         props.registrationError.response.status === 422 ? (
                          <Alert color="danger">
                            {props.registrationError.response.data.errors['email']}
                          </Alert>
                        ) : null}


                      {props && props.registrationError &&
                         typeof props.registrationError === "string" ? (
                          <Alert color="danger">
                            {props.registrationError}
                          </Alert>
                        ) : null}

                      <div className="mb-3">
                        <AvField
                          name="first_name"
                          label="First name *"
                          type="text"
                          validate={{
                            required: {value: true, errorMessage: "Please enter First name"},
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="last_name"
                          label="Last name *"
                          type="text"
                          validate={{
                            required: {value: true, errorMessage: "Please enter Last name"},
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          /* mask="(999) 999-9999" */
                          /* tag={[Input, InputMask]} */
                          name="phone"
                          label="Phone"
                          type="text"
                          // validate={{
                          //   required: {value: true, errorMessage: "Please enter Phone number"},
                          // }}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          id="email"
                          name="email"
                          label="Email address *"
                          className="form-control"
                          type="email"
                          autoComplete="off"
                          validate={{
                            required: {value: true, errorMessage: "Please enter Email Address"},
                                    pattern: {value: '/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/', errorMessage: 'Invalid Email Address'},
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="promo_code"
                          label="Referral code"
                          type="text"
                          validate={{
                            required: {value: false, errorMessage: "Please enter Referral Code"},
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="promo_code2"
                          label="Promo code"
                          type="text"
                          validate={{
                            required: {value: false, errorMessage: "Please enter Promo Code"},
                          }}
                        />
                      </div>
                      <div className="mb-3 eye-pswd">
                        <AvField
                          name="password"
                          label="Password *"
                          type="password"
                          onChange={(val, prevVal) => strengthChecker(prevVal) }
                          autoComplete="new-password"
                          validate={{
                            required: {value: true, errorMessage: "Please enter Password"},
                          }}
                        />
                        <div className="eye-icon">
                          <img src={showPswd} onClick={(e)=>{ showPass(e) }}></img>
                        </div>
                        <span id="password-strength" style={{ display:'none' }}>Weak - Please enter a stronger password</span>
                        <span id="password-strength-check">
                          Password requirements:
                          <ul>
                            <li>Minimum of twelve characters</li>
                            <li>At least 1 upper case and 1 lower case letter</li>
                            <li>At least 1 number</li>
                            <li>At least 1 special character ( <b>[ $ @ # & ! ] +</b> )</li>
                            <li>Must be “Strong”</li>
                          </ul>
                        </span>
                        {/* <span id="password-hint" style={{ display:'block' }}>Hint: The password should be at least twelve characters long.
                        To make it stronger,use upper and lower case letters,
                        numbers, and symbols like !"?$%^&).
                        </span> */}
                      </div>
                      <div className="mb-3 eye-pswd">
                        <AvField
                          name="password_confirm"
                          label="Confirm Password *"
                          type="password"
                          autoComplete="new-password"
                          validate={{
                            required: {value: true, errorMessage: "Please enter Confirm Password"},
                            match:{value:'password', errorMessage: "Password and Confirm Password Does Not Match"}
                          }}
                        />
                        <div className="eye-icon">
                          <img src={showPswd} onClick={(e)=>{ showPass(e) }}></img>
                        </div>
                      </div>
                      <div className="form-check">
                        <input type="checkbox" defaultChecked={!agree} onChange={checkboxHandler} className="form-check-input" id="auth-terms-condition-check" required="required"/>
                        <label className="form-check-label" htmlFor="auth-terms-condition-check">I read, understand, and accept the <Link to="/terms-of-service" style={{"fontWeight":"bold"}} className="text-dark imp_">Terms and Conditions</Link></label>
                        
                      </div>

                      
                      { strongPswd == true ? <>
                        <div className="mt-3 text-end txt-greyed">
                          <button disabled={agree}
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                          >
                            Register
                          </button>
                        </div>
                      </> : <><div className="mt-3 text-end txt-greyed">
                          <button disabled={true}
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                          >
                            Register
                          </button>
                        </div></> }
                      
                      
                      <div className="mt-4 text-center">
                        <p className="text-muted mb-0">Already have an account ? 
                          {/* <Link to="/login" className="fw-medium text-primary"> Sign In</Link> */}
                          <a href="/login" className="fw-medium text-primary"> Sign In</a>
                        </p>
                      </div>

                    </AvForm>

                  </div>
                  </CardBody>
              </Card>
            </Col>
          </Row>
          </Container>
      </div>
      <div className="koloden-main" style={{"paddingTop":"0px","background":"white","width":"100%"}}>
      <div className="section-info dark">
                <div className="container">
                    <div className="row">
                        <h1 className="title">Have any questions?</h1>
                        <p>Check our FAQ or</p>
                        <div className="get-started-btn">
                            <Link to="/contact-us">
                                <button>CONTACT US</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
          <Footer />
        </div>
    </React.Fragment>
  )
}

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register)