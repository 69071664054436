import React, { useEffect, useState } from "react"
import { MDBDataTable } from "mdbreact"
import { Container, Table, Pagination, PaginationItem, PaginationLink, Form, FormGroup, Label, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Input,
Button} from "reactstrap"
import { Link } from "react-router-dom"
import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"

//Import Components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import "./datatable-cstm.css"
import $ from 'jquery'

const UserManage = props =>  {

  const [table_data, setTableData] = useState({});

  useEffect(() => {

    setTableData({});

    getTableData();

  }, [])

  const data = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 270,
      },
      {
        label: "Registered",
        field: "registered",
        sort: "asc",
        width: 100,
      },
      {
        label: "No. of Stores",
        field: "no_of_stores",
        sort: "asc",
        width: 50,
      },
      {
        label: "No. of Products",
        field: "no_of_products",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lifetime Payments",
        field: "payments",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 50,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [
      /*
      {
        Name: "Tiger Nixon",
        Email: "System Architect",
        Registered: "Edinburgh",
        Stores: "",
        Products: "",
        Lifetime: "",
        Status: "",
        Actions: [
            <button className="btn btn-primary btn-sm waves-effect waves-light btn btn-danger">Critical Matches</button>,
            <button className="btn btn-primary btn-sm waves-effect waves-light btn btn-primary">Word Mark Matches</button>,
            <button className="btn btn-primary btn-sm waves-effect waves-light btn btn-success">Image Match</button>,
            <br />,
            <button className="btn btn-primary btn-sm waves-effect waves-light btn btn-success">Primary Logo Lookup</button>,
            <button className="btn btn-primary btn-sm waves-effect waves-light btn btn-success">Secondary Logo Lookup</button>
        ],
      },*/
    ],
  }

  const resetPassword = (email) => {
    let password = prompt('password for ' + email);
    if (window.confirm('Change password?')) {
      post(url.ACTION_ADMIN_USER_RESET + '?email=' + email + '&password=' + password).then(res => {
        if(res) {
          console.log(res)
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }

  const changeStatus = (email) => {
    if (window.confirm('Change user status?')) {
      post(url.ACTION_ADMIN_USER_STATUS + '?email=' + email).then(res => {
        if(res) {
          window.location.reload();
        }
      })
      .catch(error => {
        console.log(error);
      });
    } else {
      console.log('nothing');
    }
  }

  const deleteUser = (email) => {
    if (window.confirm('Delete ' + email)) {
      post(url.ACTION_ADMIN_USER_DELETE + '?email=' + email).then(res => {
        if(res) {
          window.location.reload();
        }
      })
      .catch(error => {
        console.log(error);
      });
    } else {
      console.log('not');
    }
  }

  const getTableData = async () => {
    get(url.GET_ADMIN_USERS_INFO) 
    .then(res => {
        if(res) {
          //setTableData(res)
          res.rows.map(d => (
            data.rows.push(
              {
            "name" : d.name,
            "email" : d.email,
            "registered" : d.registered,
            "no_of_stores" : d.no_of_stores,
            "no_of_products" : d.no_of_products,
            "payments" : d.payments,
            "status" : d.status,
            // "status" : [
            //   <span className={'status-cust '+d.status}>
            //     {d.status}
            //   </span>
            // ],
            "actions" : [
              <button onClick={() => resetPassword(d.email)}
                title="Reset Password" className="btn btn-primary btn-sm waves-effect waves-light btn btn-warning">
                <i className="fa fa-key" aria-hidden="true"></i>
              </button>,
              <button onClick={() => changeStatus(d.email)}
                
                title={d.status == "Inactive" ? "Activate" : "Deactivate"} className={d.status == "Inactive" ? "btn btn-primary btn-sm waves-effect waves-light btn btn-primary Active" : "btn btn-primary btn-sm waves-effect waves-light btn btn-danger Inactive"}>
                <i className="fa fa-power-off" aria-hidden="true"></i>
                {/* {d.status == "Active" ? <>Active</> : <>Inactive</>} */}
              </button>,
              <button onClick={() => deleteUser(d.email)}
                title="Delete" className="btn btn-primary btn-sm waves-effect waves-light btn  btn-danger">
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>,
            ],
          })))
          setTableData(data)
        }
    })
    .catch(error => {
      //setErrMessage(error)
    });
  }


  return (
      <React.Fragment>
          <div className="manageUserContainer showTableFoot">
              <Container fluid>
                  <div className="page-content">
                      <div className="container-fluid">
                      <Breadcrumbs title="Koloden" breadcrumbItem="Users Information / Manage Users" />

                      <Row>
                          <Col className="col-12 datatb-prod">
                          <Card>
                              <CardBody>
                              <MDBDataTable responsive bordered data={table_data}/>
                              </CardBody>
                          </Card>
                          </Col>
                      </Row>
                      </div>
                  </div>

              </Container>
          </div>
      </React.Fragment>
  )
}

export default UserManage
