import React, { useEffect, useState } from "react"
import Help from './layout/Help'
import Header from './layout/Header'
import Footer from './layout/Footer'
import {Alert, Button,} from "reactstrap"
import {AvForm, AvField } from "availity-reactstrap-validation"
import ReCAPTCHA from "react-google-recaptcha";
import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import {Link} from 'react-router-dom'
import $ from 'jquery'

import '../../kolodenTheme/css/bootstrap.min.css'
import '../../kolodenTheme/css/fonts.css'
import '../../kolodenTheme/css/all.min.css'
import '../../kolodenTheme/css/style.css'
import MetaTags from 'react-meta-tags';
const Api = (props) => {
    
    const showPageData = false;
    const [successMessage, setSuccessMessage] = useState('');
    const [errMessage, setErrMessage] = useState('');
    const [result_data, setResultsData] = useState('');
    const recaptchaRef = React.useRef();
    const [showLoading, setShowLoading] = useState(false);

    const handleAPIRequest= (event, values) => {
        setSuccessMessage('')
        setErrMessage('')
        $('#msg-error').html('Submit the form to see results here.');

        recaptchaRef.current.execute().then(rsp => {
            const api_data = { token: rsp, category: values['category'], word_mark: values['word_mark'] };
        
            // post("https://koloden.com/apiImpl/trademark/", api_data)
            // .then(response => {
            //     var sHTML = '';
            //     response.map(d => ( 
            //         sHTML = sHTML + '<tr><td>' + d.trademark + '</td><td><a target="_blank" href="http://tsdr.uspto.gov/#caseNumber=' + d.registration + '"&caseSearchType=US_APPLICATION&caseType=SERIAL_NO&searchType=statusSearch">' + d.registration + '</a></td><td><img style="width:30%;" src="https://tsdr.uspto.gov/img/' + d.serial + '/large" /></td><td>' + d.goods_services + '</td></tr>'
            //     ))
            //     if(sHTML == '') { $('#msg-error').html('Your query did not fetch any results.'); }
            //     setResultsData(sHTML);
            // })
            // .catch(error => {
            //     setErrMessage(error)
            //     console.log(error)
            // });
            setShowLoading(true);
            setResultsData('');
            post(url.API_TRADEMARK, api_data)
            .then(response => {
                var sHTML = '';
                if(response?.results?.length) {
                    response.results?.map(d => ( 
                        sHTML = sHTML + '<tr><td>' + d.trademark + '</td><td><a target="_blank" href="http://tsdr.uspto.gov/#caseNumber=' + d.registration + '"&caseSearchType=US_APPLICATION&caseType=SERIAL_NO&searchType=statusSearch">' + d.registration + '</a></td><td><img style="width:30%;" src="https://tsdr.uspto.gov/img/' + d.serial_number + '/large" /></td><td>' + d.goods_services + '</td></tr>'
                    ))
                }
                if(sHTML == '') { $('#msg-error').html('Your query did not fetch any results.'); }
                setResultsData(sHTML);
                setShowLoading(false);
            })
            .catch(error => {
                setErrMessage(error)
                console.log(error)
                setShowLoading(false);
            });
        })
    }

    $(function() {
        var pageID = 4;
        function loadPageData(){
            get(url.CMS_CONTENT + "?page=" + pageID) 
            .then(res => {
                if(res) {
                    res.forEach(element => {
                        var page = pageID;
                        var section = element.section;
                        var column = element.column;
                        if(element.content !== undefined){
                            if(element.type == 'image') {
                                $("[data-set='"+ page + section + column +"']").attr('src', "https://koloden.com/v2api/public/cms/images/" + element.content );
                            } else if(element.type == 'text') {
                                $("[data-set='"+ page + section + column +"']").html(element.content);
                            }
                        }
                    })
                }
            })
            .catch(error => {
                console.log(error)
            });
        }

        loadPageData();
    }) 


    return(
        <div className="koloden-main">
            <MetaTags>
                <title>Trademark & Copyright Search API For Resellers | Koloden</title>
                <meta name="description" content="For independent retailers and marketplaces, Koloden offers flexible and responsive JSON API access to the Koloden engine, with custom development & pricing." />
                <meta property="og:title" content="Trademark & Copyright Search API For Resellers | Koloden" />
            </MetaTags>
            <Help />
            <Header />
            <div className="section-info api-topSection contrast">
                <div className="container">
                    <div className="row justify-content-center">
                        <h1 className="title"><span data-set="411">{ showPageData ? 'Koloden' : '' }</span> <span className="secondary" data-set="412">{ showPageData ? 'API' : '' }</span></h1>
                            <div className="col-sm-4 my-auto">
                                <p data-set="413">
                                    { showPageData ? 'For independent retailers and marketplaces, Koloden offers flexible and responsive JSON API access to the Koloden engine, with custom development and pricing where applicable.' : '' }
                                </p>
                            </div>
                            <div className="col-sm-4">
                                <img data-set="414" src="{ showPageData ? 'kolodenTheme/img/Flow-Chart-Final-3-03_rs.jpg' : '' }" alt="" />
                            </div>
                            <div className="col-sm-4 my-auto">
                                <div className="get-started">
                                    <Link to="/contact-us" className="nav-link Uniwars" href="#" data-set="415">{ showPageData ? 'WOULD YOU LIKE TO KNOW MORE?' : '' }</Link>
                                </div>
                            </div>
                    </div>

                    <div className="row api-table">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-10">
                            {successMessage ? (
                                <div className="alert alert-success fade show">
                                <div dangerouslySetInnerHTML={{__html: successMessage}} />
                                </div>
                            ) : null}

                            {errMessage ? (
                                <Alert name="error-message" color="danger">{errMessage}</Alert>
                            ) : null}
                        </div>
                        <div className="col-sm-2">
                            <h2 style={{maxWidth: "160px"}} className="title-v2 trydemo"><span className="secondary" data-set="421">{ showPageData ? 'Try it Out – a <br /> Demo <br />Implementation of' : '' }</span> <span data-set="422">{ showPageData ? 'Koloden’s' : '' }</span> <span className="secondary" data-set="423">{ showPageData ? 'API' : '' }</span>
                            </h2>
                        </div>
                        <div className="col-sm-10">
                            <div className="box-table">
                            <AvForm name="amazon-form" className="form-horizontal" onValidSubmit={(e, v) => {handleAPIRequest(e, v)}} >
                                <div className="form-group">
                                    <AvField name="word_mark" label="Listing Title *" type="text" placeholder="e.g Fancy Baseball Bat" validate={{
                                    required: {value: true, errorMessage: "Please Enter Listing Title"}}}/>
                                </div>
                                <div className="form-group">
                                    <AvField name="category" label="Goods & Services Category *" type="text" placeholder="e.g Toys" validate={{
                                    required: {value: true, errorMessage: "Please Enter Goods & Services Category"}}}/>

                                    <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6LdaIOsZAAAAABGiuqZrfEWNvbybPZjZbO2uu3GU"/>

                                </div>
                                <div className="form-group center-cstm">
                                    <div className="submit">
                                        <Button type="submit" color="primary btn-black" className="w-md Uniwars">Submit</Button>
                                    </div>
                                </div>
                                </AvForm>
                            </div>
                            <div className="box-table-result">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">Word Mark Matches</th>
                                        <th scope="col">U.S. Reg. No.	</th>
                                        <th scope="col">Image</th>
                                        <th scope="col">Goods & Services</th>
                                    </tr>
                                    </thead>
                                    <tbody dangerouslySetInnerHTML={{__html: result_data}}>
                                    </tbody>
                                    {showLoading ? 
                                    <tr>
                                       <td colSpan={4}> <p>Please wait, while we are searching...</p> </td>
                                    </tr>
                                    : ''}
                                    <tr>
                                        <td colSpan={4} id="msg-error">Submit the form to see results here.</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Footer />
        </div>
    );
};

export default Api;