import React, { useState, useEffect } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  Alert,
  CardTitle,
  Form,
  Container,
  Label,
  Input,
  FormGroup,
  Button
} from "reactstrap"
import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"



const Notifications = props => {

  const [statusOn, setStatusOn] = useState(true);
  const [frequencyBtn, setFrequencyBtn] = useState('weekly');
  const [successMessage, setSuccessMessage] = useState('');
  const [loadingFlag, setLoadingFlag] = useState(false);

  const [notifySettings, setNotifySettings] = useState({
    "Status":statusOn,
    "Frequency":frequencyBtn
  });

  function handleStatusSms() {
    if(statusOn==true){
      setStatusOn(false);
    }else {
      setStatusOn(true)
    }
  }
  function handleFrequencyNotify() {
    if(frequencyBtn=='weekly'){
      setFrequencyBtn('daily');
    }else {
      setFrequencyBtn('weekly')
    }
  }


  const getPageData = async () => {
    var authUser = JSON.parse(localStorage.getItem('authUser'))
    if(authUser['notification'] == 0){
      setStatusOn(true);
    }else{
      setStatusOn(false);
    }

    if(authUser['notification_frequency'] == 'Weekly'){
      setFrequencyBtn('daily')
    }else{
      setFrequencyBtn('weekly')
    }
  }


  useEffect(() => {
    getPageData()
  }, [])



  function submitNotifySettings(){
    setLoadingFlag(true);
    setNotifySettings({
      "Status":statusOn,
      "Frequency":frequencyBtn
    })
    var notification = !statusOn
    var freq = (frequencyBtn == 'weekly') ? 'Daily' : 'Weekly'

    var authUser = JSON.parse(localStorage.getItem('authUser'))
    authUser['notification'] = notification
    authUser['notification_frequency'] = freq
    localStorage.setItem('authUser', JSON.stringify(authUser))

    post(url.API_NOTIFICATION_SETTING + "?notification=" + notification + "&frequency=" + freq)
    .then(res => {
        setLoadingFlag(false);
        setSuccessMessage("Notification changes has been saved successfully.");
        setTimeout(()=>{
          setSuccessMessage('');
        },3000);
    })
    .catch(error => {
      //setErrMessage(error)
    });

    console.log(notifySettings);
  }
    
    
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Notifications" breadcrumbItem="User Account" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    {/* <h5>Status:</h5> */}
                    <Row>
                      <Col lg={12} className="mt">
                        <h5 className="mt-2">Current Status:</h5>
                        {statusOn ? <>
                          <p>You are currently not receiving Notifications. <br></br></p>
                          </> : <>
                          <p>You are currently receiving <b>{(frequencyBtn == 'weekly') ? 'Daily' : 'Weekly'}</b> Notifications. <br></br></p>
                        </>}
                      </Col>
                      
                    </Row>

                    
                    {/* <h5>Select Weekly or Monthly SMS</h5> */}
                    <Row className="mb-2">
                      <Col lg={12}>
                        {successMessage  && typeof successMessage === "string"? (
                          <Alert color="success">
                            {successMessage}
                            </Alert>
                       ) : null }
                      </Col>
                      <Col lg={3}>
                        <h5 className="mt-4">Allow Sending Notifications</h5>
                      </Col>
                      <Col lg={3} className="mt-3">
                        <button 
                          onClick={handleStatusSms}
                          className={statusOn==true ? "toggle-btn-cst active status" : "toggle-btn-cst inactive status"}>
                          {statusOn==true ? <>
                          <span>Yes</span>
                          </> : <>
                          <span>No</span>
                          </>}
                        </button>
                      </Col> 
                    </Row>

                    {statusOn ? <></> : <>
                    
                      <Row className="mb-2">
                        <Col lg={3}>
                          <h5 className="mt-4">Set Notifications Frequency</h5>
                        </Col>
                        <Col lg={3} className="mt-3">
                          <button 
                            onClick={handleFrequencyNotify}
                            className={frequencyBtn=='weekly' ? "toggle-btn-cst active" : "toggle-btn-cst inactive"}>
                            {frequencyBtn=='weekly' ? <>
                            <span>Weekly</span>
                            </> : <>
                            <span>Daily</span>
                            </>}
                          </button>
                        </Col> 
                      </Row>
                    
                    </>}

                    <Row>
                      <Col lg={3} className="mt-4">
                        <button 
                          onClick={submitNotifySettings}
                          className="w-md btn btn-primary"
                        >
                          {loadingFlag ? <>Saving...</> : <>Save Changes</>}
                          </button>
                      </Col>
                    </Row>

                    </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }

export default Notifications