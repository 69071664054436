import React, {useEffect, useState} from 'react'
import Help from './layout/Help'
import Header from './layout/Header'
import Footer from './layout/Footer'
import {Link, useParams, useHistory } from 'react-router-dom'
import MetaTags from 'react-meta-tags';
// import {Accordion} from 'bootstrap-4-react';

import {post, del, get, put} from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"

import '../../kolodenTheme/css/bootstrap.min.css'
import '../../kolodenTheme/css/fonts.css'
import '../../kolodenTheme/css/all.min.css'
import '../../kolodenTheme/css/style.css'
import '../../kolodenTheme/css/blog.css'

import $ from 'jquery'
import Pagination from "../../usePagination";

const Blogs = () => {

    const [blogs, setBlogs] = useState([]);
    const [total, setTotal] = useState([]);
    const [perPage, setPerPage] = useState([]);

    let {page} = useParams();

    page = parseInt(page);

    if(page < 1 || isNaN(page)) {
        page = 1;
    }

    let history = useHistory();
    const redirectTo = (pageNumber = 1) => {
        let path = '/blogs/' + pageNumber;
        history.push(path);
    }
    const paginate = pageNumber => redirectTo(pageNumber);

    useEffect(() => {
        var xhr = new XMLHttpRequest();
        setPerPage(10);
        var per_page = 10;
        xhr.withCredentials = true;

        document.title = 'Blogs | Koloden';

        xhr.addEventListener("readystatechange", function () {
            if (this.readyState === 4) {
                setTotal(Math.ceil(xhr.getResponseHeader("X-WP-Total")));
                setBlogs(JSON.parse(this.responseText));
            }
        });

        xhr.open("GET",
            "https://koloden.com/blog/wp-json/wp/v2/posts?_embed&categories=3&context=embed&page="+ page +"&per_page=" + per_page + "&orderby=id&order=asc");

        xhr.send();
    }, [page]);

    return (
        <div className="koloden-main">

            <Help/>
            <Header/>
            <MetaTags>
                <meta name="description" content="With Koloden, automatically validate product listing content and media on a daily basis for infringement risk against propriety trademark and image databases." />
                <meta property="og:title" content="Intellectual Property Management Software For Resellers | Koloden" />
            </MetaTags>
            <div className="blog-container">
                <div className="container">
                    <div className="row">
                        {blogs.map((blog, index) => (
                            <div className="col-xl-6">
                                <div className="post-box">
                                    <Link to={{ pathname: `/post/${blog.slug}`}}>
                                        <span className="Main"
                                              style={{backgroundImage: `url(${blog._embedded["wp:featuredmedia"]["0"].source_url})`}}>
                                        </span>
                                    </Link>
                                    <h3>
                                        <Link to={{ pathname: `/post/${blog.slug}`}}>
                                            {blog.title.rendered}
                                        </Link>
                                    </h3>
                                    <div className="text-s" dangerouslySetInnerHTML={{__html: blog.excerpt.rendered}}>
                                    </div>
                                    <Link to={{ pathname: `/post/${blog.slug}`}} className="button themeBtn">read more</Link>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>

            <div className='cstm-pgn'>
                <Pagination
                    postsPerPage={perPage}
                    totalPosts={total}
                    paginate={paginate}
                    page={page}
                />
            </div>
            <Footer/>
        </div>
    );
};

export default Blogs;
