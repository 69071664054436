import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import axios from 'axios';
import store from "./store"

//apply base url for axios
//const API_URL = "http://koloden.pk/public/api"
//const API_URL = "https://koloden.com/api"
//const API_URL = "https://kolodenbackend.plego.pro/api"
const API_URL = "https://koloden.com/v2api/public/api"  // This is the current Live API
//const API_URL = "http://koloden.local/api" // This is the local API 
axios.defaults.withCredentials = true;

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
