import React, { useEffect, useState } from "react"
import { Alert, Container, Row, Col, CardBody, Card, CardTitle, CardSubtitle, Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import { Bar } from "react-chartjs-2"
import ReactApexChart from "react-apexcharts"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Components
import SplineArea from "../AllCharts/apex/SplineAreaWalmart"
import MiniWidget from "./mini-widget"
import { MDBDataTable } from "mdbreact"
import "./datatables.scss"
import "./datatable-cstm.css"
import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Doughnut from "../AllCharts/echart/doughnutchartWalmart"
import Pagination from "../../usePagination"
import $ from 'jquery';
import { getDefaultPaymentMethod } from "../../helpers/payment_method_helper"

const series1 = [{
  data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54]
}]

const options1 = {
  fill: {
    colors: ['#00ab4e']
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '50%'
    }
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1
    },
  },
  tooltip: {
    fixed: {
      enabled: !1
    },
    x: {
      show: !1
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return ''
        }
      }
    },
    marker: {
      show: !1
    }
  }
};

const series2 = [70]

const options2 = {
  fill: {
    colors: ['#34c38f']
  },
  chart: {
    sparkline: {
      enabled: !0
    }
  },
  dataLabels: {
    enabled: !1
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: '60%'
      },
      track: {
        margin: 0
      },
      dataLabels: {
        show: !1
      }
    }
  }
};

const series3 = [55]

const options3 = {
  fill: {
    colors: ['#00ab4e']
  },
  chart: {
    sparkline: {
      enabled: !0
    }
  },
  dataLabels: {
    enabled: !1
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: '60%'
      },
      track: {
        margin: 0
      },
      dataLabels: {
        show: !1
      }
    }
  }
};

const series4 = [{
  data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
}]

const options4 = {

  fill: {
    colors: ['#f1b44c']
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '50%'
    }
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1
    },
  },
  tooltip: {
    fixed: {
      enabled: !1
    },
    x: {
      show: !1
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return ''
        }
      }
    },
    marker: {
      show: !1
    }
  }
};

const Walmart = props =>  {

  const [page, setPage] = useState('');
  const [perPage, setPerPage] = useState('');
  const [total, setTotal] = useState('');
  const [storeId, setStoreId] = useState([]);

  const [successMessage, setSuccessMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [card, setCard] = useState('');
  const [store_data, setStoreData] = useState([]);
  const [subStoreName, setSubStoreName] = useState([]);
  const [store_products, setStoreProducts] = useState([]);
  const StoreName = 'Walmart';

  const [reports_data, setReport] = useState([]);

  /***** History - Start *****/
  const [series_data, setSeriesData] = useState([]);
  const [category_data, setCategoryData] = useState([]);
  const [selectedHistory, setHistory] = useState('');
  /***** History - End *****/

  const [exact_match_results, setExactMatch] = useState([]);
  const [logo_detection_results, setLogoDetection] = useState([]);
  const [trademark_search_results, setTrademarkSearch] = useState([]);
  const [text_match_results, setTextMatch] = useState([]);
  const [prdocut_name_heading, setProductHeading] = useState('');

  const [exactMatchProductImage, setExactMatchProductImage] = useState('');

  useEffect(() => {
    setStoreData([])
    setStoreId([])
    setStoreProducts([])
    setSuccessMessage('')
    setErrMessage('')
    getStoreList();

    setReport([])
    getReports()

    /***** History - Start *****/
    setSeriesData([])
    getSeries();
    setCategoryData([]);
    /***** History - End *****/

    setTextMatch([]);
    setTrademarkSearch([]);
    setLogoDetection([]);
    setExactMatch([]);
    setProductHeading(''); 
    getDefaultPaymentMethod();

    setExactMatchProductImage('');
  }, [])

  $(function() {
    const defaultPaymentMethod = window.localStorage.getItem('default_payment_method');
    const defaultPaymentSource = window.localStorage.getItem('default_payment_source');
    const cardInfo = window.localStorage.getItem('m_card');
    if( ((!defaultPaymentMethod || defaultPaymentMethod == 'card') && 
     (!defaultPaymentSource || defaultPaymentSource == 'N/A')) && 
      (!cardInfo || cardInfo == '0') ) {
      setCard(0);
    }
  })

  /***** History - Start *****/
  async function handleHistoryChange(e) {
    var history = e.target.value
    setHistory(history)
    post(url.CHART_DASHBOARD_SERIES + '?store=4&h=' + history) 
    .then(res => {
        if(res) {
            setCategoryData(res[0].xaxis);
            res.map(d => (
              series.push({
              "name" : d.name,
              "data" : d.data
            })))
        }
      //setCategoryData(category_data);
      setSeriesData(series);
    })
    .catch(error => {
      //setErrMessage(error)
    });
  }

  const series = [
  ]

  const series_options = {
    dataLabels: {
      enabled: !1
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    series: [{
      name: 'Listings',
      data: [11, 40, 28, 52, 42, 109, 100, 11, 40, 28, 52, 42]
    },
    {
      name: 'series2',
      data: [60, 34, 46, 34, 52, 41, 22, 60, 34, 46, 34, 52]
    },
    {
      name: "series3",
      data: [50, 31, 41, 14, 92, 11, 33, 50, 31, 41, 14, 92],
    },
    {
      name: "series4",
      data: [14, 92, 11, 30, 50, 31, 14, 92, 11, 30, 50, 31],
    }
  ],
    colors: ['black', '#f46a6a', '#f1b44c', '#34c38f', '#909090'],
    xaxis: {
      type: 'text',
      categories: [],
    },
    grid: {
      borderColor: '#f1f1f1',
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      },
    }
  }
 
  const closeModal = () => {
    var locModal = document.getElementById('product_result_modal');
    locModal.style.display = "none";
    ViewProducts(lastProductId, lastProductSName, lastProductPage);
  }
  
  const ShowProductData = (storeId, productId, type, productName) => {
    post(url.USER_STOREPRODUCTS + '/list?storeId=' + storeId + '&prId=' + productId + '&type=' + type).then(res => {
      if(res) {
        setTextMatch(res.listMatches.text_match_results);
        setTrademarkSearch(res.listMatches.trademark_search_results);
        setLogoDetection(res.listMatches.logo_detection_results);
        setExactMatch(res.listMatches.exact_match_results);
        setProductHeading(productName);

        if(res.listMatches.text_match_results.length > 0){
          res.listMatches.text_match_results.map((item, i) => {
            if(item.ignore == 1){
              setSelectedCheckboxIds( selectedCheckboxIds => [...selectedCheckboxIds, item.id]);
            }
  
          });
        }
  
        if(res.listMatches.trademark_search_results.length > 0){
          res.listMatches.trademark_search_results.map((item, i) => {
            if(item.ignore == 1){
              setSelectedCheckboxIds( selectedCheckboxIds => [...selectedCheckboxIds, item.id]);
            }
  
          });
        }
  
        if(res.listMatches.logo_detection_results.length > 0){
          res.listMatches.logo_detection_results.map((item, i) => {
            if(item.ignore == 1){
              setSelectedCheckboxIds( selectedCheckboxIds => [...selectedCheckboxIds, item.id]);
            }
  
          });
        }
  
        if(res.listMatches.exact_match_results.length > 0){
          res.listMatches.exact_match_results.map((item, i) => {
            if(item.ignore == 1){
              setSelectedCheckboxIds( selectedCheckboxIds => [...selectedCheckboxIds, item.id]);
            }
  
          });
        }

        if(res.listMatches.text_match_results.length > 0 || res.listMatches.trademark_search_results.length > 0 || res.listMatches.logo_detection_results.length > 0 ||  res.listMatches.exact_match_results.length > 0 ) {
          var locModal = document.getElementById('product_result_modal');
          locModal.style.display = "block";
        }
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  const getSeries = () => {    
    post(url.CHART_DASHBOARD_SERIES + '?store=4')
    .then(res => {
        if(res) {
            setCategoryData(res[0].xaxis);
            res.map(d => (
              series.push({
              "name" : d.name,
              "data" : d.data
            })))
        }
      //setCategoryData(series_options);
      setSeriesData(series);
    })
    .catch(error => {
      //setErrMessage(error)
    });
  }  

  const getHSeries = () => {    
    post(url.CHART_DASHBOARD_SERIES + '?store=4&h=' + selectedHistory)
    .then(res => {
        if(res) {
            setCategoryData(res[0].xaxis);
            res.map(d => (
              series.push({
              "name" : d.name,
              "data" : d.data
            })))
        }
      //setCategoryData(series_options);
      setSeriesData(series);
    })
    .catch(error => {
      //setErrMessage(error)
    });
  }
  /***** History - End *****/

  const getStoreList = () => {    
    get(url.GET_USERSTORES + "/" + StoreName) 
    .then(res => {
        if(res) {

          if(res == "Not found")
            return;

          const store = res.map(d => (
            stores_template.rows.push({
            "key" : d.sr,
            "Store_Name" : d.store_name,
            "Action":  [
                <span><button key="b1" className='btn btn-primary btn-sm waves-effect waves-light btn btn-primary' onClick={() => ViewProducts(d.id,d.store_name)}>View Products</button> | </span>,
                <button key="b2" className='btn btn-primary btn-sm waves-effect waves-light btn btn-danger' onClick={() => DeleteStore(d.id)}>Delete Store</button>
              ]
          })))
        }
      setStoreData(stores_template);
    })
    .catch(error => {
      //setErrMessage(error)
    });
  }  

  const DeleteStore = (id) => {
    
    if (!window.confirm('Are you sure you wish to delete this store?')) {
      return;
    }

    setSuccessMessage('')
    setErrMessage('')

    del(url.GET_USERSTORES + "/" + id) 
    .then(res => {
        if(res) {
          setSuccessMessage('Store deleted successfully')
          window.location.reload();
        }
      else {
        setErrMessage('Error: Failed to delete Store')
      }
    })
    .catch(error => {
      //setErrMessage(error)
    });
  }

  const [lastProductId, setLastProductId] = useState('');
  const [lastProductSName, setLastProductSName] = useState('');
  const [lastProductPage, setLastProductPage] = useState('');
  const ViewProducts = (id, sname, page = 1) => {
    setLastProductId(id);
    setLastProductSName(sname);
    setLastProductPage(page);
    
    document.getElementById("grand_Slide").style.display = "block";

    setSuccessMessage('')
    setErrMessage('')
    setSubStoreName(sname);

    const products_data = {
      columns: [
        {
          label: "Listing Title",
          field: "Listing_Title",
          sort: "asc",
          width: 150,
        },
        {
          label: "Main Image",
          field: "Main_Image",
          sort: "asc",
          width: 270,
        },
        /*
        {
          label: "Additional Images",
          field: "Additional_Images",
          sort: "asc",
          width: 200,
        },
        {
          label: "Last Scanned",
          field: "Last_Scanned",
          sort: "asc",
          width: 100,
        },
        */
        {
          label: "Action",
          field: "Action",
          sort: "asc",
          width: 150,
        }
      ],
      rows: [
        
        
      ],
    }

    var search_value = document.getElementById("searchProductsInput").value;
    var riskFilter    = document.getElementById("riskFilter").value;

    var api_url = url.USER_STOREPRODUCTS + "/" + id + "/1";

    if(search_value.length > 0) {
      api_url = api_url + "/" + search_value;
    }

    if(riskFilter.length > 0) {
      if(search_value.length < 1) {
        api_url = api_url + "/0";
      }
      api_url = api_url + "/" + riskFilter;
    }

    api_url = api_url +"?page=" + page

    get(api_url)
    .then(res => {
         if(res) {
            const uniqueKey = 1;
           setPage(res.products.current_page);
           setStoreId(res.store_id);
           setPerPage(res.products.per_page);
           setTotal(res.products.total);
            const store = res.product_list.map(d => (
            products_data.rows.push({
            "key" : uniqueKey+1,
            Listing_Title: d.name,
            Main_Image: [<img width="40px" height="20px" src={d.main_image} />],
            /*
            Additional_Images: [
              d.additional_images.map(adimg => (
              <img width="40px" height="20px" src={adimg} />
              ))
            ],
            Last_Scanned: d.last_updated,
            */
            Action: [
              <button style={{ display: (d.critical_matches > 0 ? "" : "none") }} onClick={() => ShowProductData(id,d.product_id,'critical_match',d.name + ' - Word Mark Matches')} className={"btn btn-primary btn-sm waves-effect waves-light btn " + (d.critical_matches > 0 ? 'btn-danger' : 'btn-primary')}>{d.critical_matches} Critical Matches</button>,
              <button style={{ display: (d.critical_matches_ignore > 0 ? "" : "none") }} onClick={() => ShowProductData(id,d.product_id,'critical_match',d.name + ' - Word Mark Matches')} className={"btn btn-primary btn-sm waves-effect waves-light btn " + (d.critical_matches_ignore > 0 ? 'btn-success' : 'btn-primary')}>{d.critical_matches_ignore} Critical Matches (Ignored)</button>,

              <button style={{ display: (d.text_matches > 0 ? "" : "none") }} onClick={() => ShowProductData(id,d.product_id,'text_match',d.name + ' - Wordmark Matches')} className={"btn btn-primary btn-sm waves-effect waves-light btn " + (d.text_matches > 0 ? 'btn-warning' : 'btn-primary')}>{d.text_matches} Word Mark Matches</button>,
              <button style={{ display: (d.text_matches_ignore > 0 ? "" : "none") }} onClick={() => ShowProductData(id,d.product_id,'text_match',d.name + ' - Wordmark Matches')} className={"btn btn-primary btn-sm waves-effect waves-light btn " + (d.text_matches_ignore > 0 ? 'btn-success' : 'btn-primary')}>{d.text_matches_ignore} Word Mark Matches (Ignored)</button>,

              <button style={{ display: (d.listing_matches > 0 ? "" : "none") }} onClick={() => { setExactMatchProductImage(d.main_image); ShowProductData(id,d.product_id,'exact_match',d.name + ' - Image Match'); }} className={"btn btn-primary btn-sm waves-effect waves-light btn " + (d.listing_matches > 0 ? 'btn-danger' : 'btn-primary')}>{d.listing_matches} Image Match</button>,
              <button style={{ display: (d.listing_matches_ignore > 0 ? "" : "none") }} onClick={() => { setExactMatchProductImage(d.main_image); ShowProductData(id,d.product_id,'exact_match',d.name + ' - Image Match'); }} className={"btn btn-primary btn-sm waves-effect waves-light btn " + (d.listing_matches_ignore > 0 ? 'btn-success' : 'btn-primary')}>{d.listing_matches_ignore} Image Match (Ignored)</button>,

              <br />,

              <button style={{ display: (d.logo_matches > 0 ? "" : "none") }} onClick={() => ShowProductData(id,d.product_id,'logo_detection',d.name + ' - Primary Logo Lookup')} className={"btn btn-primary btn-sm waves-effect waves-light btn " + (d.logo_matches > 0 ? 'btn-warning' : 'btn-primary')}>{d.logo_matches} Primary Logo Lookup</button>,
              <button style={{ display: (d.logo_matches_ignore > 0 ? "" : "none") }} onClick={() => ShowProductData(id,d.product_id,'logo_detection',d.name + ' - Primary Logo Lookup')} className={"btn btn-primary btn-sm waves-effect waves-light btn " + (d.logo_matches_ignore > 0 ? 'btn-success' : 'btn-primary')}>{d.logo_matches_ignore} Primary Logo Lookup (Ignored)</button>,

              <button style={{ display: (d.possible_matches > 0 ? "" : "none") }} onClick={() => ShowProductData(id,d.product_id,'trademark_search',d.name + ' - Secondary Logo Lookup')} className={"btn btn-primary btn-sm waves-effect waves-light btn " + (d.possible_matches > 0 ? 'btn-warning' : 'btn-primary')}>{d.possible_matches} Secondary Logo Lookup</button>,
              <button style={{ display: (d.possible_matches_ignore > 0 ? "" : "none") }} onClick={() => ShowProductData(id,d.product_id,'trademark_search',d.name + ' - Secondary Logo Lookup')} className={"btn btn-primary btn-sm waves-effect waves-light btn " + (d.possible_matches_ignore > 0 ? 'btn-success' : 'btn-primary')}>{d.possible_matches_ignore} Secondary Logo Lookup (Ignored)</button>,
              ],
          })))

           document.getElementById("grand_Slide").style.display = "none";
           document.getElementById("storeProducts").style.display = "block";

           setTimeout(()=>{
            const element = document.getElementById("storeProducts");
            element.scrollIntoView();
           },300)
        }
            
      setStoreProducts(products_data);
    })
    .catch(error => {
      //setErrMessage(error)
    });
  }

  const stores_template = {
    columns: [
      {
        label: "#",
        field: "key",
        sort: "asc",
        width: 150,
        key: 1,
      },
      {
        label: "Store Name",
        field: "Store_Name",
        sort: "asc",
        width: 270,
        key: 2,
      },
      {
        label: "Action",
        field: "Action",
        sort: "asc",
        width: 200,
        key: 3,
      }
    ],
    rows: [
    ],
  }

  const products_template = {
    columns: [
      {
        label: "Listing Title",
        field: "Listing_Title",
        sort: "asc",
        width: 150,
      },
      {
        label: "Main Image",
        field: "Main_Image",
        sort: "asc",
        width: 270,
      },
      /*
      {
        label: "Additional Images",
        field: "Additional_Images",
        sort: "asc",
        width: 200,
      },
      {
        label: "Last Scanned",
        field: "Last_Scanned",
        sort: "asc",
        width: 100,
      },
      */
      {
        label: "Action",
        field: "Action",
        sort: "asc",
        width: 150,
      }
    ],
    rows: [
      
      
    ],
  }

  const reports = []

  const getReports = async () => {  
    
    let td = new Date().toISOString().slice(0, 10);
    var uid = localStorage.getItem('m_id');
    var cdrtWAL = localStorage.getItem('c_d_r_t_WAL_' + uid + '_' + td);

    if(cdrtWAL){
      var res = JSON.parse(cdrtWAL);
      res.map(d => (
        reports.push({
          "id" : d.id,
          "icon" : d.icon,
          "title" : d.title,
          "value" : d.value,
          "decimal" : d.decimal,
          "prefix" : d.prefix,
          "suffix" : d.suffix,
          "charttype" : d.chart_type,
          "chartheight" : d.chart_height,
          "chartwidth" : d.chart_width,
          "badgeValue" : d.badge_value,
          "color" : d.color,
          "desc" : d.desc,
          "series" : d.series,
          "options" : options1,
        })))
      setReport(reports);
    }else{
      await post(url.CHART_DASHBOARD_REPORTS_TODAY + '?store=4') 
      .then(res => {
          if(res) {
              localStorage.setItem('c_d_r_t_WAL_' + uid + '_' + td, JSON.stringify(res));
              res.map(d => (
                reports.push({
                "id" : d.id,
                "icon" : d.icon,
                "title" : d.title,
                "value" : d.value,
                "decimal" : d.decimal,
                "prefix" : d.prefix,
                "suffix" : d.suffix,
                "charttype" : d.chart_type,
                "chartheight" : d.chart_height,
                "chartwidth" : d.chart_width,
                "badgeValue" : d.badge_value,
                "color" : d.color,
                "desc" : d.desc,
                "series" : d.series,
                "options" : options1,
              })))
          }
        setReport(reports);
      })
      .catch(error => {
        //setErrMessage(error)
      });
    }
  }

  const paginate = pageNumber => ViewProducts(storeId,'ebay', pageNumber);

  const handler = (event) => {
    if(event.key === 'Enter') {
      ViewProducts(storeId,'ebay', 1)
    }
  };

  // Reset Input Field handler
  const resetSearchField = () => {
    document.getElementById("searchProductsInput").value = "";
    ViewProducts(storeId,'ebay', 1)
  };

  // Reset Input Field handler
  const resetRiskFilter = () => {
    ViewProducts(storeId,'ebay', 1);
  };

  const [selectedCheckboxIds, setSelectedCheckboxIds] = useState([]);
  const updateIgnoreProductAlert = (prID, e) => {
    var checkedId = prID;
    var prIgnore = e.target.checked;
    console.log('ID: ' + prID);
    console.log('Ignore: ' + prIgnore);

    if(prIgnore){
      setSelectedCheckboxIds( selectedCheckboxIds => [...selectedCheckboxIds, checkedId]);
    }else{
      setSelectedCheckboxIds( selectedCheckboxIds => selectedCheckboxIds.filter(id => id !== checkedId));
    }

    var ignore = 0;
    if(prIgnore) { ignore = 1; }
    post(url.USER_STOREPRODUCTS_IGNORE_ALERT + '/' + prID + '/' + ignore).then(res => {
      if(res) {      
        let td = new Date().toISOString().slice(0, 10);
        var uid = localStorage.getItem('m_id');
        localStorage.removeItem('c_d_r_lss_WAL_' + uid + '_' + td);
        localStorage.removeItem('c_d_r_t_WAL_' + uid + '_' + td);
        localStorage.removeItem('c_d_r_lss_' + uid + '_' + td);
        localStorage.removeItem('c_d_r_t_' + uid + '_' + td);
        /*
        setTimeout(
          function() {
            window.location.reload()
          }, 3000);
        */
      }
    })
    .catch(error => {
      console.log(error);
    });


    console.log(selectedCheckboxIds);
  }

  return (
    <React.Fragment>
      <div className="popup_CustomModel" id="product_result_modal">
            {exact_match_results && exact_match_results.length > 0 ? (
              <span>
                <div className="header_popup">
                  <span className="text-primary">Your changes will appear after page refresh!</span>
                  <button onClick={() => closeModal()} type="button" className="close" id="close_PopupCstm" data-dismiss="modal">×</button>
                  <h1>{/* prdocut_name_heading */}</h1>
                </div>

                  <div className="">
                    <table className="table table-bordered table-hover text-center">
                      <thead>
                        <tr><th>Main Image</th><th>Cited Reference Image</th><th>Score</th></tr>
                      </thead>
                      <tbody>
                      {exact_match_results.map((item, i) => (
                        <tr key={i}>
                          <td><img alt={item.im_name} src={exactMatchProductImage}/></td>
                          <td><img alt={item.im_name} src={item.im_url}/></td>
                          <td>{item.score}</td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="footer_popup">
                    <div className="row">
                      <div className="col-10">
                        <h6 className="text-left">The above image(s) in our reference database were determined to be close to one or more images used in this product listing.  Please confirm you have any necessary permissions to use your images.</h6>
                      </div>
                      <div className="col-2">
                        <button onClick={() => closeModal()} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                  {/* <div className="footer_popup">
                    <button onClick={() => closeModal()} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                  </div> */}
                </span>
            ) : null}


            {logo_detection_results && logo_detection_results.length > 0 ? (
              <div>
                <div className="header_popup">
                  <span className="text-primary">Your changes will appear after page refresh!</span>
                  <button onClick={() => closeModal()} type="button" className="close" id="close_PopupCstm" data-dismiss="modal">×</button>
                  <h1>{/* prdocut_name_heading */}</h1>
                </div>

                  <div className="">
                    <table className="table table-bordered table-hover text-center">
                      <thead>
                        <tr><th>Label</th><th>Score</th><th>Registration Number(s)</th></tr>
                      </thead>
                      <tbody>
                      {logo_detection_results.map((item, i) => (
                        <tr key={i}>
                          <td>{item.detected}</td>
                          <td>{item.score}</td>
                          <td>{item.registration_number}</td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="footer_popup">
                    <div className="row">
                      <div className="col-10">
                        {/* <h6 className="text-left">The above image(s) in our reference database were determined to be close to one or more images used in this product listing.  Please confirm you have any necessary permissions to use your images.</h6> */}
                      </div>
                      <div className="col-2">
                        <button onClick={() => closeModal()} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                  {/* <div className="footer_popup">
                    <button onClick={() => closeModal()} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                  </div> */}

                </div>
            ) : null}

            {trademark_search_results && trademark_search_results.length > 0 ? (
                <div>

                  <div className="header_popup">
                    <span className="text-primary">Your changes will appear after page refresh!</span>
                    <button onClick={() => closeModal()} type="button" className="close" id="close_PopupCstm" data-dismiss="modal">×</button>
                    <h1>{/* prdocut_name_heading */}</h1>
                  </div>

                    <div className="">
                      <table className="table table-bordered table-hover text-center">
                        <thead>
                          <tr><th>Cited Reference Image</th><th>Score</th></tr>
                        </thead>
                        <tbody>
                        {trademark_search_results.map((item, i) => (
                          <tr key={i}>
                            <td><img alt={item.im_name} src={item.im_url}/></td>
                            <td>{item.score}</td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="footer_popup">
                      <div className="row">
                        <div className="col-10">
                          {/* <h6 className="text-left">The above image(s) in our reference database were determined to be close to one or more images used in this product listing.  Please confirm you have any necessary permissions to use your images.</h6> */}
                        </div>
                        <div className="col-2">
                          <button onClick={() => closeModal()} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        </div>
                      </div>
                    </div>
                    {/* <div className="footer_popup">
                      <button onClick={() => closeModal()} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                    </div> */}

                  </div>
              ) : null}


            {text_match_results && text_match_results.length > 0 ? (
              <div>

                  <div className="header_popup">
                    <span className="text-primary">Your changes will appear after page refresh!</span>
                    <button onClick={() => closeModal()} type="button" className="close" id="close_PopupCstm" data-dismiss="modal">×</button>
                    <h1>{/* prdocut_name_heading */}</h1>
                  </div>

                  <div className="">
                    <table className="table table-bordered table-hover text-center">
                      <thead>
                        <tr><th>Word Mark Matches</th><th>U.S. Reg. No.</th><th>Cited Reference Image</th><th>Goods & Services</th><th className="table-col-reason">Reason</th></tr>
                      </thead>
                      <tbody>
                      {text_match_results.map((item, i) => (
                        <tr key={i}>
                          <td>{item.value}</td>
                          <td><a href={"http://tsdr.uspto.gov/#caseNumber=" + item.registration + "&caseSearchType=US_APPLICATION&caseType=SERIAL_NO&searchType=statusSearch"}
                                             target="_blank">{item.registration}</a></td>
                          <td><img src={"http://tsdr.uspto.gov/img/" + item.serial + "/large"}/></td>
                          <td>{item.goods_services_partial}</td>
                          <td className="table-col-reason">{item.reason}</td>
                          <td className="table-col-reason">
                            <label className="container-kol-chkb">Ignore
                              <input type="checkbox" value={item.id} checked={selectedCheckboxIds.includes(item.id)} onChange={ e => { updateIgnoreProductAlert(item.id, e) }}></input>
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="footer_popup">
                    <div className="row">
                      <div className="col-10">
                        {/* <h6 className="text-left">The above image(s) in our reference database were determined to be close to one or more images used in this product listing.  Please confirm you have any necessary permissions to use your images.</h6> */}
                      </div>
                      <div className="col-2">
                        <button onClick={() => closeModal()} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                    {/* <div className="footer_popup">
                      <button onClick={() => closeModal()} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                    </div> */}

                </div>
            ) : null}
      </div>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Koloden" breadcrumbItem={"Platforms / " + StoreName }/>
          {/* <span className="err-fld">{ card == '0' ? 'Add your payment details by clicking on User Account > Payment Method' : '' }</span> */}
          <Container fluid>
          {/* <Row>
            {reports_data && reports_data.length > 0 ? (
              <MiniWidget reports={reports_data} />
            ) : null}
          </Row> */}
          {/* <Row>
            <Col xl={8}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4"> Account History </CardTitle>
                  <div className="date-months">
                      <label>Select:</label>
                      <select onChange={ handleHistoryChange }>
                        <option value="1d">1 Day</option>
                        <option value="2d">2 Days</option>
                        <option value="5d">5 Days</option>
                        <option value="15d">15 Days</option>
                        <option value="1m" selected>1 Month</option>
                        <option value="2m">2 Months</option>
                        <option value="3m">3 Months</option>
                        <option value="6m">6 Months</option>
                        <option value="1y">1 Year</option>
                      </select>
                    </div>
                    <div>
                      {series_data && series_data.length > 0 ? (
                          <ReactApexChart options={category_data} series={series_data} type="area" height="350" className="apex-charts"/>
                      ) : null}
                    </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card>
                <CardBody>
                  <CardTitle>Listing Scan Summary</CardTitle>
                  <div id="doughnut-chart" className="e-chart">
                    <Doughnut />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        
        </Container>

          <Row>
            <Col className="col-12 datatb-prod">
              <Card>
                <CardBody>
                  <CardTitle>{StoreName} - Stores</CardTitle>

                  {successMessage ? (
                        <div className="alert alert-success fade show">
                          <div dangerouslySetInnerHTML={{__html: successMessage}} />
                        </div>
                  ) : null}

                  {errMessage ? (
                        <Alert name="error-message" color="danger">{errMessage}</Alert>
                  ) : null}

                  {store_data && store_data.rows && store_data.rows.length > 0 ? (
                      <MDBDataTable responsive bordered data={store_data} />
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>

            <div className="grand_Slide" id="grand_Slide" style={{display: "none"}}>
              <Spinner id="loader-page" className="m-1 .loader-table" color="primary" />
            </div>
            <Col id="storeProducts" className="col-12 datatb-prod storeProducts unstretch-image" style={{display: "none"}}>
              <Card>
                <CardBody>
                  {subStoreName && subStoreName != '' ? (
                  <CardTitle style={{marginBottom: "20px"}}>{StoreName} - {subStoreName} - Products</CardTitle>
                  ) : null}

                  <div className="Option-fieldDev resp-fullLayout">
                    <label>Select:</label>
                    <select id="riskFilter" name="risk_filter" onChange={resetRiskFilter}>
                      <option value="" selected>None</option>
                      <option value="critical_match">Critical Matches</option>
                      <option value="text_match">Word Mark Matches</option>
                      <option value="exact_match">Image Matches</option>
                      <option value="logo_detection">Primary Logo Lookup</option>
                      <option value="trademark_search">Secondary Logo Lookup</option>
                    </select>
                  </div>
                  <div className="search-fieldDev resp-fullLayout">
                    <label>Search:</label>
                    <input id="searchProductsInput" placeholder="Enter Keywords" onKeyPress={(e) => handler(e)} className="form-control"></input>
                    <button onClick={resetSearchField}><i title="Remove Keywords" className="mdi mdi-close-circle"></i></button>
                  </div>
                  {store_products && store_products.rows && store_products.rows.length > 0 ? (
                    <MDBDataTable responsive striped centered="true" bordered data={store_products} />
                  ) : null}
                  <Pagination
                      postsPerPage={perPage}
                      totalPosts={total}
                      paginate={paginate}
                      page={page}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Walmart
