import React, { useEffect, useState } from "react"
import { MDBDataTable } from "mdbreact"
import { Container, Modal, Table, Pagination, PaginationItem, PaginationLink, Form, FormGroup, Label, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Input,
Button} from "reactstrap"
import { Link } from "react-router-dom"
import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"

//Import Components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import "./datatable-cstm.css"
import $ from 'jquery'
import { removeSpaces } from "../../helpers/app-helper"
import { link } from "fs"

const UserManage = props =>  {

  var cont = 1;
  const [tableData, setTableData] = useState({});
  const [modal_view, setmodal_view] = useState(false);
  const [fieldModifyStatus, setFieldModifyStatus] = useState("");
  const [titleView, setTitleView] = useState("");

  const [refreshPage, setrefreshPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [dataMode, setDataMode] = useState('');
  const [dataId, setDataId] = useState('');
  const [dataName, setDataName] = useState('');
  const [dataNumber, setDataNumber] = useState('');
  const [dataRegNo, setDataRegNo] = useState('');
  const [dataReason, setDataReason] = useState('');
  const [dataWordMark, setDataWordMark] = useState('');
  const [searchTxt, setSearchTxt] = useState('')
  const [dataCheckStatus, setDataCheckStatus] = useState('');
  const [dataLiveDead, setDataLiveDead] = useState('Live');
  const [dataError, setDataError] = useState('');

  const [delete_ModalStatus, setdelete_ModalStatus] = useState(false);
  const [deleteDataId, setDeleteDataId] = useState('');
  const [deleteDataNumber, setDeleteDataNumber] = useState('');
  const [deleteDataReason, setDeleteDataReason] = useState('');
  const [deleteDataWordmark, setDeleteDataWordmark] = useState('');

  function tog_standard(val, id=0, name='', number=0, regNo='', wordmark='', reason='', checkStatus='', liveDead='') {
    if(val=="edit"){
      setDataError('');
      setDataMode('edit');
      setFieldModifyStatus("edit");
      setTitleView("Edit Critical List");
      setDataId(id);
      setDataName(name);
      setDataNumber(number);
      setDataRegNo(regNo);
      setDataCheckStatus(checkStatus);
      setDataLiveDead(liveDead);
      setDataReason(reason);
      setDataWordMark(wordmark);
    }else {
      setDataError('');
      setDataMode('add');
      setFieldModifyStatus("add");
      setTitleView("Add Critical List");
      setDataId('');
      setDataName('');
      setDataNumber('');
      setDataRegNo('');
      setDataCheckStatus('');
      setDataLiveDead('');
      setDataReason('');
      setDataWordMark('');
    }
    setmodal_view(!modal_view);
  }

  const data = {
    columns: [
      /*
      {
        label: "Id",
        field: "Id",
        sort: "asc",
        width: 150,
      },*/
      {
        label: "Owner",
        field: "Name",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Serial No",
      //   field: "SerialNo",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "U.S. Reg. No.",
        field: "USRegNo",
        sort: "asc",
        width: 150,
      },
      {
        label: "Wordmark",
        field: "Wordmark",
        sort: "asc",
        width: 270,
      },
      // {
      //   label: "Check Status",
      //   field: "CheckStatus",
      //   sort: "asc",
      //   width: 150,
      // },
      /*
      {
        label: "Live",
        field: "LiveDead",
        sort: "asc",
        width: 150,
      },*/
      // Bottom is old fields
      {
        label: "Reason",
        field: "Reason",
        sort: "asc",
        width: 100,
      },
      {
        label: "Actions",
        field: "Actions",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [
      /*
      {
        Number: "1198564",
        Wordmark: "DEANTAPUP",
        Reason: "Litigation",
        Actions: [
            <button className="btn btn-primary btn-sm waves-effect waves-light btn btn-warning"
                            onClick={() => {
                              tog_standard("edit")
                            }}>Edit</button>,
            <button className="btn btn-primary btn-sm waves-effect waves-light btn btn-danger">Delete</button>
        ],
      },*/
    ],
  }


  const onChangeSearchTxt = (event) => {
    const txt = event.target.value;
    if(txt == '') {
      getPageData();
    }
    if(txt.length > 1){
      getPageData(txt);
    }
    setSearchTxt(event.target.value);
  }

  const onSaveWordListData = (event) => {
    setDataError('');

    const data = {
      id: dataId,
      name: dataName,
      serial_no: dataNumber,
      registration_no: dataRegNo,
      word_mark: dataWordMark,
      check_status: dataCheckStatus == '' ? '' : dataCheckStatus,
      //live_dead: dataLiveDead == '' ? 'Live' : dataLiveDead,
      reason: dataReason
    };
    
    if(dataMode == 'edit'){
      var error = false;

      if(removeSpaces(dataReason) == '') { 
        document.querySelector('#modal-error-reason').style.display = 'block'; 
        error = true;
      }else {document.querySelector('#modal-error-reason').style.display = 'none';}

      if(error) { return; }

      post(url.URL_WORD_LIST_EDIT, data) 
      .then(res => {
          if(res) {
            console.log(res)
            tog_standard();
            setrefreshPage(Math.random());
          }
      })
      .catch(error => {
          console.log(error)
      });
    }else if(dataMode == 'add'){
      var error = false;

      if(removeSpaces(dataName) == '') { 
        document.querySelector('#modal-error-name').style.display = 'block'; 
        error = true;
      }else {
        document.querySelector('#modal-error-name').style.display = 'none';
      }

      if(removeSpaces(dataNumber) == '') { 
        document.querySelector('#modal-error-serial').style.display = 'block'; 
        error = true;
      }else {document.querySelector('#modal-error-serial').style.display = 'none';}
      
      if(removeSpaces(dataRegNo) == '') { 
        document.querySelector('#modal-error-registration').style.display = 'block'; 
        error = true;
      }else {document.querySelector('#modal-error-registration').style.display = 'none';}
      
      if(removeSpaces(dataWordMark) == '') { 
        document.querySelector('#modal-error-wordmark').style.display = 'block'; 
        error = true;
      }else {document.querySelector('#modal-error-wordmark').style.display = 'none';}
      
      /*
      if(dataCheckStatus == '') { 
        document.querySelector('#modal-error-checkstatus').style.display = 'block'; 
        error = true;
      }else {document.querySelector('#modal-error-checkstatus').style.display = 'none';}
      */
      
      if(removeSpaces(dataReason) == '') { 
        document.querySelector('#modal-error-reason').style.display = 'block'; 
        error = true;
      }else {document.querySelector('#modal-error-reason').style.display = 'none';}

      if(error) { return; }

      post(url.URL_WORD_LIST_ADD, data) 
      .then(res => {
          if(res) {
            try{
              tog_standard();
              setrefreshPage(Math.random());
            }catch(e){}
          }
      })
      .catch(error => {
          if(error && error.response && error.response.data && error.response.data.error) {
            setDataError(error.response.data.error)
          }
      });
    }
  }


  const showDeleteAlert = async (e, id, number, wordMark, reason) => {
    setDeleteDataId(id)
    setDeleteDataNumber(number)
    setDeleteDataWordmark(wordMark)
    setDeleteDataReason(reason)
    setdelete_ModalStatus(true)
  }


  const deleteData = async (e) => {    
    const data = {
      id: deleteDataId,
      serial_no: deleteDataNumber,
      word_mark: deleteDataWordmark,
      reason: deleteDataReason
    };

    post(url.URL_WORD_LIST_DELETE, data).then(res => {
      if(res) {
        setrefreshPage(Math.random());
        setdelete_ModalStatus(false)
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  const openLink = async () => {
    console.log('link');
  }

  const getPageData = async (search='') => {
    var searchTxt = '';
    if(search != ''){
      searchTxt = '&search=' + search;
    }

    get(url.URL_WORD_LIST + "?limit=100" + searchTxt) 
    .then(res => {
        if(res) {

          res.data.records.map(d => (
            data.rows.push({
              //Id: d.id,
              Name:  d.name,
              // SerialNo: d.serial_no,
              USRegNo: [<a target="_blank" href={"http://tsdr.uspto.gov/#caseNumber=" + d.registration_no + "&caseSearchType=US_APPLICATION&caseType=SERIAL_NO&searchType=statusSearch"}>{d.registration_no}</a>],
              Wordmark: d.word_mark,
              // CheckStatus: [<a target="_blank" href={"http://tsdr.uspto.gov/#caseNumber=" + d.registration_no + "&caseSearchType=US_APPLICATION&caseType=SERIAL_NO&searchType=statusSearch"}>{d.check_status}</a>],
              //LiveDead: d.live_dead,
              Reason: d.reason,
              Actions: [
                  <button title="Edit" className="btn btn-primary btn-sm waves-effect waves-light btn btn-warning"
                                  onClick={() => {
                                    tog_standard("edit", d.id, d.name, d.serial_no, d.registration_no, d.word_mark, d.reason, d.check_status, d.live_dead)
                                  }}><i className="fas fa-edit" aria-hidden="true"></i></button>,
                  <button title="Delete" className="btn btn-primary btn-sm waves-effect waves-light btn btn-danger"
                    onClick={(e) => showDeleteAlert(e, d.id, d.registration_no, d.word_mark, d.reason)}
                  ><i className="fas fa-trash" aria-hidden="true"></i></button>
              ],
              clickEvent: () => openLink()
          })))
          setTableData(data)

        }
    })
    .catch(error => {
        console.log(error)
    });
  }


  const searchData = async (e) => {

    setDataId('');
    setDataName('');
    setDataNumber('');
    setDataCheckStatus('');
    setDataLiveDead('');
    setDataReason('');
    setDataWordMark('');

    const searchTxt = dataRegNo;
    post(url.URL_WORD_LIST_SEARCH + "?search=" + searchTxt)
    .then(res => {
        if(res) {
          const d = res.data[0];

          setDataId(d.id);
          setDataName(d.name);
          setDataNumber(d.serial_no);
          setDataRegNo(d.registration_no);
          setDataCheckStatus(d.check_status);
          setDataLiveDead(d.live_dead);
          setDataReason(d.reason);
          setDataWordMark(d.word_mark);
        }
    })
    .catch(error => {
        console.log(error)
    });
  }

  useEffect(() => {
    setTableData({});
    getPageData();
  }, [refreshPage])


  return (
      <React.Fragment>
          <div className="manageUserContainer modifyCriticalList showTableFoot">
              <Container fluid>
                  <div className="page-content">
                      <div className="container-fluid">
                      <Breadcrumbs title="Koloden" breadcrumbItem="Modify Critical List" />

                      <Row className="justify-content-between">
                        <Col className="col-md-3 col-6">
                          
                          <button
                            style={{marginBottom:"20px"}}
                            type="button" 
                            className="btn btn-primary waves-effect waves-light"
                            data-toggle="modal"
                            data-target="#myModal"
                            onClick={() => {
                              tog_standard("Add Field")
                            }}
                          >
                            Add Critical List
                          </button>
                         
                        </Col>
                        <Col className="col-md-6 col-12">
                          <Row className="mb-3 justify-content-center">
                              <label
                                htmlFor="example-text-input"
                                className="col-xlg-4 col-md-5 col-12 col-form-label"
                              >
                              Search By Number/Wordmark
                              </label>
                              <div className="col">
                                <input
                                    className="form-control"
                                    type="text" 
                                    placeholder="1198564"
                                    onChange={onChangeSearchTxt}
                                    value={searchTxt}
                                />
                              </div>
                          </Row> 
                        </Col> 

                        <Modal
                          isOpen={modal_view}
                          toggle={() => {
                            tog_standard()
                          }}
                        >
                          <div className="modal-header">
                            <h5 className="modal-title mt-0" id="modal-title">
                            {titleView}
                              </h5>
                            <button
                              type="button"
                              onClick={() => {
                                setmodal_view(false)
                              }}
                              className="close CloseDataTb"
                              style={{marginTop:"13px",marginRight:"10px"}}
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body ">
                            
                          {/*
                          <Row className="mb-3 justify-content-center">
                                <label
                                htmlFor="example-text-input"
                                className="col-md-5 col-form-label"
                                >
                                Id
                                </label>
                                <div className="col-md-5">
                                  <input
                                      className="form-control"
                                      type="number"
                                      disabled={fieldModifyStatus == "edit" ? "disabled" : ""}
                                      value={"22"}
                                  />
                                  <span id="modal-error" style={{ display: "none" }}>Error should be here!</span>
                                </div>
                            </Row>*/}
                            
                            { dataError != "" ? <>
                              <Row className="mb-3 justify-content-center">
                                <Col className="col-12 text-center bg-danger text-white p-2 mx-2">
                                  <span id="modal-error-registration" class="text-white" >{dataError}</span>
                                </Col>
                              </Row>
                              </> : <></>}
                          

                            <div></div>
                            <Row className="mb-3 justify-content-center">
                                <label
                                htmlFor="example-text-input"
                                className="col-md-5 col-form-label"
                                >
                                U.S. Reg. No.
                                </label>
                                <div className="col-md-5">
                                  <div className="wrap-btn-input">
                                        <input
                                            className="form-control pr-5"
                                            type="text"
                                            disabled={fieldModifyStatus == "edit" ? "disabled" : ""}
                                            value={dataRegNo} onChange={ (e) => { setDataRegNo(e.target.value) } }
                                        />
                                        {fieldModifyStatus == "edit" ? <></> : 
                                          // <div className="col-2 p-lg-0">
                                            <button onClick={searchData} title="Search" className="btn btn-primary btn-sm waves-effect waves-light btn btn-success"
                                            ><i className="fas fa-search" aria-hidden="true"></i></button>
                                          // </div>
                                          }
                                  </div>
                                  <span id="modal-error-registration" class="text-danger" style={{ display: "none" }}>Registration No is required!</span>
                                </div>
                            </Row> 
                            <Row className="mb-3 justify-content-center">
                                <label
                                htmlFor="example-text-input"
                                className="col-md-5 col-form-label"
                                >
                                Name
                                </label>
                                <div className="col-md-5">
                                  <input
                                      className="form-control"
                                      type="text"
                                      disabled={fieldModifyStatus == "edit" || fieldModifyStatus == "add" ? "disabled" : ""}
                                      value={dataName} onChange={ (e) => { setDataName(e.target.value) } }
                                  />
                                  <span id="modal-error-name" class="text-danger" style={{ display: "none" }}>Name is required!</span>
                                </div>
                            </Row> <Row className="mb-3 justify-content-center">
                                <label
                                htmlFor="example-text-input"
                                className="col-md-5 col-form-label"
                                >
                                Serial No
                                </label>
                                <div className="col-md-5">
                                  <input
                                      className="form-control"
                                      type="number"
                                      disabled={fieldModifyStatus == "edit" || fieldModifyStatus == "add" ? "disabled" : ""}
                                      value={dataNumber} onChange={ (e) => { setDataNumber(e.target.value) } }
                                  />
                                  <span id="modal-error-serial" class="text-danger" style={{ display: "none" }}>Serial No is required!</span>
                                </div>
                            </Row>
                            <Row className="mb-3 justify-content-center">
                                <label
                                htmlFor="example-text-input"
                                className="col-md-5 col-form-label"
                                >
                                Wordmark
                                </label>
                                <div className="col-md-5">
                                  <input
                                      className="form-control"
                                      type="text"
                                      value={dataWordMark} onChange={ (e) => { setDataWordMark(e.target.value) } }
                                      disabled={fieldModifyStatus == "edit" || fieldModifyStatus == "add" ? "disabled" : ""}
                                  />
                                  <span id="modal-error-wordmark" class="text-danger" style={{ display: "none" }}>Wordmark is required!</span>
                                </div>
                            </Row> 
                            <Row className="mb-3 justify-content-center">
                                <label
                                htmlFor="example-text-input"
                                className="col-md-5 col-form-label"
                                >
                                Check Status
                                </label>
                                <div className="col-md-5">
                                  <input
                                      className="form-control"
                                      type="text"
                                      value={dataCheckStatus} onChange={ (e) => { setDataCheckStatus(e.target.value) } }
                                      disabled={fieldModifyStatus == "edit" || fieldModifyStatus == "add" ? "disabled" : ""}
                                  />
                                  <span id="modal-error-checkstatus" class="text-danger" style={{ display: "none" }}>Check Status is required!</span>
                                </div>
                            </Row> 
                            <Row className="mb-3 justify-content-center">
                                <label
                                htmlFor="example-text-input"
                                className="col-md-5 col-form-label"
                                >
                                Live
                                </label>
                                <div className="col-md-5">
                                  <select className="form-control" 
                                      value={dataLiveDead}
                                      onChange={ (e) => { setDataLiveDead(e.target.value) }}
                                      disabled={fieldModifyStatus == "edit" || fieldModifyStatus == "add" ? "disabled" : ""}>
                                      <option value="Live">Live</option>
                                      <option value="Dead">Dead</option>
                                  </select>
                                  <span id="modal-error" class="text-danger" style={{ display: "none" }}>Live is required!</span>
                                </div>
                            </Row> 
                            <Row className="mb-3 justify-content-center">
                                <label
                                htmlFor="example-text-input"
                                className="col-md-5 col-form-label"
                                >
                                Reason
                                </label>
                                <div className="col-md-5">
                                  <select 
                                    className="show-arrow form-control"
                                    type="text" 
                                    value={dataReason} onChange={ (e) => { setDataReason(e.target.value) } }
                                  >
                                    <option value="Litigation">Litigation</option>
                                    <option value="VERO">VERO</option>
                                  </select>
                                  <span id="modal-error-reason" class="text-danger" style={{ display: "none" }}>Reason is required!</span>
                                </div>
                            </Row> 

                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              onClick={() => {
                                tog_standard()
                              }}
                              className="btn btn-secondary waves-effect"
                              data-dismiss="modal"
                            >
                              Cancel
                              </button>
                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light"
                              onClick={onSaveWordListData}
                            >
                              Save
                              </button>
                          </div>
                        </Modal>


                        <Modal
                          isOpen={delete_ModalStatus}
                        >
                          <div className="modal-header">
                            <h5 className="modal-title mt-0" id="modal-title">
                              Delete Critical List
                              </h5>  
                          </div>
                          <div className="modal-body ">
                            
                            <Row className="mb-3 justify-content-center">
                                <label
                                  htmlFor="example-text-input"
                                  className="col-md-12 col-form-label"
                                >
                                Are you sure, you want to delete the critical list item? {deleteDataNumber}
                                </label>
                            </Row> 

                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              onClick={() => {
                                setdelete_ModalStatus(false);
                              }}
                              className="btn btn-secondary waves-effect"
                              data-dismiss="modal"
                            >
                              Cancel
                              </button>
                            <button
                              type="button"
                              className="btn btn-danger waves-effect waves-light"
                              onClick={deleteData}
                            >
                              Yes
                              </button>
                          </div>
                        </Modal>

                          {loading == true ? <>
                              <Col className="col-12">
                                <div className="loadingData">Loading table...</div>
                              </Col>
                            </> : <></>
                          }


                            <Col className="col-12 datatb-prod">
                              <Card>
                                  <CardBody>
                                  <MDBDataTable responsive bordered data={tableData}/>
                                  </CardBody>
                              </Card>
                            </Col>
                      </Row>
                      </div>
                  </div>

              </Container>
          </div>
      </React.Fragment>
  )
}

export default UserManage
