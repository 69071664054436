import React from 'react'
import $ from 'jquery';
import Help from './layout/Help'
import Header from './layout/Header'
import Footer from './layout/Footer'
import {Link} from 'react-router-dom'

import '../../kolodenTheme/css/bootstrap.min.css'
import '../../kolodenTheme/css/fonts.css'
import '../../kolodenTheme/css/all.min.css'
import '../../kolodenTheme/css/style.css'


const Key = () => {
    $(function() {
        $('.head-tags ul li').each(function(){
            $(this).on('click', function(){
                var dataTg = $(this).data('tg');
                if($(this).hasClass('active')){
                }
                else {
                    $('.head-tags ul li').each(function(){$(this).removeClass('active')})
                    $(this).addClass('active');
                }

                if(dataTg == '1'){
                    $('.head-tags-container .cont-tab').each(function(){$(this).hide()})
                    $('.head-tags-container .cont-tab[data-cont="1"]').show()
                }
                if(dataTg == '2'){
                    $('.head-tags-container .cont-tab').each(function(){$(this).hide()})
                    $('.head-tags-container .cont-tab[data-cont="2"]').show()
                }
                if(dataTg == '3'){
                    $('.head-tags-container .cont-tab').each(function(){$(this).hide()})
                    $('.head-tags-container .cont-tab[data-cont="3"]').show()
                }
                if(dataTg == '4'){
                    $('.head-tags-container .cont-tab').each(function(){$(this).hide()})
                    $('.head-tags-container .cont-tab[data-cont="4"]').show()
                }
            })
        })
    })

    return(
    <div className="koloden-main">
        
        <Help />
        <Header />
        
        <div className="section-info keys-info">
            <div className="container">
                <div className="row justify-content-center">
                    <h1 className="title">Instructions for Integrating Your Stores Into <br /> Koloden</h1>
                        <h2 className="title">
                            Select the appropriate tab for each marketplace
                        </h2>
                        <p className="ebay-para">
                            eBay®, Wish®, Amazon®, and Walmart® marks are registered servicemarks of those respective companies and are not<br /> associated with Koloden.
                        </p>
                </div>
            </div>
        </div>
        <div className="head-tags">
            <div className="container">
                <div className="row justify-content-center">
                    <ul>
                        <li className="active" data-tg="1">Wish</li>
                        <li data-tg="2">Ebay</li>
                        <li data-tg="3">Amazon</li>
                        <li data-tg="4">Walmart</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="head-tags-container">
            <div className="container cont-tab" data-cont="1" style={{display: "block"}}>
                <div className="row justify-content-center">
                    <div className="col-sm-3">
                        <img width="100%" src="kolodenTheme/img/koloden-merchant-wish-b.png" alt="" />
                    </div>
                </div>
                <div className="row justify-content-center wish-container">
                    <div className="col-sm-12">
                        <h1>Step 1: Setup your Wish API credentials.</h1>
                        <div className="details">
                            <ul>
                                <li>After logging in your Wish account (<a href="https://merchant.wish.com">https://merchant.wish.com</a>) click the <b>Settings</b> sub menu specified in Account menu at top right.</li>
                                <li>In the settings page displayed, click <b>API Settings</b> in the left navigation pane.</li>
                                <li>There you can enter your <b>App Name</b> (e.g., “Koloden”) and specify <b>Redirect URI</b> as <b>“https://koloden.com/frontend/web/site/app”</b> and click <b>Create App</b> button.</li>
                                <li>In the popup displayed click <b>Confirm</b> button.  A page will be displayed where you can view your App Name, Client Id, Client Secret and Redirect URI.</li>
                            </ul>
                            <div className="img-detail">
                                <img width="60%" src="kolodenTheme/img/wish-1.png" alt="" />
                            </div>
                            <ul>
                                <li>Please jot down the Client ID and Client Secret for future reference.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <h1>Step 2: Connect your Wish store to your Koloden account.</h1>
                        <div className="details">
                            <ul>
                                <li>If you already have an account in Koloden please login, if not register using the URL ( <a href="https://koloden.com/register">https://koloden.com/register</a> ).</li>
                                <li>After successful login, in My Account page (<a href="https://koloden.com/register">https://koloden.com/register</a>) click <strong>Platforms</strong> link specified in left nav.</li>
                                <li>In the page displayed click the <strong>Add Store</strong> button corresponding to Wish.com.</li>
                            </ul>
                            <div className="img-detail">
                                <img width="100%" src="kolodenTheme/img/img.png" alt="" />
                            </div>
                            <ul>
                                <li>In the page displayed enter the Store name, Client id and Secret key (which is displayed in your wish.com account <strong>API Settings</strong> page) and click <strong>Submit</strong> button.</li>
                                <li>A Wish.com page will be displayed requesting for permission.&nbsp;Click <strong>Confirm</strong> button on the page and on the confirm popup displayed.</li>
                                <li>Within ~24 hours, Koloden will integrate your Wish store and will start scanning your Wish store product listings on a daily basis.</li>
                                <li>Repeat this process for additional Wish storefronts.</li>
                            </ul>
                            <div className="img-detail">
                                <iframe src="https://play.vidyard.com/WrHYbCtC346ZQQSNSTbYCB?disable_popouts=1&v=4.2.30&type=inline" frameborder="0"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container cont-tab" data-cont="2" style={{display: "none"}}>
                <div className="row justify-content-center">
                    <div className="col-sm-3">
                        <img width="100%" src="kolodenTheme/img/koloden-merchant-ebay-b.png" alt="" />
                    </div>
                </div>
                
                <div className="row justify-content-center ebay-container">
                    <div className="col-sm-12">
                        <h1>Step 1: Obtain eBay.com Seller ID.</h1>
                        <div className="details">
                            <ul>
                                <li>When logged into your eBay Seller account, you can see your Seller ID as in the image below:</li>
                            </ul>
                            <div className="img-detail">
                                <img width="70%" src="kolodenTheme/img/image55.png" alt="" />
                            </div>
                            <ul>
                                <li>Please note down the Seller ID for future reference.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <h1>Step 2: Connect your eBay store to your Koloden account.</h1>
                        <div className="details">
                            <ul>
                                <li>If you already have an account in Koloden please login, if not register using the URL ( <a href="https://koloden.com/register">https://koloden.com/register</a> ).</li>
                                <li>After successful login, in My Account page (<a href="https://koloden.com/register">https://koloden.com/register</a>) click <strong>Platforms</strong> link specified in left nav.</li>
                                <li>In the page displayed click the <strong>Add Store</strong> button corresponding to Wish.com.</li>
                            </ul>
                            <div className="img-detail">
                                <img width="100%" src="kolodenTheme/img/img (1).png" alt="" />
                            </div>
                            <ul>
                                <li>In the popup, enter the Store name, Seller ID (which you obtained in Step 1) and click <strong>Submit</strong> button.</li>
                                <li>You will be redirected to your Koloden dashboard after successful submission.</li>
                                <li>Within ~24 hours, Koloden will integrate your eBay store and will start scanning your eBay store product listings on a daily basis.</li>
                                <li>Repeat this process for additional eBay storefronts.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container cont-tab" data-cont="3" style={{display: "none"}}>
                <div className="row justify-content-center">
                    <div className="col-sm-3">
                        <img width="100%" src="kolodenTheme/img/koloden-merchant-amazon-b.png" alt="" />
                    </div>
                </div>
                
                <div className="row justify-content-center">
                    <div className="col-sm-12">
                        <h1>Step 1: Setup a seller account in amazon.com</h1>
                        <div className="details">
                            <ul>
                                <li>Create a Seller account in sellercentral.amazon.com.</li>
                                <li>Login and go to the link – https://sellercentral.amazon.com/mws/register/developer-info.</li>
                                <li>Apply for MWS access and get approval from Amazon.</li>
                                <li>Now to add products, click <strong>Inventory</strong>&nbsp;and select <b>Manage Inventory</b>.</li>
                                <li>Click on <strong>Add a Product</strong> button and you can add products from here.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <h1>Step 2: Connect Amazon.com to Koloden.</h1>
                        <div className="details">
                            <ul>
                                <li>If you already have an account in Koloden please login, if not register using the URL ( <a href="https://koloden.com/register">https://koloden.com/register</a> ).</li>
                                <li>After successful login, in My Account page (<a href="https://koloden.com/dashboard">https://koloden.com/dashboard</a>) click <strong>Platforms</strong> link specified in left nav.</li>
                                <li>In the page displayed click the <strong>Add Store</strong> button corresponding to Amazon.com.</li>
                            </ul>
                            <div className="img-detail">
                                <img width="100%" src="kolodenTheme/img/img.png" alt="" />
                            </div>
                            <ul>
                                <li>In the page displayed enter the details below and click <strong>Submit</strong> button.</li>
                                <li>Store name – Enter a name for your store.</li>
                                <li>Merchant Token – In Amazon Seller Central, go to settings -&gt; Account Info page.&nbsp; Then click on <b>Merchant Token</b> link under <b>Business Information</b> section. You can find Merchant Token from this page.</li>
                                <li>MWS Auth Token – In Amazon Seller Central, go to settings -&gt; User Permissions.&nbsp; Click on <b>View manage Your Apps</b> button under <b>Third party developer and apps</b> title.&nbsp; In this page click on <b>Authorize new developer</b> button. In the showing page enter <b>Koloden</b> as Developer’s Name and <b>891719845544</b> as Developer ID and click Next button. Accept the checkbox and click Next.&nbsp; In the next page you can see the <b>MWS Auth Token.</b></li>
                            </ul>
                            <div className="img-detail">
                                <img width="100%" src="kolodenTheme/img/Picture2.png" alt="" />
                            </div>
                            <ul>
                                <li>Marketplace – Select your area of sales (e.g., U.S.).</li>
                                <li>You will be redirected to the Koloden dashboard after successful submission.</li>
                                <li>Within ~24 hours your Amazon store will be integrated with your Koloden account.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container cont-tab" data-cont="4" style={{display: "none"}}>
                <div className="row justify-content-center">
                    <div className="col-sm-3">
                        <img width="100%" src="kolodenTheme/img/logo-walmart_rs.png" alt="" />
                    </div>
                </div>
                
                <div className="row justify-content-center">
                    <div className="col-sm-12">
                        <h1>Step 1 : Setup your Walmart API credentials</h1>
                        <div className="details">
                            <ul>
                                <li>Login to your walmart seller account at&nbsp;https://seller.walmart.com/login</li>
                                <li>From the Settings menu click on <strong>Consumer IDs &amp; Private Keys</strong> under API section.</li>
                                <li>Click on <strong>Walmart Developer Portal</strong> button in the api page</li>
                                <li>Login to the developer portal</li>
                                <li>Copy the Client ID and Client Secret</li>
                            </ul>
                            <div className="img-detail">
                                <img width="100%" src="kolodenTheme/img/wallmart-devportal_hiden.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div     className="col-sm-12">
                        <h1>Step 2 : Connect Walmart.com to Koloden.</h1>
                        <div className="details">
                            <ul>
                                <li>if you already have an account in Koloden please login, if not register using the URL (&nbsp;<a href="https://koloden.com/register">https://koloden.com/register</a>&nbsp;).</li>
                                <li>After successful login, in My Account page (<a href="https://koloden.com/dashboard">https://koloden.com/dashboard</a>) click&nbsp;<strong>Platforms</strong>&nbsp;link specified in left nav.</li>
                                <li>In the page displayed click the&nbsp;<strong>Add Store</strong>&nbsp;button corresponding to Walmart.com.</li>
                            </ul>
                            <div className="img-detail">
                                <img width="100%" src="kolodenTheme/img/walmart_menu.png" alt="" />
                            </div>
                            <ul>
                                <li>In the page displayed enter the details below and click&nbsp;<strong>Submit</strong>&nbsp;button.</li>
                                <li>Store name – Enter a name for your store.</li>
                                <li>Client ID – The Client ID taken from walmart development portal</li>
                                <li>Client Secret- The Client Secret taken from walmart development portal</li>
                                <li>You will be redirected to the Koloden dashboard after successful submission.</li>
                                <li>Within ~24 hours your Walmart store will be integrated with your Koloden account.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

                <br />
                <br />
            </div>
        <div className="section-info dark">
            <div className="container">
                <div className="row">
                    <h1 className="title">Still have questions?</h1>
                    <p>Let us answer them!</p>
                    <div className="get-started-btn">
                        <Link to="/contact-us">
                            <button>CONTACT US</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        
        <Footer />
    </div>
    )
};

export default Key;