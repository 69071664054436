import React, {useEffect, useState} from 'react'
import Help from './layout/Help'
import Header from './layout/Header'
import Footer from './layout/Footer'
import '../../kolodenTheme/css/bootstrap.min.css'
import '../../kolodenTheme/css/fonts.css'
import '../../kolodenTheme/css/all.min.css'
import '../../kolodenTheme/css/style.css'
import '../../kolodenTheme/css/blog.css'


const PaymentConfirmation = () => {

    return (
        <div className="koloden-main">
            <Help/>
            <Header/>
            <div className="faq-container">
                <div className="container">
                    <div id='action-item'>
                        <h1>Thank You</h1>
                        <p>Your payment has been received. A confirmation email will be sent shortly to your email address on file.</p>
                        <p>Thank you</p>
                    </div>
                </div>
                <div style={{minHeight: "180px"}}></div>
            </div>
            <Footer/>
        </div>
    );
};

export default PaymentConfirmation;