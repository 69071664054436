import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postProfile
} from "../../../helpers/backend_helper"

const fireBaseBackend = getFirebaseBackend()

function* editProfile({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      console.log(1);
      const response = yield call(postProfile, user)
      localStorage.removeItem("authUser")
      console.log(2);
      localStorage.setItem("authUser", JSON.stringify(response))
      console.log(JSON.stringify(response));
      yield put(profileSuccess(response))
    } 
  } catch (error) {
    yield put(profileError(error))
    console.log(JSON.stringify(error));
  }
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
