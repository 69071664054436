import React from 'react';

const Pagination = ({ postsPerPage, totalPosts, paginate, page }) => {
    var pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    const count = pageNumbers.pop();
    pageNumbers.push(count);

    if(count > 9) {
        pageNumbers = [];
        switch (true){
            case page<7:
                for (let i = 2; i <= Math.ceil(9); i++) {
                    pageNumbers.push(i);
                }
                break;
            case page>7:
                var last_page = page+3;
                if(last_page > (count-1)) {
                    last_page = count-1;
                }
                for (let i = Math.ceil(page-3); i <= Math.ceil(last_page); i++) {
                    pageNumbers.push(i);
                }
                break;
        }
    }

    if(typeof count == "undefined") {
        return (
            <p className="noRecords">No matching records found.</p>
        );
    } else if(count == 1) {
        return ('');
    } else if(count > 1 && count < 10) {
        return (
            <nav>
                <ul className='pagination'>
                    {pageNumbers.map(number => (
                        <li key={number} className={"page-item " + (number == page ? 'active' : '')}>
                            <button onClick={() => paginate(number)} className='page-link'>
                                {number}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>
        );
    } else {
        return (
            <nav>
                <ul className='pagination'>
                    <li key={1} className='page-item'>
                        <button onClick={() => paginate(1)} className='page-link'>
                            FIRST
                        </button>
                    </li>
                    {pageNumbers.map(number => (
                        <li key={number} className='page-item'>
                            <button onClick={() => paginate(number)}  className={"page-link " + (number == page ? 'active' : '')}>
                                {number}
                            </button>
                        </li>
                    ))}
                    <li key={pageNumbers.pop()} className='page-item'>
                        <button onClick={() => paginate(pageNumbers.pop())} className='page-link'>
                            LAST
                        </button>
                    </li>
                </ul>
            </nav>
        );
    }
};

export default Pagination;
