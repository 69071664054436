import React, { useState } from "react"
import { AvForm, AvField } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"

import {
  Card,
  CardBody,
  Col,
  Row,
  Alert,
  CardTitle,
  Form,
  Container,
  Label,
  Input,
  FormGroup,
  Button
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import hidePswd from "../../assets/icon-pswd/show.png"
import showPswd from "../../assets/icon-pswd/hidden.png"

// Form Mask
import InputMask from "react-input-mask"


const EditAccount = props => {
    const [customchk, setcustomchk] = useState(true)
    const [toggleSwitch, settoggleSwitch] = useState(true)
    const [toggleSwitchSize, settoggleSwitchSize] = useState(true)
    const userObj = JSON.parse(localStorage.getItem("authUser"))
    
    var newUserStorageData = JSON.parse(localStorage.getItem("updatedUserAcc")); 

    const [successMessage, setSuccessMessage] = useState('');
    const [errMessage, setErrMessage] = useState('');

    const handleValidSubmit = (event, values) => { 

      setSuccessMessage('')
      setErrMessage('')

      const postProfile = post(url.POST_EDIT_PROFILE, values)
      .then(response => {
        if(response == 0) {
          setErrMessage('Your current password is wrong')
          return response
        }
        
        try{
          const updateState = {
            "first_name":values.first_name,
            "last_name":values.last_name,
            "display_name":values.name,
            "phone_number":values.phone,
          }; 
          localStorage.removeItem("updatedUserAcc");
          localStorage.setItem('updatedUserAcc',JSON.stringify(updateState));
          newUserStorageData = JSON.parse(localStorage.getItem("updatedUserAcc")); 
        }catch(err){
          console.log("Error is in the User Edit Account settings for localstorage variable => "+err)
        };

        window.scrollTo({ top: 0, behavior: 'smooth' });
        setSuccessMessage('Your Account Information has been updated successfully.')
        return response.data
      })
      .catch(error => {
        let errorText = '';
        for (var x in error.response.data.errors) {
          errorText += error.response.data.errors[x] + '</br>'
        } 
        setErrMessage(errorText)
      });
    }
    const showPass = (e) => {
    
      let target = e.target.parentNode;
      let targetParent = target.parentNode;
      let inputField = targetParent.querySelector("input");
      if (inputField.type === "password") {
        inputField.type = "text";
        e.target.src=hidePswd;
      } else {
        inputField.type = "password";
        e.target.src=showPswd;
      }
      console.log(inputField);
    }
    
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Accounts" breadcrumbItem="Edit Account" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={6}>
                        <div className="mt-4">
                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => {handleValidSubmit(e, v)}} >

                          {successMessage  && typeof successMessage === "string"? (
                            <Alert color="success">
                            {successMessage}
                            </Alert>
                          ) : null}

                            {errMessage ? (
                                <div className="alert alert-danger fade show">
                                  <div dangerouslySetInnerHTML={{__html: errMessage}} />
                                </div>
                              ) : null}

                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <AvField name="first_name" label="First name *" type="text" 
                                    value={ newUserStorageData?.first_name ? newUserStorageData?.first_name : userObj.first_name } 
                                    validate={{
                                      required: {value: true, errorMessage: "Please enter First name"},
                                      minLength: {value: 1, errorMessage: 'First name must be greater then 3 characters'},
                                    }}/>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <AvField name="last_name" label="Last name *" type="text"
                                    value={ newUserStorageData?.last_name ? newUserStorageData?.last_name : userObj.last_name } 
                                    validate={{
                                      required: {value: true, errorMessage: "Please Enter Last Name"},
                                    }} />
                                </div>
                              </Col>
                            </Row>
                            
                            <div className="mb-3">
                              <AvField name="name" label="Display Name*" type="text" 
                                value={ newUserStorageData?.display_name ? newUserStorageData?.display_name : userObj.name } 
                                validate={{
                                      required: {value: true, errorMessage: "Please Enter Display Name"},
                                      minLength: {value: 1, errorMessage: 'Display name must be greater then 3 characters'},
                                  }}/>
                            </div>

                            <div className="mb-3">
                              <AvField /* mask="(999) 999-9999" tag={[Input, InputMask]}*/ name="phone" label="Phone" type="text" 
                                value={ newUserStorageData?.phone_number ? newUserStorageData?.phone_number : userObj.phone }  
                                validate={{
                                    // required: {value: true, errorMessage: "Please Enter Phone Number"},
                                  }}/>
                            </div>

                            <div className="mb-3">
                              <AvField disabled id="email" name="email" value={userObj.email} label="Email address *" className="form-control" type="email" validate={{
                                    required: {value: true, errorMessage: "Please Enter Email Address"},
                                    pattern: {value: '/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/', errorMessage: 'Invalid Email Address'},
                                  }} />
                            </div>
                            <div className="mb-3">
                              <AvField disabled name="promo_code" label="Referral code" type="text" value={userObj.promo_code} validate={{
                                    required: {value: false, errorMessage: "Please Enter Referral code"},
                                  }} />
                            </div>

                            <div className="mb-3">
                              <AvField disabled name="promo_code2" label="Promo code" type="text" value={userObj.promo_code2} validate={{
                                    required: {value: false, errorMessage: "Please Enter Promo code"},
                                  }} />
                            </div>

                            <h1 style={{"fontSize":"15px"}}>Password change</h1>
                            <div className="mb-3 eye-pswd">
                                <AvField name="current_password" label="Current Password (leave blank to leave unchanged)" autoComplete="new-password" type="password"/>
                                <div className="eye-icon">
                                  <img src={showPswd} onClick={(e)=>{ showPass(e) }}></img>
                                </div>
                            </div>
                            <div className="mb-3 eye-pswd">
                                <AvField name="new_password" label="New Password (leave blank to leave unchanged)" autoComplete="new-password" type="password" validate={{
                                    minLength: {value: 6, errorMessage: 'New Password must be greater then 6 characters'},
                                  }}/>
                                <div className="eye-icon">
                                  <img src={showPswd} onClick={(e)=>{ showPass(e) }}></img>
                                </div>
                            </div>
                            <div className="mb-3 eye-pswd">
                                <AvField name="password_confirm" label="Confirm New Password" type="password" autoComplete="new-password" validate={{
                                    minLength: {value: 6, errorMessage: 'Confirm New Password must be greater then 6 characters'},
                                    match:{value:'new_password', errorMessage: "New Password and Confirm New Password Does Not Match"}
                                  }}/>
                                <div className="eye-icon">
                                  <img src={showPswd} onClick={(e)=>{ showPass(e) }}></img>
                                </div>
                            </div>
                            <div className="mt-4">
                              <Button type="submit" color="primary" className="w-md">Save Changes</Button>
                            </div>
                          </AvForm>
                        </div>
                      </Col>
                      
                    </Row>
                    </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }

export default EditAccount