import React, {useEffect, useState} from 'react'
import Help from '../Site/layout/Help'
import Header from '../Site/layout/Header'
import Footer from '../Site/layout/Footer'
import '../../kolodenTheme/css/bootstrap.min.css'
import '../../kolodenTheme/css/fonts.css'
import '../../kolodenTheme/css/all.min.css'
import '../../kolodenTheme/css/style.css'
import '../../kolodenTheme/css/blog.css'


const StoreAuthorizationSuccess = () => {

    return (
        <div className="koloden-main">
            <Help/>
            <Header/>
            <div className="faq-container">
                <div className="container">
                    <div id='action-item' style={{marginBottom: '200px'}}>
                        <h3>Thank you for your seller account authorization with us.</h3>
                        <p>To view your registered store, please <a href="/login">login</a> to your account and go to Platforms {'->'} Amazon</p>
                    </div>
                   <br></br><br></br>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default StoreAuthorizationSuccess;