import React from 'react'
import Help from './layout/Help'
import Header from './layout/Header'
import Footer from './layout/Footer'
import {Link} from 'react-router-dom'

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"

import '../../kolodenTheme/css/bootstrap.min.css'
import '../../kolodenTheme/css/fonts.css'
import '../../kolodenTheme/css/all.min.css'
import '../../kolodenTheme/css/style.css'
import MetaTags from 'react-meta-tags';
import $ from 'jquery'

const AboutUs = () => {

    const showPageData = false;

    $(function() {
        var pageID = 3;
        function loadPageData(){
            get(url.CMS_CONTENT + "?page=" + pageID) 
            .then(res => {
                if(res) {
                    res.forEach(element => {
                        var page = pageID;
                        var section = element.section;
                        var column = element.column;
                        if(element.content !== undefined){
                            if(element.type == 'image') {
                                $("[data-set='"+ page + section + column +"']").attr('src', "https://koloden.com/v2api/public/cms/images/" + element.content );
                            } else if(element.type == 'text') {
                                $("[data-set='"+ page + section + column +"']").html(element.content);
                            }
                        }
                    })
                }
            })
            .catch(error => {
                console.log(error)
            });
        }

        loadPageData();
    }) 

    return(
            <div className="koloden-main">
            <MetaTags>
                <title>About Koloden's Intellectual Property Management Tools</title>
                <meta name="description" content="Koloden is an intellectual property management software for resellers, created by IP attorneys who are involved in IP enforcement actions in federal courts." />
                <meta property="og:title" content="About Koloden's Intellectual Property Management Tools" />
            </MetaTags>
            <Help />
            <Header />
                <div className="section-info contrast pricing">
                    <div className="container">
                        <div className="row justify-content-center">
                            <h1 className="title" data-set="311">{ showPageData ? 'Make your business simpler with us' : '' }</h1>
                                <p className="plantext" style={{maxWidth: "790px"}} >
                                    <span className="green" data-set="312">{ showPageData ? 'Koloden' : '' }</span> <span data-set="313" className="secondary font-semi-light">{ showPageData ? 'was conceived by intellectual property attorneys who are actively<br /> involved in IP enforcement actions in federal courts across the country.' : '' }</span>
                                </p>
                        </div>
                    </div>
                </div>
                <div className="section-info blog-sect about-us-blog">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-sm-6 text-center">
                                <div className="img-box pd-0">
                                    <img data-set="321" src="{ showPageData ? 'kolodenTheme/img/pexels-andrea-piacquadio-3758105.jpg' : '' }" width="300px" alt="" />
                                </div>
                            </div>
                            <div className="col-sm-6 ml-auto">
                                <h2 className="title-v2"><span data-set="322"  className="secondary">{ showPageData ? 'What is' : '' }</span> <span data-set="323">{ showPageData ? 'Koloden' : '' } </span></h2>
                                <p data-set="324">
                                    { showPageData ? 'Brand owners use visual search and related technologies to scrape product listings looking for counterfeit products.  We thought: why not let the merchants use the same technologies on their listings to identify problems before the brand owners find out?  A win-win scenario for merchant resellers and brands, but perhaps not so much for the lawyers (sorry!).' : '' }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-info dark">
                    <div className="container">
                        <div className="row">
                            <h2 className="title-v2" data-set="331">{ showPageData ? 'Ready to get started?' : '' }</h2>
                            <div className="get-started-btn">
                                <Link to="/register">
                                    <button>SIGN UP NOW</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                
            <Footer />
            </div>
    );
};

export default AboutUs;
