import React from 'react'
import Help from './layout/Help'
import Header from './layout/Header'
import Footer from './layout/Footer'
import {Link} from 'react-router-dom'
import { Form, form } from 'reactstrap'
import { useState, useEffect, useRef } from 'react'
import * as url_helper from "../../helpers/url_helper"

import axios from 'axios';

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import $ from 'jquery'

import '../../kolodenTheme/css/bootstrap.min.css'
import '../../kolodenTheme/css/fonts.css'
import '../../kolodenTheme/css/all.min.css'
import '../../kolodenTheme/css/style.css'
import emailIcon from '../../assets/images/email.png';
import phoneIcon from '../../assets/images/phone.png';
import addressIcon from '../../assets/images/address.png';
import MetaTags from 'react-meta-tags';
const Contact = () => {

    const showPageData = false;
    const [errorMsg, setErrorMsg] = useState(false);
    const [state, setState] = useState({
      fullName: "",
      email: "",
      phoneNumber: "",
      phoneNumberWithMasking: "",
      subject: "",
      message: "",
    });

    
    function showMapLoc(){
        let url = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2970.378639508797!2d-87.64454634928703!3d41.88471337287784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2cc61698fc6f%3A0x1649c661252dfc7a!2sAU%2C%20LLC!5e0!3m2!1sen!2sin!4v1568805513292!5m2!1sen!2sin';
        window.open(url, '_blank').focus();
    };
    
    useEffect(() => {
        $(function() {
            var pageID = 7;
            function loadPageData(){
                get(url.CMS_CONTENT + "?page=" + pageID) 
                .then(res => {
                    if(res) {
                        res.forEach(element => {
                            var page = pageID;
                            var section = element.section;
                            var column = element.column;
                            if(element.content !== undefined){
                                if(element.type == 'image') {
                                    $("[data-set='"+ page + section + column +"']").attr('src', "https://koloden.com/v2api/public/cms/images/" + element.content );
                                } else if(element.type == 'text') {
                                    $("[data-set='"+ page + section + column +"']").html(element.content);
                                }
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log(error)
                });
            }
    
            loadPageData();
        }) 
    },[0]) 
    

    const [fullNameErr, setFullNameErr] = useState('')
    const [subjectErr, setSubjectErr] = useState('')
    const [emailErr, setEmailErr] = useState('')
    const [phoneNumberErr, setPhoneNumberErr] = useState('')
    const [messageErr, setMessageErr] = useState('')
    const [loader, setLoader] = useState(false)

    function handleChange(e) {
        const copy = { ...state }
        copy[e.target.name] = e.target.value
        setState(copy)
    }

    
    const inputRefFullName = useRef(null);
    const inputRefSubject = useRef(null);
    const inputRefEmail = useRef(null);
    const inputRefPhoneNumber = useRef(null);
    const inputRefMessage = useRef(null);

    async function handleSubmit(event) {
        
        event.preventDefault()

        let flag = true;

        console.log(state);
        const toCheckFor = ["@", "."]
        
        if (state?.fullName.trim() == '') {
            setFullNameErr('Full Name is required')
            inputRefFullName.current.focus();
            flag = false; 
        }

        if (state?.email == '') { 
            setEmailErr('Email Address is required')
            inputRefEmail.current.focus();
            flag = false;
        }

        if (state?.phoneNumber != '' && state?.phoneNumber.length < 10) {
            setPhoneNumberErr('Please enter a valid phone number')
            inputRefPhoneNumber.current.focus();
            flag = false;
        }

        if (state?.subject.trim() == '') { 
            setSubjectErr('Subject is required')
            inputRefSubject.current.focus();
            flag = false;
        }

        if (state?.message == '') {
            setMessageErr('Message is required')
            inputRefMessage.current.focus();
            flag = false;
        }

        if (flag) {

            setLoader(true);
            const axios = require('axios');

            let data = JSON.stringify({
                "email": state.email,
                "message": state.message,
                "name": state.fullName,
                "phone": state.phoneNumberWithMasking,
                "subject": state.subject
            }); 

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: url_helper.POST_CONTACT_US,
                headers: { 
                    'Content-Type': 'application/json'
                },
                data : data
            };

            axios.request(config)
                .then((response) => {
                    setErrorMsg(true);
                    
                    console.log(JSON.stringify(response.data));

                    setState({
                        fullName: "",
                        email: "",
                        phoneNumber: "",
                        phoneNumberWithMasking: "",
                        subject: "",
                        message: "",
                    })

                    setTimeout(()=>{
                        setErrorMsg(false);
                    }, 3000);
                    setLoader(false);
                })
                .catch((error) => {
                    setLoader(false);
                    console.log(error);
                });
                
        }
    }

    

    // Function to remove the Masking from phone Number
    const NormalizePhoneNumber = (phone) => {
        
        const copy = { ...state }

        const numericOnly = phone.replace(/\D/g, '');
        let maskedNumber = '';
        if (numericOnly.length > 0) {
        maskedNumber = `(${numericOnly.slice(0, 3)}`;
        }
        if (numericOnly.length > 3) {
        maskedNumber += `) ${numericOnly.slice(3, 6)}`;
        }
        if (numericOnly.length > 6) {
        maskedNumber += `-${numericOnly.slice(6, 10)}`;
        }

        
        copy['phoneNumber'] = phone;
        copy['phoneNumberWithMasking'] = maskedNumber;
        setState(copy)

    }

    return(
        <div className="koloden-main">
            <MetaTags>
                <title>Contact Us | Koloden</title>
                <meta name="description" content="Contact Us | Koloden" />
                <meta property="og:title" content="Contact Us | Koloden" />
            </MetaTags>
            <Help />
            <Header />
        <div className="help">
            <i className="fa fa-question-circle" aria-hidden="true"></i>
            <span>Help</span>
        </div>
        <div className="section-info contrast pricing privacy">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="">
                        <h1 className="title">CONTACT US</h1>
                    </div>
                </div>
            </div>
        </div>
        <div className="section-info blog-sect content contact-us">
            <div className="container">
                <section className="contact-page">
                    <div className="container">
                        <div className='row' >
                            <div className="mb-3 col-lg-4 col-md-12">
                                <div className="d-flex row justify-content-center">
                                    <div className="col-md-6 col-lg-12">
                                        <div className="left-contact">
                                            <div className="icon ">
                                                <img src={emailIcon}></img>
                                            </div>
                                            <div className="contact-box">
                                                <h4 data-set="717">{ showPageData ? 'Email' : '' }</h4>
                                                <a href="mailto:info@koloden.com" data-set="718">{ showPageData ? 'info@koloden.com' : '' }</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='col-md-6 col-lg-12'>
                                        <div className="left-contact">
                                            <div className="icon"> 
                                                <img src={phoneIcon}></img>
                                            </div>
                                            <div className="contact-box">
                                                <h4>Phone</h4>
                                                <a href="tel:+1-414-751-0987">(414) 751-0987</a>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className='col-md-6 col-lg-12'>
                                        <div className="left-contact">
                                            <div className="icon"> 
                                                <img src={addressIcon}></img>
                                            </div>
                                            <div className="contact-box onHoverClick" onClick={()=>{showMapLoc()}}>
                                                <h4 data-set="711">{ showPageData ? 'Address' : '' }</h4>
                                                <span data-set="712">{ showPageData ? 'AU LLC Koloden' : '' }</span><br></br>
                                                <span data-set="713">{ showPageData ? '564 W. Randolph St. 2nd Floor' : '' }</span> <br></br>
                                                <span data-set="714">{ showPageData ? 'Chicago, IL 60661' : '' }</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 px-5 mx-auto">
                                <div className="left-contact2">
                                    <h3 className="text-primary mb-2">We’re here to help.</h3>
                                    <p className="text-muted">Fill out the form below to get in touch with our support team.</p>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            {/* {successMessage != "" ? <Alert variant="success">{successMessage} </Alert> : ""}
                                            {queryErr != "" ? <Alert variant="danger">{queryErr} </Alert> : ""} */}
                                        </div>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <p className="require_span mb-4">Fields marked with an * are required</p>
                                        {errorMsg ? <span className="text-success mb-4">Your form has been submitted successfully.</span> : ""}
                                        <div className='row'>
                                            <div className='col-md-6 mb-3'>
                                                <input
                                                    className='form-control'
                                                    type="text"
                                                    name="fullName"
                                                    placeholder="Full Name *"
                                                    id="full_name"
                                                    ref={inputRefFullName}
                                                    value={state?.fullName}
                                                    onChange={(e) => { handleChange(e); setFullNameErr(''); }}
                                                ></input>
                                                {fullNameErr ? <span className="text-danger">{fullNameErr}</span> : ""}
                                            </div>
                                            <div className='col-md-6 mb-3'>
                                                <input
                                                    className='form-control'
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email *"
                                                    value={state?.email}
                                                    ref={inputRefEmail}
                                                    formNoValidate
                                                    id="email_address"
                                                    onChange={(e) => { handleChange(e); setEmailErr(''); }}

                                                ></input>
                                                {emailErr ? <span className="text-danger">{emailErr}</span> : ""}
                                            </div>
                                            <div className='col-md-6 mb-3'>
                                                <input
                                                    className='form-control'
                                                    type="text"
                                                    name="phoneNumber"
                                                    id="phone_number"
                                                    ref={inputRefPhoneNumber}
                                                    placeholder="Phone # (123) 234-3453"
                                                    onChange={(e) => { NormalizePhoneNumber(e.target.value.replace(/\D/g, '')); setPhoneNumberErr("") }}
                                                    value={state?.phoneNumberWithMasking}
                                                    >
                                                </input>
                                                {phoneNumberErr ? <span className="text-danger">{phoneNumberErr}</span> : ""}
                                            </div>
                                            <div className='col-md-6 mb-3'>
                                                <input
                                                    className='form-control'
                                                    type="text"
                                                    name="subject"
                                                    id="subject"
                                                    ref={inputRefSubject}
                                                    placeholder="Subject I Would Like To Discuss *"
                                                    value={state?.subject}
                                                    onChange={(e) => { handleChange(e); setSubjectErr(''); }}
                                                ></input>
                                            {subjectErr ? <span className="text-danger">{subjectErr}</span> : ""}
                                            </div>
                                            <div className='col-md-12 mb-3'>
                                                <textarea 
                                                    rows="6" 
                                                    name="message" 
                                                    value={state?.message} 
                                                    placeholder="Message For Support Team *" 
                                                    id="message" 
                                                    ref={inputRefMessage}
                                                    className="form-control"
                                                    onChange={(e) => { handleChange(e); setMessageErr(''); }}
                                                ></textarea>
                                                {messageErr ? <span className="text-danger">Message is required</span> : ""}
                                            </div>

                                        </div>

                                        <button type="submit" className="submit btn btn-dark w-100 waves-effect waves-light">
                                            <b>
                                                {loader ? "Sending your message..." : "Send Message"}
                                            </b>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                {/* <div className="row justify-content-center">
                    <div className="col-sm-8">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2970.378639508797!2d-87.64454634928703!3d41.88471337287784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2cc61698fc6f%3A0x1649c661252dfc7a!2sAU%2C%20LLC!5e0!3m2!1sen!2sin!4v1568805513292!5m2!1sen!2sin"
                          frameborder="0" style={{border: "0px", opacity: "1", visibility: "visible"}} allowfullscreen=""></iframe>
                    </div>
                    <div className="col-sm-4">
                        <div className="address-bar">
                            <h2 className='address' data-set="711">{ showPageData ? 'Address' : '' }</h2>
                            <p className='address' >
                                <span data-set="712">{ showPageData ? 'AU LLC Koloden' : '' }</span> <br />
                                <span data-set="713">{ showPageData ? '564 W. Randolph St. 2nd Floor' : '' }</span> <br />
                                <span data-set="714">{ showPageData ? 'Chicago, IL 60661' : '' }</span> <br />
                            </p>
                                <h2 className='phone' data-set="715">{ showPageData ? 'Phone' : '' }</h2>
                                <span className='phone' data-set="716">{ showPageData ? '(708) 584-0888' : '' }</span><br /><br />
    
                                <h2 className='email' data-set="717">{ showPageData ? 'Email' : '' }</h2>
                                <a className='email' href="mailto:info@koloden.com" data-set="718">{ showPageData ? 'info@koloden.com' : '' }</a><br /><br />
                            
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
        
        <div className="section-info dark">
            <div className="container">
                <div className="row">
                    <h2 className="title-v2" data-set="721">{ showPageData ? 'Ready to get started?' : '' }</h2>
                    <div className="get-started-btn">
                        <Link to="/register">
                            <button>SIGN UP NOW</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        
        <Footer />
    </div>
    );
};

export default Contact;