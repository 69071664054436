import React from 'react'
import Help from './layout/Help'
import Header from './layout/Header'
import Footer from './layout/Footer'
import {Link} from 'react-router-dom'

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import $ from 'jquery'

import '../../kolodenTheme/css/bootstrap.min.css'
import '../../kolodenTheme/css/fonts.css'
import '../../kolodenTheme/css/all.min.css'
import '../../kolodenTheme/css/style.css'


const AboutUs = () => {

    const showPageData = false;
    
    $(function() {
        var pageID = 6;
        function loadPageData(){
            get(url.CMS_CONTENT + "?page=" + pageID) 
            .then(res => {
                if(res) {
                    res.forEach(element => {
                        var page = pageID;
                        var section = element.section;
                        var column = element.column;
                        if(element.content !== undefined){
                            if(element.type == 'image') {
                                $("[data-set='"+ page + section + column +"']").attr('src', "https://koloden.com/v2api/public/cms/images/" + element.content );
                            } else if(element.type == 'text') {
                                $("[data-set='"+ page + section + column +"']").html(element.content);
                            }
                        }
                    })
                }
            })
            .catch(error => {
                console.log(error)
            });
        }

        loadPageData();
    }) 

    return(
            <div className="koloden-main">
                
            <Help />
            <Header />
            
            <div className="section-info contrast pricing privacy">
                    <div className="container">
                        <div className="row">
                            <div className="left-20-priv">
                                <h1 className="title align-left">Koloden | PRIVACY POLICY</h1>
                                <p>Effective June 7, 2019</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-info blog-sect content">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-sm-12 ml-auto" data-set="611">
                                <p>AU LLC Koloden respects and values its Users’ privacy. This Privacy Policy (the “Policy”) describes the information AU LLC Koloden collects from Users when they visit or utilize the Koloden.com platform (the “Platform”), how AU LLC Koloden uses that information, and under what circumstances AU LLC Koloden may or does disclose that information. This Policy should be read in conjunction with the Koloden.com Terms of Service available at https://koloden.com/terms.</p>
                                <br />
                                <p><strong>1. Privacy Policy Introduction.</strong></p>
                                <div className="para-cont">
                                    <p style={{paddingLeft: "30px"}}>a. Purpose. We are committed to safeguarding the privacy of our website visitors and Platform service users (collectively “Users”). This Policy applies where we are acting as a data controller with respect to the personal data of our Users (“User Information”) by outlining the purposes and means of processing that User Information.</p>
                                    <p style={{paddingLeft: "30px"}}>b. Consent to Use of Cookies. We use cookies on our Platform. Where such cookies are not strictly necessary for the utilization of the Platform and services rendered thereon, we will ask you to consent to our use of cookies when you first visit our website.</p>
                                    <p style={{paddingLeft: "30px"}}>c. Privacy Controls. At the moment, the Platform does not incorporate privacy controls which affect how we will process your User Information. User may “unsubscribe” from emails the Platform send you, although doing so may prevent you from receiving notification necessary for the maintenance of your user account and operation of the Platform.</p>
                                    <p style={{paddingLeft: "30px"}}>d. Data Controller. In this policy, “we”, “us,” and “our” refer to AU LLC Koloden. For more information about AU LLC Koloden, see Section 19.</p>
                                </div>
                                <p><strong>2. How We Use Your Personal Data.</strong></p>
                                <div className="para-cont">
                                    <p style={{paddingLeft: "30px"}}>a. User Information Use Introduction. In this Section 2 we set out: (i) the general categories of User Information that we may process; (ii) in the case of User Information that we did not obtain directly from you, the source and specific categories of that data; (iii) the purposes for which we may process User Information; and (iv) the legal bases of the processing.</p>
                                    <p style={{paddingLeft: "30px"}}>b. Processing of Usage Data. We may process data about your use of the Platform (“Usage Data”). Usage Data may include your IP address, geographical location, browser type and version, operating system, equipment characteristics, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and patterns of your service use. The source of the Usage Data is our analytics tracking system. Usage Data may be processed for the purposes of analyzing the use of the Platform. The legal basis for this processing is your consent hereunder and our legitimate interests, namely monitoring and improving the Platform and services provided therewith.</p>
                                    <p style={{paddingLeft: "30px"}}>c. Processing Account Data. We may process data associated with your User account (“Account Data”). The Account Data may include your name and email address. The source of the Account Data is, generally, you. The Account Data may be processed for the purposes of operating the Platform, providing our services, ensuring the security of the Platform and services, maintaining back-ups of our databases, and communicating with you. The legal basis for this processing is your consent hereunder and our legitimate interests, namely the proper administration of our Platform and business.</p>
                                    <p style={{paddingLeft: "30px"}}>d. Processing Service Data. We may process your User Information that is provided in the course of the use of our services (“Service Data”). The Service Data may include any data obtained from marketplaces you integrate to the Platform. The source of the Service Data is, generally, you, the marketplaces you authorize the Platform to access upon their integration thereto, and the Platform’s evaluation of your product listings and data therein. Service Data may be processed for the purposes of operating our website, providing our services, ensuring the security of our website and services, maintaining back-ups of our databases, creating and analyzing Platform performance metrics, and communicating with you. The legal basis for this processing is your consent hereunder and our legitimate interests, namely the proper administration of our Platform and business.</p>
                                    <p style={{paddingLeft: "30px"}}>e. Processing Inquiry Data. We may process information contained in any inquiry you submit to us regarding goods and/or services (“Inquiry Data”). The Inquiry Data may be processed for the purposes of offering, marketing, and selling relevant goods and/or services to you. The legal basis for this processing is your consent hereunder and our legitimate interests, namely the proper administration of our Platform and business.</p>
                                    <p style={{paddingLeft: "30px"}}>f. Processing User Relationship Data. We may process information relating to our customer relationships, including User contact information (“User Relationship Data”). User Relationship Data may include your name, your contact details, and information contained in communications between us and you. The source of the User Relationship Data is, generally, you. User Relationship Data may be processed for the purposes of managing our relationships with users, communicating with users, keeping records of those communications, and promoting our products and services to users. The legal basis for this processing is your consent hereunder and our legitimate interests, namely the proper management of our customer relationships.</p>
                                    <p style={{paddingLeft: "30px"}}>g. Processing Transaction Data. We may process information relating to transactions, including purchases of goods and services, that you enter into with us and/or through our Platform (“Transaction Data”). Transaction Data may include your contact details, your payment card details, and the transaction details. Transaction Data may be processed for the purpose of supplying the purchased goods and services and keeping proper records of those transactions. The legal basis for this processing is the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract and our legitimate interests, namely the proper administration of our Platform and business.</p>
                                    <p style={{paddingLeft: "30px"}}>h. Processing Notification Data. We may process information that you provide to us for the purpose of subscribing to our email notifications and/or newsletters (“Notification Data”). Notification Data may be processed for the purposes of sending you the relevant notifications and/or newsletters. The legal basis for this processing is your consent hereunder and our taking steps, at your request, to correspond with you in those fashions.</p>
                                    <p style={{paddingLeft: "30px"}}>i. Processing Correspondence Data. We may process information contained in or relating to any communication that you send to us (“Correspondence Data”). Correspondence Data may include the communication content and metadata associated with the communication. Our Platform will generate the metadata associated with communications made using any Platform contact forms. Correspondence Data may be processed for the purposes of communicating with you and record-keeping. The legal basis for this processing is your consent hereunder and our legitimate interests, namely the proper administration of our Platform and business and communications with users.</p>
                                    <p style={{paddingLeft: "30px"}}>j. Processing Other Data. Reserved.</p>
                                    <p style={{paddingLeft: "30px"}}>k. Processing Data for Legal Claims. We may process any of your User Information identified in this policy where necessary for the establishment, exercise, or defense of legal claims, whether in court proceedings or in an administrative or out-of-court procedure. The legal basis for this processing is our legitimate interests, namely the protection and assertion of our legal rights, your legal rights and the legal rights of others.</p>
                                    <p style={{paddingLeft: "30px"}}>l. Processing for Risk Management. We may process any of your User Information identified in this Privacy Policy where necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, or obtaining professional advice. The legal basis for this processing is our legitimate interests, namely the proper protection of our business against risks.</p>
                                    <p style={{paddingLeft: "30px"}}>m. General Purposes of Processing User Information. In addition to the foregoing, we may also process any of your User Information where such processing is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.</p>
                                    <p style={{paddingLeft: "30px"}}>n. Disclosure of Third-Party User Information. Please do not provide any other person’s User Information to us, unless we prompt you to do so.</p>
                                </div>
                                <p><strong>3. Reserved</strong></p>
                                <p><strong>4. How We Disclose User Information.</strong></p>
                                <div className="para-cont">
                                    <p style={{paddingLeft: "30px"}}>a. Intra-Group Disclosures of User Information. We may disclose your User Information to any member of our organization, including its parents and subsidiaries as reasonably necessary for the purposes, and on the legal bases, set out in this policy.</p>
                                    <p style={{paddingLeft: "30px"}}>b. Disclosure of User Information to Insurers and Advisers. We may disclose your User Information to our insurers and/or professional advisers as reasonably necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, obtaining professional advice, or the establishment, exercise or defense of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.</p>
                                    <p style={{paddingLeft: "30px"}}>c. Disclosures of User Information to Subcontractors. We may disclose User Information to our suppliers or subcontractors, including without limitation Platform developers, insofar as reasonably necessary for the maintenance and administration of our Platform and business.</p>
                                    <p style={{paddingLeft: "30px"}}>d. Disclosure of User Information to Payment Services Providers. Financial transactions relating to our Platform and services are be handled by our payment services providers (e.g., Stripe®). We will share transaction data with our payment services providers only to the extent necessary for the purposes of processing your transactions, payments, refunding such payments, and dealing with complaints and queries relating to such payments and refunds. You can find information about the payment services providers’ privacy policies and practices at https://stripe.com/us/privacy/.</p>
                                    <p style={{paddingLeft: "30px"}}>e. Disclosure of User Information to Third-Party Suppliers. We may disclose your User Information to one or more of those selected third-party suppliers of goods and services for the purpose of enabling them to contact you so that they can offer, market and sell to you relevant goods and/or services.&nbsp; Each such third party will act as a data controller in relation to the data that we supply to it; and upon contacting you, each such third party will supply to you a copy of its own privacy policy, which will govern that third party’s use of your User Information.</p>
                                    <p style={{paddingLeft: "30px"}}>f. Disclosure of User Information Necessary for Legal Compliance, etc. In addition to the foregoing, we may disclose your User Information where such disclosure is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person. We may also disclose your User Information where such disclosure is necessary for the establishment, exercise or defense of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.</p>
                                </div>
                                <p><strong>5. International Transfers of Your User Information.</strong></p>
                                <div className="para-cont">
                                    <p style={{paddingLeft: "30px"}}>a. Introduction to International User Information Transfers. In this Section 5, we provide information about the circumstances in which your User Information may be transferred to countries outside the European Economic Area (EEA).</p>
                                    <p style={{paddingLeft: "30px"}}>b. International Transfers within Business. We have offices and facilities in the United States. The European Commission has made an “adequacy decision” with respect to the data protection laws of the United States. Transfers to the United States will be protected by appropriate safeguards, namely the Federal Trade Commission Act (15 U.S.C. §§ 41-58), the Electronic Communications Privacy Act (18 U.S.C. § 2510 et seq.) and the Computer Fraud and Abuse Act (18 U.S.C. § 1030), and the Stored Communications Act (18 U.S.C. § 2701, et seq.).</p>
                                    <p style={{paddingLeft: "30px"}}>c. International Transfers to Hosting Services Provider. The hosting facilities for our Platform are situated in the United States. The European Commission has made an “adequacy decision” with respect to the data protection laws of the United States. Transfers to the United States will be protected by appropriate safeguards, namely the Federal Trade Commission Act (15 U.S.C. §§ 41-58), the Electronic Communications Privacy Act (18 U.S.C. § 2510 et seq.) and the Computer Fraud and Abuse Act (18 U.S.C. § 1030), and the Stored Communications Act (18 U.S.C. § 2701, et seq.).</p>
                                    <p style={{paddingLeft: "30px"}}>d. International Transfers to Subcontractors. Our subcontractors are situated in the United States and Singapore. The European Commission has made an “adequacy decision” with respect to the data protection laws of the United States and Singapore. Transfers to the United States will be protected by appropriate safeguards, namely the Federal Trade Commission Act (15 U.S.C. §§ 41-58), the Electronic Communications Privacy Act (18 U.S.C. § 2510 et seq.) and the Computer Fraud and Abuse Act (18 U.S.C. § 1030), the Stored Communications Act (18 U.S.C. § 2701, et seq.), and the Singapore Personal Data Protection Act 2012 (PDPA).</p>
                                    <p style={{paddingLeft: "30px"}}>e. Publication of User Information on Internet. You acknowledge that User Information that you submit for publication through our website or services may be available, via the internet, around the world. We cannot prevent the use (or misuse) of such User Information by others.</p>
                                </div>
                                <p><strong>6. Retaining and Deleting User Information.</strong></p>
                                <div className="para-cont">
                                    <p style={{paddingLeft: "30px"}}>a. Data Retention Introduction. This Section 6 sets out our data retention policies and procedures which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of User Information.</p>
                                    <p style={{paddingLeft: "30px"}}>b. User Information Retention Default Rule. User Information that we process for any purpose or purposes shall not be kept for longer than is necessary therefor.</p>
                                    <p style={{paddingLeft: "30px"}}>c. Reserved.</p>
                                    <p style={{paddingLeft: "30px"}}>d. User Information Retention Criteria. In some cases, it is not possible for us to specify in advance the periods for which your User Information will be retained. In such cases, we will determine the period of retention based on the following criteria:</p>
                                    <p style={{paddingLeft: "60px"}}><em>i. our reasonable necessity for retaining the User Information, pursuant to this Policy; and</em></p>
                                    <p style={{paddingLeft: "60px"}}><em>ii. our obligations to delete this information, pursuant to this Policy and applicable law.</em></p>
                                    <p style={{paddingLeft: "30px"}}>e. User Information Deletion Exception. Notwithstanding the other provisions of this Section 6, we may retain your User Information where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.</p>
                                </div>
                                <p><strong>7. Security of User Information.</strong></p>
                                <div className="para-cont">
                                    <p style={{paddingLeft: "30px"}}>a. Appropriate Technical and Organizational Security Measures. We will take appropriate technical and organizational precautions to secure your User Information and to prevent the loss, misuse or alteration of your User Information.</p>
                                    <p style={{paddingLeft: "30px"}}>b. User Information Stored on Secure Servers and Computers. We will store all your User Information on secure servers.</p>
                                    <p style={{paddingLeft: "30px"}}>c. Encrypted storage of User Information. The following User Information will be stored by us in encrypted form: your name, contact information, and password.</p>
                                    <p style={{paddingLeft: "30px"}}>d. Security of Server-Browser Communications. Data relating to your inquiries and financial transactions that is sent from your web browser to our web server, or from our web server to your web browser, will be protected using encryption technology.</p>
                                    <p style={{paddingLeft: "30px"}}>e. Unencrypted Data Sent Over Internet is Insecure. You acknowledge that the transmission of unencrypted (or inadequately encrypted) data over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.</p>
                                    <p style={{paddingLeft: "30px"}}>f. Password Security. You should ensure that your password is not susceptible to being guessed, whether by a person or a computer program. You are responsible for keeping the password you use for accessing our website confidential and we will not ask you for your password (except when you log in to our website).</p>
                                </div>
                                <p><strong>8. Amendments to this Privacy Policy.</strong></p>
                                <div className="para-cont">
                                    <p style={{paddingLeft: "30px"}}>a. Amendment by Publication. We may update this Privacy Policy from time to time by publishing a new version on our website.</p>
                                    <p style={{paddingLeft: "30px"}}>b. Check for Changes to the Policy. You should check this page occasionally to ensure you are happy with any changes to this policy.</p>
                                    <p style={{paddingLeft: "30px"}}>c. Notification of Changes to the Policy. We may notify you of significant changes to this Privacy Policy by email or through the private messaging system on our Platform.</p>
                                </div>
                                <p><strong>9. Your Rights.</strong></p>
                                <div className="para-cont">
                                    <p style={{paddingLeft: "30px"}}>a. Introduction to Data Subject Rights Summaries. In this Section 9, we have summarized the rights that you have under data protection law. Some of the rights are complex, and not all of the details have been included in our summaries. Accordingly, you should read the relevant laws and guidance from the regulatory authorities for a full explanation of these rights.</p>
                                    <p style={{paddingLeft: "30px"}}>b. List of Data Subject Rights. Your principal rights under data protection law are:</p>
                                    <p style={{paddingLeft: "60px"}}><em>i. the right to access;</em></p>
                                    <p style={{paddingLeft: "60px"}}><em>ii. the right to rectification;</em></p>
                                    <p style={{paddingLeft: "60px"}}><em>iii. the right to erasure;</em></p>
                                    <p style={{paddingLeft: "60px"}}><em>iv. the right to restrict processing;</em></p>
                                    <p style={{paddingLeft: "60px"}}><em>v. the right to object to processing;</em></p>
                                    <p style={{paddingLeft: "60px"}}><em>vi. the right to data portability;</em></p>
                                    <p style={{paddingLeft: "60px"}}><em>vii. the right to complain to a supervisory authority; and</em></p>
                                    <p style={{paddingLeft: "60px"}}><em>viii. the right to withdraw consent.</em></p>
                                    <p style={{paddingLeft: "30px"}}>c. Summary of Right to Access User Information. You have the right to confirmation as to whether or not we process your User Information and, where we do, access to the User Information, together with certain additional information. That additional information includes details of the purposes of the processing, the categories of User Information concerned and the recipients of the User Information. Providing the rights and freedoms of others are not affected, we will supply to you a copy of your User Information. You can access your User Information by accessing your User Account when logged into the Platform.</p>
                                    <p style={{paddingLeft: "30px"}}>d. Summary of Right to Rectification of User Information. You have the right to have any inaccurate User Information about you rectified and, taking into account the purposes of the processing, to have any incomplete User Information about you completed.</p>
                                    <p style={{paddingLeft: "30px"}}>e. Summary to Right to Erasure of User Information. In some circumstances you have the right to the prompt erasure of your User Information. Those circumstances include: (i) the User Information is no longer necessary in relation to the purposes for which it was collected or otherwise processed; (ii) you withdraw consent to consent-based processing; (iii) you object to the processing under certain rules of applicable data protection law; (iv) the processing is for direct marketing purposes; and (v) the User Information has been unlawfully processed. However, there are exclusions of the right to erasure. The general exclusions include where processing is necessary: (i) for exercising the right of freedom of expression and information; (ii) for compliance with a legal obligation; or (iii) for the establishment, exercise, or defense of legal claims.</p>
                                    <p style={{paddingLeft: "30px"}}>g. Summary of Right to Object to Processing of User Information. You have the right to object to our processing of your User Information on grounds relating to your particular situation, but only to the extent that the legal basis for the processing is that the processing is necessary for: (i) the performance of a task carried out in the public interest or in the exercise of any official authority vested in us; or (ii) the purposes of the legitimate interests pursued by us or by a third party. If you make such an objection, we will cease to process the personal information unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing is for the establishment, exercise, or defense of legal claims. You acknowledge that under certain circumstances, objecting to our processing of your User Information may compromise or prevent your utilization of certain features of the Platform.</p>
                                    <p style={{paddingLeft: "30px"}}>h. Summary of Right to Object to Processing of User Information for Direct Marketing. You have the right to object to our processing of your User Information for direct marketing purposes (including profiling for direct marketing purposes). If you make such an objection, we will cease to process your User Information for this purpose.</p>
                                    <p style={{paddingLeft: "30px"}}>i. Summary of Right to Object to Processing of User Information for Research Purposes. You have the right to object to our processing of your User Information for scientific or historical research purposes or statistical purposes on grounds relating to your particular situation, unless the processing is necessary for the performance of a task carried out for reasons of public interest.</p>
                                    <p style={{paddingLeft: "30px"}}>k. Summary of Right to Complain to Data Protection Supervisory Authority. If you consider that our processing of your personal information infringes data protection laws, you have a legal right to lodge a complaint with a supervisory authority responsible for data protection. You may do so in the EU member state of your habitual residence, your place of work, or the place of the alleged infringement.</p>
                                    <p style={{paddingLeft: "30px"}}>l. Summary of Right to Withdraw Consent to User Information Processing. To the extent that the legal basis for our processing of your User Information is consent, you have the right to withdraw that consent at any time. Withdrawal will not affect the lawfulness of processing before the withdrawal.</p>
                                    <p style={{paddingLeft: "30px"}}>m. Exercise of Data Subject Rights. You may exercise any of your rights in relation to your User Information by written notice to us, in addition to the other methods specified in this Section 9.</p>
                                </div>
                                <p><strong>10. Third-Party Platforms.</strong></p>
                                <div className="para-cont">
                                    <p style={{paddingLeft: "30px"}}>a. Hyperlinks to Third-Party Platforms. Our Platform includes hyperlinks to, and details of, third-party websites.</p>
                                    <p style={{paddingLeft: "30px"}}>b. No Responsibility for Third-Party Privacy Policies. We have no control over, and are not responsible for, the privacy policies and practices of third parties.</p>
                                </div>
                                <div className="para-cont">
                                    <p><strong>11. User Information of Children.</strong> The Platform is targeted primarily for use by adults. We do not target or create special areas for use by children. Accordingly, we do not knowingly collect age identifying information, nor do we knowingly collect any personal information from children under the age of 13 years. HOWEVER, WE HEREBY ADVISE ALL VISITORS TO THE WEBSITE UNDER THE AGE OF 13 NOT TO DISCLOSE OR PROVIDE ANY PERSONALLY IDENTIFIABLE INFORMATION ON THE WEBSITE. In the event that we discover that a child under the age of 13 has provided personally identifiable information to us, in accordance with the Children’s Online Privacy Protection Act (please see the Federal Trade Commission’s website at http://www.onguardonline.gov/articles/0031-protecting-your-childs-privacy-online for more information about this Act), we will delete the child’s personally identifiable information from our files to the extent possible.</p>
                                    <p><strong>12. Updating Information.</strong> Please let us know if the personal information that we hold about you needs to be corrected or updated.</p>
                                    <p><strong>13. Reserved.</strong></p>
                                    <p><strong>14. Cookies.</strong></p>
                                    <p style={{paddingLeft: "30px"}}>a. What are cookies? A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.</p>
                                    <p style={{paddingLeft: "30px"}}>b. Persistent and Session Cookies. Cookies may be either “persistent” cookies or “session” cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.</p>
                                    <p style={{paddingLeft: "30px"}}>c. Cookies and Personal Information. Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies.</p>
                                </div>
                                <p><strong>15. Cookies that We Use. </strong>Reserved</p>
                                <div className="para-cont">
                                    <p style={{paddingLeft: "30px"}}>a. Use of Cookies by Services Providers. Our service providers use cookies and those cookies may be stored on your computer when you visit our website.</p>
                                    <p style={{paddingLeft: "30px"}}>b. Google Analytics Cookies. We use Google Analytics to analyze the use of our website. Google Analytics gathers information about website use by means of cookies. The information gathered relating to our website is used to create reports about the use of our website. Google’s privacy policy is available at: https://www.google.com/policies/privacy/.</p>
                                </div>
                                <p><strong>17. Managing Cookies.</strong></p>
                                <div className="para-cont">
                                    <p style={{paddingLeft: "30px"}}>a. How to Manage Cookies. Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these links:</p>
                                    <p style={{paddingLeft: "60px"}}><em>i. https://support.google.com/chrome/answer/95647?hl=en (Chrome);</em></p>
                                    <p style={{paddingLeft: "60px"}}><em>ii. https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences (Firefox)</em></p>
                                    <p style={{paddingLeft: "60px"}}><em>iii. http://www.opera.com/help/tutorials/security/cookies/ (Opera);</em></p>
                                    <p style={{paddingLeft: "60px"}}><em>iv. https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies (Internet Explorer);</em></p>
                                    <p style={{paddingLeft: "60px"}}><em>v. https://support.apple.com/kb/PH21411 (Safari); and</em></p>
                                    <p style={{paddingLeft: "60px"}}><em>vi. https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy (Edge).</em></p>
                                    <p style={{paddingLeft: "30px"}}>b. Negative Impact of Blocking Cookies. Blocking all cookies will have a negative impact upon the usability of many websites.</p>
                                    <p style={{paddingLeft: "30px"}}>c. Effects on Platform use of Blocking Cookies. If you block cookies, you will not be able to use all the features on our website.</p>
                                </div>
                                <p><strong>18. Reserved.</strong></p>
                                <p><strong>19. Our Details.</strong></p>
                                <div className="para-cont">
                                    <p style={{paddingLeft: "30px"}}>a. Platform Operator Name. This website is owned and operated by AU LLC Koloden.</p>
                                    <p style={{paddingLeft: "30px"}}>b. Company Registration Details. We are registered in Illinois, United States, under registration number 03542777 and our registered office is at 564 W. Randolph St. 2nd Floor Chicago, IL 60661 USA.</p>
                                    <p style={{paddingLeft: "30px"}}>c. Place of Business. Our principal place of business is at 564 W. Randolph St. 2nd Floor Chicago, IL 60661 USA.</p>
                                    <p style={{paddingLeft: "30px"}}>d. Contact Information. You can contact us:</p>
                                    <p style={{paddingLeft: "60px"}}><em>i. by post, to the postal address given above;</em></p>
                                    <p style={{paddingLeft: "60px"}}><em>ii. using our Platform contact form;</em></p>
                                    <p style={{paddingLeft: "60px"}}><em>iii. by telephone, on the contact number published on our website from time to time; or</em></p>
                                    <p style={{paddingLeft: "60px"}}><em>iv. by email, using the email address published on our website from time to time.</em></p>
                                </div>
                                <p><strong>20. Data Protection Registration.</strong></p>
                                <div className="para-cont">
                                    <p style={{paddingLeft: "30px"}}>a. Registered with ICO. We are not registered as a data controller with the UK Information Commissioner’s Office.</p>
                                    <p style={{paddingLeft: "30px"}}>b. Data Protection Registration Number. We do not maintain a data protection registration number.</p>
                                </div>
                                <p><strong>21. Representative within the European Union.</strong> We do not maintain a representative within the European Union.</p>
                                <p><strong>22. Data Protection Officer.</strong> We do not maintain a data protection officer registration. Any queries about data protection may be sent to us at, Attention PRIVACY, at the contact information set forth herein.</p>


                            </div>

                        </div>
                    </div>
                </div>
                
                <div className="section-info dark">
                    <div className="container">
                        <div className="row">
                            <h1 className="title" data-set="621">{ showPageData ? 'Ready to get started?' : '' }</h1>
                            <div className="get-started-btn">
                                <Link to="/register">
                                    <button>SIGN UP NOW</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                
            <Footer />
            </div>
    );
};

export default AboutUs;