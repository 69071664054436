import React from 'react'
import Help from './layout/Help'
import Header from './layout/Header'
import Footer from './layout/Footer'
import {Link} from 'react-router-dom'

import '../../kolodenTheme/css/bootstrap.min.css'
import '../../kolodenTheme/css/fonts.css'
import '../../kolodenTheme/css/all.min.css'
import '../../kolodenTheme/css/style.css'

const RightSholders = () => {
    return(
            <div className="koloden-main">
                
                <Help />
                <Header />
                <div className="section-info contrast pricing">
                    <div className="container">
                        <div className="row justify-content-center">
                            <h1 className="title"><span>Koloden </span>for Rightsholders</h1>
                                <h2 className="title">Do you own protected product images?  Are you interested in reducing the rate at<br /> which e-commerce resellers use them, without authorization, to sell goods?</h2>
                        </div>
                    </div>
                </div>
                <div className="section-info blog-sect">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-sm-3">
                                <div className="img-box">
                                    <img src="kolodenTheme/img/pexels-kaboompics-com-5598-1024x683.jpg" alt="" />
                                </div>
                            </div>
                            <div className="col-sm-9">
                                <h1 className="title"><span>Koloden </span>utilizes proprietary trademark and image content databases to help resellers cease and avoid using protected trademark and copyright assets.</h1>
                                <p>
                                    As part of Koloden’s system, we may protectably host your brand content within our database which can be used to flag any of our users’ listings which may incorporate such content.<br /><br />

        Koloden operates behind a 256-bit SSL and is SOC2, SOC3, SSAE 16, and PCI-compliant.  Your branded content is secure and can be removed at any time.<br /><br />

        If you are interested to helping Koloden’s mission and mitigating infringement of your branded content, contact us at admin@koloden.com.<br /><br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-info dark">
                    <div className="container">
                        <div className="row">
                            <h1 className="title">Ready to get started?</h1>
                            <div className="get-started-btn">
                                <Link to="/register">
                                    <button>SIGN UP NOW</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                
            <Footer />
            </div>
    );
};

export default RightSholders;