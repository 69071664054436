import React, {useEffect, useState} from 'react'
import Help from './layout/Help'
import Header from './layout/Header'
import Footer from './layout/Footer'
import {Link} from 'react-router-dom'
// import {Accordion} from 'bootstrap-4-react';

import {post, del, get, put} from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"

import '../../kolodenTheme/css/bootstrap.min.css'
import '../../kolodenTheme/css/fonts.css'
import '../../kolodenTheme/css/all.min.css'
import '../../kolodenTheme/css/style.css'
import '../../kolodenTheme/css/blog.css'

import $ from 'jquery'

const FAQs = () => {

    function handleToggle(e){
        let targetNode = e.target;
        let targetParent = targetNode.closest('#acc-item');
        let targetChildDiv = targetParent.childNodes[1];
        let targetChildDiv2 = targetNode.closest('.accordion-item').querySelector('#toggleIcon');
        if(targetChildDiv.classList.contains('show')){
            targetChildDiv.classList.remove('show');
            targetChildDiv.classList.add('hide');
            targetChildDiv2.classList.add('expand');
            targetChildDiv2.classList.remove('shrink');
        }else {
            targetChildDiv.classList.remove('hide');
            targetChildDiv.classList.add('show');
            targetChildDiv2.classList.remove('expand');
            targetChildDiv2.classList.add('shrink');
        }
    }

    const [faqs, setFaqs] = useState([]);
    document.title = 'FAQs | Koloden';
    useEffect(() => {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;

        xhr.addEventListener("readystatechange", function () {
            if (this.readyState === 4) {
                setFaqs(JSON.parse(this.responseText));
            }
        });

        xhr.open("GET", "https://koloden.com/blog/wp-json/wp/v2/posts?categories=2&context=view&per_page=50&orderby=id&order=asc");

        xhr.send();
    }, []);
    



    return (
        <div className="koloden-main">

            <Help/>
            <Header/>

            <div className="faq-container">
                <div className="container">
                    <div id='action-item'>
                        <h1>Frequently Asked Questions</h1>
                        <p>From how to avoid IP infringement to how Koloden works - we’re dedicated to filling the knowledge gap. Reach out for any other questions or <a href="https://koloden.com/register">click here to get started</a> protecting yourself today.</p>
                    </div>

                    <div className="accordion">
                        {faqs.map((faq, index) => (
                            <div className="accordion-item" id='acc-item'>
                                <h2 className="accordion-header">
                                    <button className="accordion-button" type="button" onClick={handleToggle}>
                                        <span className='expand' id="toggleIcon"></span>
                                        {faq.title.rendered}
                                    </button>
                                </h2>
                                <div className="accordion-collapse collapse hide" id='action-victim'>
                                    <div id={"collapse"+index} className="accordion-body" dangerouslySetInnerHTML={{__html: faq.content.rendered}}>
                                                                            
                                    </div>
                                </div>
                                {/* <div className='sep-accordion'></div> */}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
};

export default FAQs;