//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"
export const POST_REGISTER = "/register"

//LOGIN
export const POST_JWT_LOGIN = "/login"
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_PASSWORD_FORGET = "/forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"
export const RESET_PASSWORD = "/reset"
export const VERIFY_RESET_PASSWORD_TOKEN = "/verify-token"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/edit_user"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"

// CONTACT-US
export const POST_CONTACT_US = "https://koloden.com/v2api/public/api/contact-us"
export const POST_CONTACT_US_USER = "https://koloden.com/v2api/public/api/contact-us-user"


//STORES
export const GET_STORES = "/store"

//USER STORES
export const GET_USERSTORES = "/userstore"
export const POST_USERSTORES = "/userstore"
export const POST_USERSTORES_SP = "/userstore/store-sp"


//USER STORE PRODUCTS
export const USER_STOREPRODUCTS = "/products"
export const USER_STOREPRODUCTS_IGNORE_ALERT = "/products/list/ignore"

//Chart - Dashboard - Series
export const CHART_DASHBOARD_SERIES = "/chart/series"
export const CHART_DASHBOARD_REPORTS_TODAY = "/chart/today"
export const CHART_DASHBOARD_REPORTS_LSS = "/chart/listing_scan_summary"
export const CHART_DASHBOARD_CE_BY_PLATFORM = "/chart/ce_by_platform"
//Billing - User
export const BILLING_USER_DATA_YearMonths = "/billing/ym"
export const BILLING_USER_DATA = "/billing/user"
export const BILLING_USER_DATA_TOTAL = "/billing/user/total"
export const BILLING_USER_DATA_ALL = "/billing/user/all"
//Stripe
export const STRIPE_USER_CARD = "/stripe/user/card"
export const STRIPE_USER_INFO = "/stripe/user/info"
export const STRIPE_USER_PAYMENT_METHOD = "/stripe/user/set-payment-method"
export const STRIPE_USER_GET_PAYMENT_METHOD = "/stripe/user/get-payment-method"

//User Information
export const USER_STATISTICS = "/user-statistics"
export const GET_ADMIN_USERS_INFO = '/admin-users'
export const GET_ADMIN_REFERRAL_INFO = '/admin-referral'

export const ACTION_ADMIN_USER_RESET = '/admin-user-reset'
export const ACTION_ADMIN_USER_STATUS = '/admin-user-status'
export const ACTION_ADMIN_USER_DELETE = '/admin-user-delete'

//Constact URLs
export const URL_LINK_DASHBOARD = '/dashboard'
export const URL_LINK_ADMIN_USERTOTAL = '/dashboard/user-totals'

//Promo Code
export const GET_ADMIN_PROMO_CODES = '/promocodes'
export const PROMO_CODES_DELETE = '/promocode-delete'
export const PROMO_CODES_ACTIVE = '/promocode-active'
export const PROMO_CODES_INACTIVE = '/promocode-inactive'
export const PROMO_CODES_GET = '/promocode-get'
export const PROMO_CODES_ADD = '/promocode-add'
export const PROMO_CODES_EDIT = '/promocode-edit'

// CMS Pages
export const CMS_CONTENT = '/cms/get/content'

// AMAZON PAY
export const AMAZON_PAY_SIGNATURE = '/amazon-pay/get-signature'
export const AMAZON_PAY_DETAILS = '/amazon-pay/get-amazonpay-details'

// API: Trademarks
export const API_TRADEMARK = '/trademark/get'
export const API_TRADEMARK_SET = '/trademark/set'

// API: Notification
export const API_NOTIFICATION_SETTING = '/notification/setting'
export const API_NOTIFICATION_FREQUENCY = '/notification/frequency'

//Risk Feed URLs
export const URL_RISK_FEED = '/risk-feed'
export const URL_RISK_FEED_NOT_SEEN = '/risk-feed/not-seen'
export const URL_RISK_FEED_ALL_SEEN = '/risk-feed/all-seen'

//Risk Feed URLs
export const URL_WORD_LIST = '/word-list'
export const URL_WORD_LIST_EDIT = '/word-list-edit'
export const URL_WORD_LIST_ADD = '/word-list-add'
export const URL_WORD_LIST_DELETE = '/word-list-delete'
export const URL_WORD_LIST_SEARCH = '/word-list-search'