import React, { Component } from "react"
import { useEffect, useState } from "react"
import ReactEcharts from "echarts-for-react"
import { post, del, get, put } from "../../../../src/helpers/api_helper"
import * as url from "../../../../src/helpers/url_helper"

class DoughnutWalmart extends Component {

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this);
    this.renderList = this.renderList.bind(this);

    this.state = {
      data: [
        {
          name: 'Critical Errors',
          value: '0',
        }, {
          name: 'Attention',
          value: '0',
        }, {
          name: 'Minimal Risk',
          value: '0',
        },
      ],
      optionsData: this.getOption(),
      reportStartDate: this.getDateTime()
    }
  }

  getDateTime = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    return today;
  }

  componentDidMount() {
    var context = this;
    let td = new Date().toISOString().slice(0, 10);
    var uid = localStorage.getItem('m_id');
    var cdrtLSS = localStorage.getItem('c_d_r_lss_WAL_' + uid + '_' + td);

    if(cdrtLSS){
      var res = JSON.parse(cdrtLSS);
      context.setState({data: res.data})
      context.setState({optionsData: res.options})
    }else{
      post(url.CHART_DASHBOARD_REPORTS_LSS + "?store=4&date=" + this.getDateTime()) 
      .then(res => {
          if(res) {
            localStorage.setItem('c_d_r_lss_WAL_' + uid + '_' + td, JSON.stringify(res));
            context.setState({data: res.data})
            context.setState({optionsData: res.options})
          }
      })
    }



    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    } 
        
    today = yyyy + '-' + mm + '-' + dd;
    document.getElementById("date_list_scan_summary").setAttribute("max", today);
  }

  renderList() {
      const {data} = this.state;
      let content = data.map(datum => {
        return (
          <li>{datum.name}<span>{datum.value}</span></li>
        )
      });
      return (
        <ul className="list_Group">
            <li className="Heading">Errors<span>Number</span></li>
            {content}
        </ul>
      )
  }

  handleChange (e) {
    var date = e.target.value
    var context = this
    this.setState({ reportStartDate: date })

    console.log(this.state.optionsData)
    post(url.CHART_DASHBOARD_REPORTS_LSS + "?store=4&date=" + date) 
    .then(res => {
        if(res) {
          context.setState({data: res.data})
          context.setState({optionsData: res.options})
        }
        console.log(this.state.optionsData)
    })
    .catch(error => {
    });
  }


  getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        x: "left",
        data: ["Critical", "Attention", "Minimal"],
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#f46a6a", "#f1b44c", "#34c38f"],
      series: [
        {
          name: "Listing Scan Summary",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "30",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: [
            { value: 335, name: "Critical" },
            { value: 310, name: "Attention" },
            { value: 234, name: "Minimal" },
          ],
        },
      ],
    }
  }
  render() {
    const myList = this.renderList();
    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "350px" }} option={this.state.optionsData} />
        <div class="custom-style">
          <input type="date" id="date_list_scan_summary" className="custom_datePicker" value={this.state.reportStartDate} onChange={this.handleChange} /* onChange={(e) => {this.handleChange(e)}} */></input>
          {myList}
        </div>
      </React.Fragment>
    )
  }
}
export default DoughnutWalmart
