import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { MDBDataTable } from "mdbreact"
import { Container, Modal,Table, Pagination, PaginationItem, PaginationLink, Form, FormGroup, Label, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Input,
Button} from "reactstrap"
import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"

//Import Components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import "./datatable-cstm.css"
import $ from 'jquery'


const Promocode = props => {

    const [table_data, setTableData] = useState({});
    const [modal_title, setModalTitle] = useState({});
    const [modal_code, setModalCode] = useState({});
    const [modal_description, setModalDescription] = useState({});
    const [modal_StartDate, setModalStartDate] = useState({});
    const [modal_EndDate, setModalEndDate] = useState({});
    const [modal_TrialPeriod, setModalTrialPeriod] = useState({});
    const [modal_Status, setModalStatus] = useState({});
    const [modal_ID, setModalID] = useState({});
    const [modal_error, setModalError] = useState({});

    useEffect(() => {

      setModalTitle('Add a Promo Code');
      setModalCode('');
      setModalDescription('');
      setModalStartDate(getCurrentDate("-"));
      setModalEndDate(getCurrentDate("-"));
      setModalTrialPeriod('');
      setModalStatus('');
      setModalID("0");
      setModalError('');

      setTableData({});
      getTableData();

    }, [])

    function getCurrentDate(separator=''){

      let newDate = new Date()
      let date_raw = newDate.getDate();
      let month_raw = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      var date, month
        
      if (date_raw<10)  {  date ="0"+date_raw.toString()} else {  date =date_raw.toString()}
      if (month_raw<10)  {  month ="0"+month_raw.toString()} else {  month =month_raw.toString()}
      
      
      return (year + separator + month + separator + date);
    }

    function removeBodyCss() {
      document.body.classList.add("no_padding")
    }


    const data = {
      columns: [
        {
          label: "Promo Code",
          field: "code",
          sort: "asc",
          width: 150,
        },
        {
          label: "Description",
          field: "description",
          sort: "asc",
          width: 270,
        },
        {
          label: "Start Date",
          field: "date_start",
          sort: "asc",
          width: 200,
        },
        {
          label: "End Date",
          field: "date_end",
          sort: "asc",
          width: 100,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 150,
        },
        {
          label: "Trial Period (Days)",
          field: "initial_trial_period",
          sort: "asc",
          width: 150,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [
        /*
        {
          serial: "1",
          pmCode: "System Architect",
          startDate: "21/Jan/2020",
          endDate: "61/Mar/2021",
          Status: "Active",
          Actions: 
          [
              <button className="btn btn-primary btn-sm waves-effect waves-light btn btn-success">Active</button>,
              <button className="btn btn-primary btn-sm waves-effect waves-light btn btn-info">In Active</button>,
              <button className="btn btn-primary btn-sm waves-effect waves-light btn btn-warning" onClick={() => {
                  tog_standard()
                }}>Edit</button>,
              <button className="btn btn-primary btn-sm waves-effect waves-light btn btn-danger">Delete</button>,
              
          ],
        } */
      ],
    }

    const [modal_standard, setmodal_standard] = useState(false)
    function tog_standard() {
      setModalTitle('Add a Promo Code')
      setModalID("0")
      setmodal_standard(!modal_standard)
      removeBodyCss()
    }

    const deletePromoCode = (code) => {
      if (window.confirm('Delete ' + code)) {
        post(url.PROMO_CODES_DELETE + '?code=' + code).then(res => {
          if(res) {
            window.location.reload();
          }
        })
        .catch(error => {
          console.log(error);
        });
      } else {
        console.log('not');
      }
    }

    const activePromoCode = (code) => {
      $('.btn-active-' + code).html('Wait...');
      post(url.PROMO_CODES_ACTIVE + '?code=' + code).then(res => {
        if(res) {
          window.location.reload();
        }
      })
      .catch(error => {
        console.log(error);
      });
    }

    const inactivePromoCode = (code) => {
      $('.btn-inactive-' + code).html('Wait...');
      post(url.PROMO_CODES_INACTIVE + '?code=' + code).then(res => {
        if(res) {
          window.location.reload();
        }
      })
      .catch(error => {
        console.log(error);
      });
    }

    const editPromoCode = (code) => {
      setModalTitle('Edit Promo Code: ' + code);
      if(document.getElementById('modal-error') !=null){
        document.getElementById("modal-error").style.display = "none";
      }

      get(url.PROMO_CODES_GET + '?code=' + code) 
      .then(res => {
          if(res) {
            setModalID(res.id)
            setModalCode(res.code)
            setModalDescription(res.description)
            setModalStartDate(res.date_start)
            setModalEndDate(res.date_end)
            setModalTrialPeriod(res.initial_trial_period)
            setModalStatus(res.status)
            setmodal_standard(true)
            removeBodyCss()
          }
      })
    }

    const savePromoCode = () => {
      if(document.getElementById('modal-error') !=null){
        document.getElementById("modal-error").style.display = "none";
      }
      var title = modal_title.substring(0,4);
      if(title == 'Edit'){

        var param = "code=" + modal_code
        param += "&" + "description=" + modal_description
        param += "&" + "date_start=" + modal_StartDate
        param += "&" + "date_end=" + modal_EndDate
        param += "&" + "status=" + modal_Status
        param += "&" + "initial_trial_period=" + modal_TrialPeriod
        param += "&" + "id=" + modal_ID

        post(url.PROMO_CODES_EDIT + '?' + param).then(res => {
          if(res) {
            window.location.reload();
          }
        })
        .catch(error => {
          console.log(error);
        });
      }else if(title == 'Add '){
        var param = "code=" + modal_code
        param += "&" + "description=" + modal_description
        param += "&" + "date_start=" + modal_StartDate
        param += "&" + "date_end=" + modal_EndDate
        param += "&" + "status=" + modal_Status
        param += "&" + "initial_trial_period=" + modal_TrialPeriod

        post(url.PROMO_CODES_ADD + '?' + param).then(res => {
          if(res) {
            window.location.reload();
          }
        })
        .catch(error => {
          if(document.getElementById('modal-error') !=null){
            document.getElementById("modal-error").style.display = "block";
          }
        });
      }
    }

    const getTableData = async () => {
      get(url.GET_ADMIN_PROMO_CODES) 
      .then(res => {
          if(res) {
            res.rows.map(d => (
              data.rows.push({
              "code" : d.code,
              "description" : d.description,
              "date_start" : d.date_start,
              "date_end" : d.date_end,
              "initial_trial_period" : d.initial_trial_period,
              "status" : d.status,
              "actions" : [
                <button onClick={() => activePromoCode(d.code)}
                  title="Active" className={`btn btn-active-${d.code} btn-${d.status == "Active" ? "success" : "light"} btn-primary btn-sm waves-effect waves-light`}>
                  Active
                </button>,
                <button onClick={() => inactivePromoCode(d.code)}
                  title="In Active" className={`btn btn-inactive-${d.code} btn-${d.status == "Inactive" ? "success" : "light"} btn-primary btn-sm waves-effect waves-light`}>
                  In Active
                </button>,
                <span style={{marginLeft: "6px",marginRight: "6px"}}>|</span>,
                <button onClick={() => editPromoCode(d.code)}
                  title="Edit" className="btn btn-primary btn-sm waves-effect waves-light btn btn-warning">
                  <i className="fas fa-edit" aria-hidden="true"></i>
                </button>,
                <span style={{marginLeft: "6px",marginRight: "6px"}}>|</span>,
                <button onClick={() => deletePromoCode(d.code)}
                  title="Delete" className="btn btn-primary btn-sm waves-effect waves-light btn btn-danger">
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </button>,
              ],
            })))
            setTableData(data)
          }
      })
      .catch(error => {
        //setErrMessage(error)
      });
    }


    return (
        <React.Fragment>
            <div className="promoCodeContainer showTableFoot">
              <Container fluid>
                  <div className="page-content">
                      <div className="container-fluid">
                      <Breadcrumbs title="Koloden" breadcrumbItem="Manage Promo Code" />

                      <button
                      style={{marginBottom:"20px"}}
                        type="button"
                        onClick={() => {
                          tog_standard()
                        }}
                        className="btn btn-primary waves-effect waves-light"
                        data-toggle="modal"
                        data-target="#myModal"
                      >
                        Add Promo Code
                          </button>

                      <Modal
                        isOpen={modal_standard}
                        toggle={() => {
                          tog_standard()
                        }}
                      >
                        <div className="modal-header">
                          <input type="hidden" id="modal-id" value={modal_ID} />
                          <h5 className="modal-title mt-0" id="modal-title">
                            {modal_title}
                            </h5>
                          <button
                            type="button"
                            onClick={() => {
                              setmodal_standard(false)
                            }}
                            className="close CloseDataTb"
                            style={{marginTop:"13px",marginRight:"10px"}}
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body ">
                        <Row className="mb-3 justify-content-center">
                            <label
                            htmlFor="example-text-input"
                            className="col-md-5 col-form-label"
                            >
                            Promo Code
                            </label>
                            <div className="col-md-5">
                            <input
                                className="form-control"
                                type="text"
                                defaultValue={modal_code}
                                onChange={ (e) => setModalCode(e.target.value) } 
                            />
                            <span id="modal-error" style={{ display: "none" }}>This code already exists!</span>
                            </div>
                        </Row>

                        <Row className="mb-3 justify-content-center">
                            <label
                            htmlFor="example-date-input"
                            className="col-md-5 col-form-label"
                            >
                            Start Date
                            </label>
                            <div className="col-md-5">
                            <input
                                className="form-control"
                                type="date"
                                defaultValue={modal_StartDate}
                                onChange={ (e) => setModalStartDate(e.target.value) }
                                id="example-date-input"
                            />
                            </div>
                        </Row>
                        
                        <Row className="mb-3 justify-content-center">
                            <label
                            htmlFor="example-date-input"
                            className="col-md-5 col-form-label"
                            >
                            End Date
                            </label>
                            <div className="col-md-5">
                            <input
                                className="form-control"
                                type="date"
                                defaultValue={modal_EndDate}
                                onChange={ (e) => setModalEndDate(e.target.value) }
                                id="example-date-input"
                            />
                            </div>
                        </Row>
                        
                        <Row className="mb-3 justify-content-center">
                            <label
                            htmlFor="example-text-input"
                            className="col-md-5 col-form-label"
                            >
                            Initial Trial Period (Days)
                            </label>
                            <div className="col-md-5">
                            <input
                                className="form-control"
                                type="text"
                                defaultValue={modal_TrialPeriod}
                                onChange={ (e) => setModalTrialPeriod(e.target.value) }
                            />
                            </div>
                        </Row>
                        
                        <Row className="mb-3 justify-content-center">
                            <label className="col-md-5 col-form-label">Status</label>
                            <div className="col-md-5">
                            <div className="cstm-dropArr">
                            {/* <i className="uil-angle-down"></i> */}
                            <select className="" onChange={ (e) => setModalStatus(e.target.value) }>
                                <option>Select </option>
                                {modal_Status == "Active" ? (<option value="Active" selected>Active</option>) : (<option value="Active">Active</option>)}
                                {modal_Status == "Inactive" ? (<option value="Inactive" selected>In Active</option>) : (<option value="Inactive">In Active</option>)}
                            </select>
                            </div>
                            </div>
                        </Row>

                        <Row className="mb-3 justify-content-center">
                            <label
                            htmlFor="example-text-input"
                            className="col-md-5 col-form-label"
                            >
                            Description
                            </label>
                            <div className="col-md-5">
                              <textarea
                              style={{resize:"none",minHeight:"120px"}}
                              className="form-control"
                              placeholder="Description"
                              rows={1}
                              defaultValue={modal_description}
                              onChange={ (e) => setModalDescription(e.target.value) }
                              />
                            </div>
                        </Row>

                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            onClick={() => {
                              tog_standard()
                            }}
                            className="btn btn-secondary waves-effect"
                            data-dismiss="modal"
                          >
                            Cancel
                            </button>
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light"
                            onClick={() => {
                              savePromoCode()
                            }}
                          >
                            Save
                            </button>
                        </div>
                      </Modal>

                      <Row>
                          <Col className="col-12 datatb-prod">
                          <Card>
                              <CardBody>
                              <MDBDataTable responsive bordered data={table_data} />
                              </CardBody>
                          </Card>
                          </Col>
                      </Row>
                      </div>
                  </div>

              </Container>
          </div>
        </React.Fragment>
    )
  }
  
  export default Promocode
