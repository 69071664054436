import React from 'react'
import Help from './layout/Help'
import Header from './layout/Header'
import Footer from './layout/Footer'
import {Link} from 'react-router-dom'

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"

import '../../kolodenTheme/css/bootstrap.min.css'
import '../../kolodenTheme/css/fonts.css'
import '../../kolodenTheme/css/all.min.css'
import '../../kolodenTheme/css/style.css'
import MetaTags from 'react-meta-tags';
import $ from 'jquery'

const UseCases = () => {

    const showPageData = false;
    
    $(function() {
        var pageID = 8;
        function loadPageData(){
            get(url.CMS_CONTENT + "?page=" + pageID) 
            .then(res => {
                if(res) {
                    res.forEach(element => {
                        var page = pageID;
                        var section = element.section;
                        var column = element.column;
                        if(element.content !== undefined){
                            if(element.type == 'image') {
                                $("[data-set='"+ page + section + column +"']").attr('src', "https://koloden.com/v2api/public/cms/images/" + element.content );
                            } else if(element.type == 'text') {
                                $("[data-set='"+ page + section + column +"']").html(element.content);
                            }
                        }
                    })
                }
            })
            .catch(error => {
                console.log(error)
            });
        }

        loadPageData();
    })

    return(
        
        <div className="koloden-main">
            <MetaTags>
                <title>How to Avoid Brand & Copyright Infringement as a Reseller | Koloden</title>
                <meta name="description" content="Koloden's Intellectual Property Management software is tailored to resellers and helps them avoid litigation issues, platform strikes, & issues with customs." />
                <meta property="og:title" content="How to Avoid Brand & Copyright Infringement as a Reseller | Koloden" />
            </MetaTags>
            <Help />
            <Header />


        <div class="section-info contrast pricing">
            <div class="container">
            <div className="row justify-content-center">
                <h1 className="title" data-set="811"></h1>
                    {/* <h2 className="title" style={{maxWidth: "790px"}} >
                        <span data-set="812" className="secondary font-semi-light"></span>
                    </h2> */}
            </div>
            </div>
        </div>

        
        



        <div class="section-info blog-sect content" style={{paddingBottom: "0"}}>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-sm-12 ml-auto" data-set="821">
<p>

</p>                        
<h5 style={{marginTop: "0"}}> </h5>

                    </div>

                </div>
            </div>
        </div>






        <div class="section-info blog-sect content" style={{paddingTop: "0", paddingBottom: "0"}}>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-sm-12 ml-auto">
                        <img data-set="891" style={{width:"400px",left:"50%",transform:"translateX(-50%)",marginTop:"30px",position:"relative",border:"1px solid grey"}} src="kolodenTheme/img/para-a.png" ></img>
                    </div>

                </div>
            </div>
        </div>









        
        <div class="section-info blog-sect content" style={{paddingTop: "28px", paddingBottom: "0px"}}>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-sm-12 ml-auto" data-set="822">


                    </div>

                </div>
            </div>
        </div>








        <div class="section-info blog-sect content" style={{paddingTop: "0px",paddingBottom: "0"}}>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-sm-12 ml-auto">
                        <img data-set="892" style={{width:"400px",left:"50%",transform:"translateX(-50%)",marginTop:"30px",position:"relative",border:"1px solid grey"}} src="kolodenTheme/img/para-a.png" ></img>
                    </div>

                </div>
            </div>
        </div>




{/* ------------------------------------------------- */}

        <div class="section-info blog-sect content" style={{paddingTop: "28px"}}>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-sm-12 ml-auto" data-set="831">
<p>

</p>
                    </div>

                </div>
            </div>
        </div>












        <div class="section-info contrast pricing">
            <div class="container">
            <div className="row justify-content-center">
                <h2 className="title-v2" data-set="832">For Platform</h2>
                    <h2 className="title" style={{maxWidth: "790px"}} >
                        <span data-set="833" className="secondary font-semi-light"></span>
                    </h2>
            </div>
            </div>
        </div>









        <div class="section-info blog-sect content">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-sm-12 ml-auto" data-set="841">
                        <p></p>                        
                    </div>

                </div>
            </div>
        </div>




        <div className="section-info dark">
            <div className="container">
                <div className="row">
                    <h2 className="title-v2" data-set="851">Have any questions?</h2>
                    <p data-set="852">Check our FAQ or</p>
                    <div className="get-started-btn">
                        <Link to="/contact-us">
                            <button>CONTACT US</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>

            
            <Footer />
        </div>

    );
};

export default UseCases;