import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import * as url from "../../helpers/url_helper";
import { get, post } from "../../helpers/api_helper";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getDefaultPaymentMethod } from "../../helpers/payment_method_helper";

import $ from "jquery";

export default function PaymentMethodsRadioGroup() {
  const [value, setValue] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [errMessage, setErrMessage] = React.useState("");
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getDefaultPaymentMethod();
    setTimeout(() => {
      const defaultPaymentMethod = localStorage.getItem(
        "default_payment_method"
      );
      if(defaultPaymentMethod === null){
        setValue("card");
      }else{
        setValue(defaultPaymentMethod);
      }      
      
      showPaymentForm();
    }, 1000);

    // const defaultPaymentMethod = localStorage.getItem("default_payment_method");
    // if (defaultPaymentMethod) {
    //   setValue(defaultPaymentMethod);
    //   showPaymentForm();
    // } else {
    //   get(url.STRIPE_USER_GET_PAYMENT_METHOD).then((response) => {
    //     if (response) {
    //       setValue(response);
    //       localStorage.setItem("default_payment_method", response);
    //       showPaymentForm();
    //     }
    //   });
    // }
  }, [location]);

  const handleChange = (event) => {
    setValue(event.target.value);
    setSuccessMessage("");
    setErrMessage("");
    showPaymentForm(event.target.value);
  };

  const handleUpdate = () => {
    handleClose(); // Close the dialog box

    if (!value) {
      setErrMessage("Please select a payment method.");
      return;
    }
    const paymentMethodData = {
      payment_method: value,
    };
    post(url.STRIPE_USER_PAYMENT_METHOD, paymentMethodData)
      .then((res) => {
        if (res) {
          console.log(res);
          setSuccessMessage("Default payment method updated successfully.");
          localStorage.setItem("default_payment_method", value);
        }
      })
      .catch((error) => {
        console.log(error);
        setErrMessage("Failed to set default payment method.");
      });
  };

  const showPaymentForm = (paymentMethod = "") => {
    if (!paymentMethod) {
      paymentMethod = localStorage.getItem("default_payment_method");
    }
    $("#card_section").hide();
    $("#send_invoice_section").hide();
    $("#amazon_pay_section").hide();
    if (paymentMethod == "card" || !paymentMethod) {
      $("#card_section").hide();
    }
    if (paymentMethod == "send_invoice") {
      $("#send_invoice_section").show("fast");
    }
    if (paymentMethod == "amazon_pay") {
      $("#amazon_pay_section").show("fast");
    }
  };

  return (
    <div style={{ paddingTop: "20px" }}>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="payment_method_radio"
          name="payment_method_radio"
          value={value}
          onChange={handleChange}
          row
        >
          <FormControlLabel
            value="card"
            control={<Radio style={{ color: "#00ab4e" }} />}
            label="Stripe"
          />
          
          <FormControlLabel
            value="amazon_pay"
            control={<Radio style={{ color: "#00ab4e" }} />}
            label="Amazon Pay"
          />

          {/*
          <FormControlLabel
            value="send_invoice"
            control={<Radio style={{ color: "#00ab4e" }} />}
            label="WeChat/AliPay (Send Payment Link)"
          />
          */}
        </RadioGroup>
        <button
          className="btn btn-primary waves-effect waves-light"
          onClick={handleClickOpen}
        >
          Update
        </button>
      </FormControl>

      <div className="error-family">
        <p className="errorText">{errMessage}</p>
        <p className="successText">{successMessage}</p>
      </div>

      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to update your payment method?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="default">
              No
            </Button>
            <Button
              onClick={handleUpdate}
              style={{ color: "#00ab4e" }}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
